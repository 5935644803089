<div class="container h-100 overflow-hidden">
  <div class="top">
    <div class="top-inner">
      <div class="body-large white">
        <div class="h4">Preview</div>
      </div>
      <mat-icon class="close-icon" mat-dialog-close >close</mat-icon>
    </div>
  </div>

  <div class="container-body d-flex flex-column gap-3">
    <div class="content d-flex gap-2 bg-white align-items-center" *ngIf="data.showRedirectTo && data.url">
      <span class="h5">{{data.linkTitle}}</span>
      <mat-icon class="fs-16 gray h-16px cursor-pointer" (click)="openLink()">open_in_new</mat-icon>
    </div>

    <div class="content preview-box bg-white">
      <div class="item-center h-100 " *ngIf="data?.presignedUrl; else placeholder">
        <img *ngIf="isImage" [src]="data?.presignedUrl" alt="Preview Image" />
        <embed *ngIf="isPDF" [src]="data?.presignedUrl" width="100%" height="500px" />
        <div *ngIf="!isImage && !isPDF" class="h4 gray item-center h-100">Unsupported file format</div>
      </div>
  
      <ng-template #placeholder>
        <div class="h4 gray item-center h-100">Show file preview here</div>
      </ng-template>
    </div>

  </div>
</div>
