import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Client } from 'src/app/models/client.model';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';

@Component({
  selector: 'app-designation-paths-settings',
  templateUrl: './designation-paths-settings.component.html',
  styleUrls: ['./designation-paths-settings.component.scss']
})
export class DesignationPathsSettingsComponent implements OnInit {
  private _client: Client;
  @Input() canEdit: boolean = false;
  @Output() onSave = new EventEmitter();
  editForm: FormGroup;
  smsOptions: any[] = [
    { title: 'Map skills to designations', key: 'mapSkillsToDesignations', type: 'select', options: [], validators: [], icon: 'settings_suggest', isMulti: true, displayKey: 'name', valueKey: 'categoryId' },
    { title: 'Map specializations to designations', key: 'mapSpecializationsToDesignations', type: 'select', options: ['Yes', 'No'], validators: [Validators.required], icon: 'settings_suggest' },
    { title: 'Map External ID to designations', key: 'mapExternalIdToDesignations', type: 'select', options: ['Yes', 'No'], validators: [Validators.required], icon: 'settings_suggest' },
    { title: 'Use External ID as Identifier', key: 'externalIdAsUniqueIdentifierForDesignations', type: 'select', options: ['Yes', 'No'], checkValue: [{ key: 'mapExternalIdToDesignations', values: ['Yes'] }] , validators: [Validators.required], icon: 'settings_suggest' },
    { title: this.ds.dictionary.designationPath + ' Settings', type: 'sub-title', allowIf: ['designation-paths'] },
    { title: 'Use specializations in ' + this.ds.dictionary.designationPath.toLowerCase() + 's', key: 'useSpecializationsInDesignationPaths', type: 'select', options: ['Yes', 'No'], validators: [Validators.required], icon: 'settings_suggest', checkValue: [{ key: 'mapSpecializationsToDesignations', values: ['Yes'] }], allowIf: ['designation-paths'] },
    { title: this.ds.dictionary.designationPath + 's scoring method', key: 'designationPathsScoringMethod', type: 'select', options: ['Manual', 'System Generated'], validators: [Validators.required], icon: 'settings_suggest', allowIf: ['designation-paths'] },
    { title: `Maximum no. of allowed ${this.ds.dictionary.bookmark}s`, key: 'maxBookmarks', icon: 'settings_suggest', type: 'number', allowIf: ['designation-paths'], validators: [Validators.required, Validators.min(0), Validators.max(1000)] },
    { title: `Repeat the education carousal (Days)`, key: 'repeatEducationalCarousal', icon: 'settings_suggest', type: 'number', allowIf: ['designation-paths'], validators: [Validators.required, Validators.min(0)] },
    { title: `Limit ${this.ds.dictionary.bookmark}s to directly matching roles`, key: 'limitDirectBookmarks', icon: 'settings_suggest', type: 'select', options: ['Yes', 'No'], allowIf: ['designation-paths'], validators: [Validators.required] },
    // { title: 'Use skill category weights in ' + this.ds.dictionary.designationPath.toLowerCase() + 's', key: 'useSkillCategoryWeightsInDesignationPaths', type: 'select', options: ['Yes', 'No'], validators: [Validators.required], icon: 'settings_suggest', allowIf: ['designation-paths'], checkValue: [{ key: 'designationPathsScoringMethod', values: ['System Generated'] }] },
    // { title: this.ds.dictionary.designationPath + 's category weights', type: 'sub-title', allowIf: ['designation-paths'], checkValue: [{ key: 'useSkillCategoryWeightsInDesignationPaths', values: ['Yes'] }, { key: 'designationPathsScoringMethod', values: ['System Generated'] }] },
    { title: this.ds.dictionary.designationPath + ' Rules Engine', type: 'sub-title', allowIf: ['designation-paths'] },
    { title: 'Threshold for valid matching (0-100)', key: 'designationPathsThreshold', type: 'number', validators: [Validators.required, Validators.min(0), Validators.max(100)], icon: 'settings_suggest', allowIf: ['designation-paths'] },
    { title: `Restrict to ${this.ds.dictionary.designationCluster.toLowerCase()}s`, key: 'restrictDesignationPathsToDesignationClusters', type: 'select', options: ['Yes', 'No'], validators: [Validators.required], icon: 'settings_suggest', allowIf: ['designation-paths'] },
    { title: 'Maximum designation level jump allowed', key: 'maxDesignationJumpAllowed', type: 'number', validators: [Validators.required, Validators.min(1), Validators.max(1000)], icon: 'settings_suggest', allowIf: ['designation-paths'] },
  ];
  constructor(public ds: DataService, private weds: WaitErrorDialogsService, private fb: FormBuilder) {
    this.editForm = fb.group({});
  }

  ngOnInit(): void {
  }

  @Input()
  set client(client: Client) {
    this._client = client;
    this.buildForms();
  }

  get client() {
    return this._client;
  }

  buildForms() {
    this.smsOptions.filter((option) => option.key == 'mapSkillsToDesignations')[0].options = this.client.smsSettings.skillCategories;
    for (let i = 0; i < this.smsOptions.length; i++)if (this.smsOptions[i].autoAdded) this.smsOptions.splice(i--, 1);
    for (let i = 0; i < this.smsOptions.length; i++) {
      let s = this.smsOptions[i];
      let currentValue = this.editForm.value;
      let value = currentValue[s.key] || this.client.smsSettings[s.key] || s.defaultValue;
      if (s.key == 'useSkillCategoryWeightsInDesignationPaths' && value == 'Yes' && (currentValue || this.client.smsSettings || {}).designationPathsScoringMethod == 'System Generated') {
        let cnt = 1, skillCategoriesMapped = currentValue.mapSkillsToDesignations || this.client.smsSettings.mapSkillsToDesignations || [];
        this.client.smsSettings.skillCategories.map((c) => {
          if (!skillCategoriesMapped.filter((item) => item.categoryId == c.categoryId)[0]) return;
          this.smsOptions.splice(i + ++cnt, 0, { title: 'Weightage for ' + c.name + ' (0-1)', key: 'designationPathCategoryWeightage_' + c.categoryId, type: 'number', validators: [Validators.required, Validators.min(0), Validators.max(1)], icon: 'settings_suggest', autoAdded: true });
        });
      }
      s.showControl = true;
      (s.allowIf || []).map((key) => { if ((this.client.features.admin[key] || 'No Access') == 'No Access') s.showControl = false; });
      (s.checkValue || []).map((item) => { if (item.values.indexOf(this.editForm.contains(item.key) ? this.editForm.get(item.key).value : this.client.smsSettings[item.key]) < 0) s.showControl = false; });
      if (this.editForm.contains(s.key)) this.editForm.removeControl(s.key);
      if (s.showControl) this.editForm.addControl(s.key, new FormControl({ value: value, disabled: !this.canEdit }, s.validators));
    }
  }
  saveData() {
    if (this.editForm.invalid) return;
    let d = this.editForm.value;
    d.type = 'designations';
    this.onSave.emit(d);
  }

  compareSelect(o1: any, o2: any) {
    let keys = ['id', 'categoryId'];
    for (let i = 0; i < keys.length; i++) {
      if ((o1 && o1[keys[i]]) || (o2 && o2[keys[i]])) {
        return (o1 || {})[keys[i]] == (o2 || {})[keys[i]];
      }
    }
    return o1 == o2;
  }

  onSMSOptionSelected(smsOption, event) {
    if (['mapExternalIdToDesignations','mapSpecializationsToDesignations', 'useSkillCategoryWeightsInDesignationPaths', 'mapSkillsToDesignations', 'designationPathsScoringMethod'].indexOf(smsOption.key) >= 0) this.buildForms();
  }

  evaluateEnterKey(event) {
    if ((event.srcElement.className || '').search('textarea') >= 0) return;
    event.preventDefault()
  }
}
