import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ACCESS_LEVELS } from 'src/app/constants';
import { Client } from 'src/app/models/client.model';
import { DataService } from 'src/app/services/data.service';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-learning-element-certifications-settings',
  templateUrl: './learning-element-certifications-settings.component.html',
})
export class LearningElementCertificationsSettingsComponent implements OnInit {

  @Input() canEdit: boolean = false;
  @Input() client: Client;
  editForm: FormGroup = new FormGroup({});
  smsOptions: any[] = [
    {
      title: 'Choose Mandatory fields',
      key: 'allowedMandatoryLECertificationField',
      type: 'select',
      options: [
        { key: 'Certification Name', value: 'certificationName', disabled:  true }, 
        { key: 'Certification Number', value: 'referenceId' }, 
        { key: 'Issuing Organization', value: 'issuingOrganization' },
        { key: 'Is Renewal Need', value: 'isRenewable' }, 
        { key: 'Skills', value: 'skillItemIds' },
        { key: 'Start Date', value: 'startDate' },
        { key: 'End Date', value: 'endDate' },
        { key: 'Certificate Url', value: 'referenceUrl' },
        { key: 'Certificate Image', value: 'referenceFile' }
      ],
      defaultValue: ['certificationName'],
      displayKey: 'key',
      validators: [],
      icon: 'settings_suggest',
      isMulti: true,
    },
    {
      title: 'show/hide fields',
      key: 'showOrHideCertificationFields',
      type: 'select',
      options: [
        { key: 'Is Renewal Need', value: 'isRenewable' }, 
      ],
      defaultValue: '',
      displayKey: 'key',
      validators: [],
      icon: 'settings_suggest',
      isMulti: true,
    },
    {
      key: 'learningElementCertificationRequestEmails', type: 'multiSelectChip', validators: [], value: [],
      subOptions: {
        key: 'learningElementCertificationRequestEmails',
        title: 'Receiver of Certification Request Emails',
        icon: 'email',
        value: [],
        placeholder: 'Add email and hit Enter',
        errorText: 'Enter a valid Email Address',
        validators: [Validators.email],
      }
    },

  ]

  private _client: Client;
  constructor(
    private util: UtilitiesService
  ) { }

  ngOnInit(): void {
    this.buildForm();
  }

  private buildForm() {
    const smsSettings = this.client.smsSettings;
    this.smsOptions.map((s) => {
      let currentValue = this.editForm.value;
      let value = currentValue[s.key] || smsSettings[s.key]?.length ? smsSettings[s.key] : s.defaultValue;
      s.showControl = true;
      (s.allowIf || []).map((key) => { if ((this.client.features.admin[key] || ACCESS_LEVELS.NO_ACCESS) == ACCESS_LEVELS.NO_ACCESS) s.showControl = false; });
      (s.checkValue || []).map((item) => { if (item.values.indexOf(this.editForm.contains(item.key) ? this.editForm.get(item.key).value : this.client.smsSettings[item.key]) < 0) s.showControl = false; });
      if (this.editForm.contains(s.key)) this.editForm.removeControl(s.key);
      if (s.showControl) {
        if (s.key === 'learningElementCertificationRequestEmails') {
          s.subOptions.value = value || [];
          if (this.client.features.sms.certifications === ACCESS_LEVELS.NO_ACCESS) {
            s.showControl = false;
          }
          s.subOptions.validators = [...s.subOptions.validators, this.util.checkEmailDomain(this.client.security.permittedDomains || [])];
        }
        this.editForm.addControl(s.key, new FormControl({ value: value, disabled: !this.canEdit }, s.validators));
        if (s.subOptions) s.subOptions.disabled = !this.canEdit;
      }
    });
  }

}
