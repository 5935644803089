<div class="p2p-settings-con">
  <div class="h3 black ">P2P Endorsements Settings</div>
  <form [formGroup]="editForm" (ngSubmit)="saveData()" (keydown.enter)="evaluateEnterKey($event)" class="admin-form">
      <div class="admin-form-con">
        <div *ngFor="let smsOption of smsOptions" [ngClass]="smsOption.type=='textarea' ? 'admin-input-con-full' : 'admin-input-con-half'"
        [ngStyle]="{'display':smsOption.showControl?'block':'none'}">
          <div *ngIf="smsOption.showControl">
            <div *ngIf="smsOption.type=='select'">
              <mat-form-field appearance="outline" class="admin-input-form-field">
                  <mat-label>{{smsOption.title}}</mat-label>
                  <mat-icon matPrefix *ngIf="smsOption.icon">{{smsOption.icon}}</mat-icon>
                  <mat-select formControlName="{{smsOption.key}}" [required]="smsOption.required"
                      [compareWith]="compareSelect">
                      <mat-option *ngFor="let option of smsOption.options"
                          [value]="option">
                          {{smsOption.displayKey?option[smsOption.displayKey]:option}}
                      </mat-option>
                  </mat-select>
                  <mat-error *ngIf="editForm.get(smsOption.key).invalid">{{smsOption.errorText||'This input is
                      mandatory'}}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="admin-save-button-con" *ngIf="canEdit">
          <button type="submit" mat-stroked-button class="primary-button">Save</button>
      </div>
  </form>
</div>
