<div class="container">
  <!-- Header -->
  <div class="top">
    <div class="top-inner">
      <div class="body-large white d-flex align-items-center">
        Review Skills - {{ reviewSkillParams?.categoryName }}
      </div>
      <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>
    </div>
  </div>


  <div class="table-container">
    <div class="mb-3">
      <ng-container *ngFor="let validation of reviewSkillParams?.skillTypeValidationError?.validation">
        <div class="content-top-bar d-block">
          <div class="bordered-con mb-2 message-con" [ngClass]="validation?.class">
            <app-svg-icon class="d-flex" [src]="validation?.icon" [viewBox]="validation?.viewBox" height="16px" width="16px" [fill]="validation?.fillColor"></app-svg-icon>
            <label class="label black fw-400">{{ validation?.message }}</label>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="header d-flex justify-content-between">
      <div class="label gray">SKILL</div>
      <div class="ml-auto fix-width label gray">TYPE</div>
      <div class="skill-delete-icon">&nbsp;</div>
      <div class="skill-delete-icon">&nbsp;</div>
    </div>
    <ng-container  *ngFor="let skill of reviewSkillParams?.skillData">
      <div class="d-flex justify-content-between align-items-center row">
        <div> {{skill.name}} </div>
        <div class="ml-auto item-center gap-2">
          <div class="new-label item-center" *ngIf="isNewSkill(skill)">New</div>
          <form [formGroup]="skillForm" class="skill-form">
            <div class="skill-field" *ngFor="let question of skillQuestions">
              <mat-form-field appearance="outline" *ngIf="question.type=='Tagging'" [class.mat-form-field-invalid]="reviewSkillParams.skillTypeValidationError[skillForm?.get('skillTypeId_' + skill?.skillItemId)?.value]"
                matTooltip="Choose your {{ ds.dictionary.skillType | lowercase }}">
                <mat-select [formControlName]="question.key+'_'+skill.skillItemId"
                    (selectionChange)="onFormValueChange($event, skill)" placeholder="{{question.placeholder}}">
                    <mat-option *ngFor="let s of question.options" [value]="s.skillTypeId">
                        {{s.name}}
                    </mat-option>
                </mat-select>
                <mat-icon matSuffix class="mat-custom-arrow">keyboard_arrow_down
                </mat-icon>
              </mat-form-field> 
            </div>
          </form>
        </div>
        <div [ngStyle]="{'visibility' :reviewSkillParams?.skillTypeValidationError[skillForm.get('skillTypeId_' + skill.skillItemId)?.value]?.error ? 'visible' : 'hidden'}"
          [matTooltip]="reviewSkillParams?.skillTypeValidationError[skillForm.get('skillTypeId_' + skill.skillItemId)?.value]?.tooltipMessage">
          <app-svg-icon 
            class="h-75 transform-icon" 
            src="icons/error-rounded-red.svg" 
            viewBox="0 0 24 24" height="16px" width="16px"
            fill="var(--warning-color-dark)">
          </app-svg-icon>
        </div>
        <mat-icon class="skill-delete-icon" matTooltip="Delete the skill" (click)="onDelete(skill)">close</mat-icon>
      </div>
      <mat-divider></mat-divider>
    </ng-container>
  </div>

  <div class="button-container">
    <button mat-stroked-button class="secondary-buttonn" (click)="closeDialog()">
      DISMISS
    </button>
    
    <button mat-stroked-button class="primary-button" (click)="applyChanges()" [disabled]="hasError()">
      APPLY CHANGES
    </button>
  </div>
</div>
  
