import { Injectable } from '@angular/core';
import { IFilter, IMyLearningPageVisibilityAccess, TREATMENT_MAP } from './my-learning.model';
import { DataService } from 'src/app/services/data.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { API_ENDPOINT } from 'src/app/constants/api-endpoint.constants';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MyLearningService {

  public cachedLMSData = { data: [], cacheTime: null };

  private individualLearningFilter: IFilter = { key: '',  value: [] };
  private myLearningFilter: IFilter = { key: '',  value: [] };

  private progressCardFilterMap: Map<string, IFilter> = new Map();

  // Use for constant caching
  private LearningElementConstants = new Map<string, any>();

  constructor(private ds: DataService, private util: UtilitiesService) { }

  public cacheLMSData(data) {
    this.cachedLMSData.data = data;
    this.cachedLMSData.cacheTime = new Date().getTime();
  }

  public get getIndividualLearningFilter(): IFilter {
    return this.individualLearningFilter;
  }

  public set setIndividualLearningFilter(filters: IFilter) {
    this.individualLearningFilter = filters;
  }

  public get getMyLearningTypeFilter(): IFilter {
    return this.myLearningFilter;
  }

  public set setMyLearningFilter(filters: IFilter) {
    this.myLearningFilter = filters;
  }

  public getMyLearningPageVisibilityAccess(): IMyLearningPageVisibilityAccess {
    const userRoleSMS = this.ds.user.role.sms;
    const clientFeaturesSMS = this.ds.client.features.sms;

    return {
      showOverview: this.util.checkAccess(userRoleSMS?.myLearningsOverview, clientFeaturesSMS?.myLearningsOverview),
      showBySpecialization: this.util.checkAccess(userRoleSMS?.myLearningsBySpecialization, clientFeaturesSMS?.myLearningsBySpecialization),
      showIndividualLearning: this.util.checkAccess(userRoleSMS?.myLearningsIndividualLearning, clientFeaturesSMS?.myLearningsIndividualLearning),
    };
  }

  public hasMyLearningAccess(): boolean {
    const userRoleSMS = this.ds.user.role.sms;
    const clientFeaturesSMS = this.ds.client.features.sms;
    return this.util.checkAccess(userRoleSMS.myLearnings, clientFeaturesSMS.myLearnings);
  }

  public getConstantFiltersValue(payload: { fields: string[] }, filters: IFilter[]): Observable<IFilter[]> {
    const getLEFields = API_ENDPOINT.LEARNING_ELEMENT_CONSTANTS(this.ds.currentAdminClientId);
    const missingKeys = payload.fields.filter((key) => !this.LearningElementConstants.has(key));
  
    if (missingKeys.length === 0) {
      return of(this.buildFilterData(filters));
    }
  
    return this.ds.careerPrismDataPostApi(getLEFields, { fields: missingKeys }).pipe(
      map((res) => {
        Object.entries(res.learningElementsConstants || {}).forEach(([key, value]) => {
          this.LearningElementConstants.set(key, value);
        });
  
        return this.buildFilterData(filters);
      })
    );
  }

  private buildFilterData(filters: IFilter[]): IFilter[] {
    return filters.reduce((acc: IFilter[], filter: IFilter) => {
      let options = this.LearningElementConstants.get(filter.key);

      if (!options && filter?.options?.length) {
        options = filter.options
        this.LearningElementConstants.set(filter.key, filter.options);
      }
  
      if (options?.length || filter.options) {
        acc.push({
          key: filter.key,
          options,
          title: filter.placeholder || filter.key,
        });
      }
  
      return acc;
    }, []);
  }
}
