<div *ngIf="settings">
    <div class="h4 black">Skill Types</div>
    <div class="caption gray">Please ensure to configure skill types for skill categories separately</div>
    <form [formGroup]="editForm" (ngSubmit)="saveData()" (keydown.enter)="$event.preventDefault()">
        <div class="admin-form-con admin-input-con-full">
            <mat-form-field appearance="outline" class="input-form-field" style="width:100%;margin-top:10px;">
                <mat-label>Enable skill type feature</mat-label>
                <mat-icon matPrefix>settings_suggest</mat-icon>
                <mat-select formControlName="enablePrimarySkill" required>
                    <mat-option value="Yes">Yes</mat-option>
                    <mat-option value="No">No</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('enablePrimarySkill').invalid">{{'This input is
                    mandatory'}}
                </mat-error>
            </mat-form-field>
        </div>
    </form>
    <div class="table-con" *ngIf="editForm.get('enablePrimarySkill').value=='Yes'">
        <app-editable-table #skillTypes [canEdit]="canEdit" [enableDelete]="true" [columns]="skillTypesColumns"
            class="sms-editable-table" [dataSource]="settings.skillTypes"
            (formValidityChange)="onSkillTypesValidityChange($event)"
            [blockNegativeInputs]="true"
            footNotes="Add at least one skill type to enable the feature" [addButtonText]="'Add'"
            [enableDrag]="true">
        </app-editable-table>
    </div>
    <div class="admin-save-button-con" *ngIf="canEdit">
        <button *ngIf="editForm.get('enablePrimarySkill').value=='Yes'" mat-stroked-button class="secondary-button"
            type="button" (click)="addSkillTypesRow()">Add</button>
        <button mat-stroked-button class="primary-button" (click)="saveData()" [disabled]="editForm.invalid || !skillTypesFormValid">Save</button>
    </div>
</div>