import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Client } from 'src/app/models/client.model';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { DataOrderComponentConfig } from "../../../common-components/data-ordering-control/data-ordering-control.model";
import { ACCESS_LEVELS, SMS_OPTIONS } from "../../../../constants";

@Component({
  selector: 'app-skill-taxonomy-settings',
  templateUrl: './skill-taxonomy-settings.component.html'
})
export class SkillTaxonomySettingsComponent implements OnInit {
  private _client: Client;
  @Input() canEdit: boolean = false;
  @Output() onSave = new EventEmitter();
  editForm: FormGroup;
  optionTagGeneratedOutsideSms = {
    title: 'Leaf Skill Ext Tag Generated Outside SMS',
    key: 'leafSkillExtTagGeneratedOutsideSms',
    type: 'select',
    options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
    validators: [Validators.required],
    icon: 'settings_suggest',
    required: true,
  };

  optionAutoPopulateSkillHierarchyId = {
    title: 'Auto Populate Skill Hierarchy ID',
    key: 'autoPopulateSkillHierarchyId',
    type: 'select',
    options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
    validators: [Validators.required],
    icon: 'settings_suggest',
    required: true,
    checkValue: [{ key: 'useExtSkillId', values: [SMS_OPTIONS.YES] }],
  };

  optionNumberOfDigitsUsedForAutoPopulateSkillHierarchyId = {
    title: 'Digits Count for Skill Hierarchy ID',
    key: 'numberOfDigitsUsedForAutoPopulateSkillHierarchyId',
    type: 'select',
    options: Array.from({ length: 15 }, (_, index) => index + 6),
    validators: [Validators.required],
    icon: 'settings_suggest',
    checkValue: [
      { key: 'useExtSkillId', values: [SMS_OPTIONS.YES] },
      { key: 'autoPopulateSkillHierarchyId',values: [SMS_OPTIONS.YES] }
    ],
  };

  smsOptions: any[] = [
    {
      title: 'Use High Demand Skill Tagging',
      key: 'useHighDemandSkillTagging',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [Validators.required],
      icon: 'trending_up',
      required: true,
    },
    {
      title: 'Use skill origin',
      key: 'useSkillOrigin',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [Validators.required],
      icon: 'settings_suggest',
      required: true,
    },
    {
      title: 'Use skill services',
      key: 'useServicesInSkills',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [Validators.required],
      icon: 'settings_suggest',
      required: true,
    },
    {
      title: 'Use skill complexity',
      key: 'useSkillComplexity',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [Validators.required],
      icon: 'settings_suggest',
      required: true,
    },
    {
      title: 'Use skill introduction year',
      key: 'useSkillIntroduction',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [Validators.required],
      icon: 'settings_suggest',
      required: true,
    },
    {
      title: 'Use staffing skill',
      key: 'useStaffingSkill',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [Validators.required],
      icon: 'settings_suggest',
      required: true,
    },
    {
      title: 'Use priority skill',
      key: 'usePrioritySkill',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [Validators.required],
      icon: 'settings_suggest',
      required: true,
    },
    {
      title: 'Priority skill scale',
      key: 'prioritySkillScale',
      type: 'select',
      options: ['Yes/No', 'Numeric: 1-5', 'Numeric: 1-10'],
      validators: [Validators.required],
      icon: 'settings_suggest',
      checkValue: [{ key: 'usePrioritySkill', values: [SMS_OPTIONS.YES] }],
      required: true,
    },
    {
      title: 'Use External Skill Hierarchy Id',
      key: 'useExtSkillId',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [Validators.required],
      icon: 'settings_suggest',
      required: true,
    },
    this.optionAutoPopulateSkillHierarchyId,
    this.optionNumberOfDigitsUsedForAutoPopulateSkillHierarchyId,
    {
      title: 'Enable Skill Definitions',
      key: 'showSkillDefinitions',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [Validators.required],
      icon: 'settings_suggest',
      required: true,
    },
    {
      title: 'Use Skill Adjacency',
      key: 'useSkillAdjacency',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [],
      icon: 'settings_suggest',
    },
    {
      title: 'Allow Skill Adjacency Download',
      key: 'allowSkillAdjacencyDownload',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [],
      checkValue: [{ key: 'useSkillAdjacency', values: [SMS_OPTIONS.YES] }],
      icon: 'settings_suggest',
    },
    {
      title: 'Use Skill Proficiency',
      key: 'useSkillProficiency',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [],
      icon: 'settings_suggest',
    },
    {
      title: 'Enable Proficiency Definition As Tooltip',
      key: 'useProficiencyTooltip',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [],
      icon: 'settings_suggest',
    },
    {
      title: 'Proficiency Options To Enable',
      key: 'proficiencyOptions',
      type: 'select',
      options: [
        'Description',
        'Certificate',
        'Assessment Score',
        'Overall Experience',
        'Company Experience',
        'Skill/Project Experience',
      ],
      validators: [],
      icon: 'settings_suggest',
      isMulti: true,
    },
    {
      title: 'Leaf Skill External ID Tag',
      key: 'useLeafSkillItemExtTag',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [Validators.required],
      icon: 'settings_suggest',
      required: true,
    },
    this.optionTagGeneratedOutsideSms,
    {
      title: 'Can Scan For Duplicates',
      key: 'canScanForDuplicates',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [Validators.required],
      icon: 'settings_suggest',
      required: true,
    },
    {
      title: 'Enable Duplicate Check',
      key: 'enableDuplicateCheck',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [Validators.required],
      icon: 'settings_suggest',
    },
    {
      title: 'Use Custom Threshold for Fuzzy Match',
      key: 'useCustomThresholdForFuzzyMatch',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [Validators.required],
      icon: 'settings_suggest',
      checkValue: [],
    },
    {
      title: 'Custom Threshold for Fuzzy Match',
      key: 'customThresholdForFuzzyMatch',
      type: 'select',
      options: Array.from(Array(101).keys()),
      validators: [Validators.required],
      icon: 'settings_suggest',
      checkValue: [
        {
          key: 'useCustomThresholdForFuzzyMatch',
          values: [SMS_OPTIONS.YES],
        },
      ],
    },
    {
      title: 'Show Skill Graph',
      key: 'showSkillGraph',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [Validators.required],
      icon: 'settings_suggest',
      required: true,
    },
    {
      title: 'Use demand value',
      key: 'useDemandValue',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [Validators.required],
      icon: 'settings_suggest',
      required: true,
    },
    {
      title: 'No. of months to show feedback form, based on joining date',
      placeholder:
        'Enter No. of months, till when the feedback form should show. Set 0 to always show it',
      key: 'numMonthsToShowFeedbackForm',
      type: 'number',
      validators: [Validators.required, Validators.min(0)],
      defaultValue: 0,
      checkValue: [{ key: 'manageInterviewFeedbackForm', values: [SMS_OPTIONS.YES] }],
      icon: 'settings_suggest',
    },
    {
      title: 'Use Skill Master Tags',
      key: 'useSkillMasterTags',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [Validators.required],
      defaultValue: SMS_OPTIONS.NO,
      icon: 'settings_suggest',
    },
  ];

  showSkillMasterTags: boolean = false;
  skillMasterTagsSettingsFormGroup = new FormGroup({});
  skillRequestOptionsFormGroup: FormGroup;
  showHrmsTags: boolean = false;
  showCustomTags: boolean = false;
  showSkillAdditionRequests: boolean = false;
  isGlobalAdmin: boolean = false;
  clientAdminAccessControlHrmsTag: string;
  clientAdminAccessControlCustomTag: string;
  userRoleAccessControlHrmsTag: string;
  userRoleAccessControlCustomTag: string;

  hrmsTagsConfig: DataOrderComponentConfig = {
    key: 'hrmsTags',
    subTitle: 'HRMS Tags',
    canRemoveItem: true,
    addFromDropDown: true,
    dropDownPlaceholder: 'Choose HRMS Tags',
    maxCount: 10,
    canOrder: true,
    useSaveButton: true,
  };

  customTagsConfig: DataOrderComponentConfig = {
    key: 'customTags',
    subTitle: 'Custom Tags',
    canRemoveItem: true,
    addFromDropDown: true,
    dropDownPlaceholder: 'Choose Custom Tags',
    maxCount: 10,
    canOrder: true,
    useSaveButton: true
  }

  constructor(
    private ds: DataService,
    private weds: WaitErrorDialogsService,
    private fb: FormBuilder,
    private util: UtilitiesService,
  ) {
    this.editForm = this.fb.group({});    
    this.skillRequestOptionsFormGroup = this.fb.group({});
  }

  ngOnInit(): void { }

  @Input()
  set client(client: Client) {
    this._client = client;
    this.setAccessValues();
    this.buildForms();
    this.setInputConfigData();
    this.buildSkillMasterTagsForm();
  }
  get client() {
    return this._client;
  }

  setAccessValues() {
    this.isGlobalAdmin = this.ds.user.role.globalAdmin;
    this.clientAdminAccessControlHrmsTag = this._client.features.admin.hrmsMasters;
    this.clientAdminAccessControlCustomTag = this._client.features.admin.customTags;
    this.userRoleAccessControlHrmsTag = this.ds.user.role.admin?.hrmsMasters;
    this.userRoleAccessControlCustomTag = this.ds.user.role.admin?.customTags;
  }

  buildForms() {
    this.smsOptions.map((s) => {
      let currentValue = this.editForm.value;
      let value =
        currentValue[s.key] || this.client.smsSettings[s.key] || s.defaultValue;
      s.showControl = true;
      (s.allowIf || []).map((key) => {
        if ((this.client.features.admin[key] || 'No Access') == 'No Access')
          s.showControl = false;
      });
      (s.checkValue || []).map((item) => {
        if (
          item.values.indexOf(
            this.editForm.contains(item.key)
              ? this.editForm.get(item.key).value
              : this.client.smsSettings[item.key]
          ) < 0
        )
          s.showControl = false;
      });
      if (this.editForm.contains(s.key)) this.editForm.removeControl(s.key);
      if (s.showControl)
        this.editForm.addControl(
          s.key,
          new FormControl(
            { value: value, disabled: !this.canEdit },
            s.validators
          )
        );
      if (s.key == 'useSkillMasterTags') {
        const denyClientAdminRoleAccess = this.clientAdminAccessControlHrmsTag === ACCESS_LEVELS.NO_ACCESS &&
          this.clientAdminAccessControlCustomTag === ACCESS_LEVELS.NO_ACCESS
        const denyUserAdminRoleAccess = this.isGlobalAdmin ? denyClientAdminRoleAccess :
          this.userRoleAccessControlHrmsTag === ACCESS_LEVELS.NO_ACCESS && this.userRoleAccessControlCustomTag === ACCESS_LEVELS.NO_ACCESS
        if (denyUserAdminRoleAccess) {
          s.showControl = false;
        } else {
          this.showSkillMasterTags = value == SMS_OPTIONS.YES;
        }
      }
    });

    const index =
      this.smsOptions.findIndex(
        (element) => element.key == 'useLeafSkillItemExtTag'
      ) + 1;
    this.editForm.get('useLeafSkillItemExtTag').value == SMS_OPTIONS.NO &&
      this.smsOptions[index].key == this.optionTagGeneratedOutsideSms.key &&
      this.smsOptions.splice(index, 1) &&
      this.editForm.removeControl(this.optionTagGeneratedOutsideSms.key);

    this.editForm.get('useLeafSkillItemExtTag').value == SMS_OPTIONS.YES &&
      this.smsOptions[index].key != this.optionTagGeneratedOutsideSms.key &&
      this.smsOptions.splice(index, 0, this.optionTagGeneratedOutsideSms) &&
      this.editForm.addControl(
        this.optionTagGeneratedOutsideSms.key,
        new FormControl(
          { value: null, disabled: !this.canEdit },
          this.optionTagGeneratedOutsideSms.validators
        )
      );
  }

  setInputConfigData() {
    this.showHrmsTags = this.isGlobalAdmin ? this.clientAdminAccessControlHrmsTag != ACCESS_LEVELS.NO_ACCESS :
      this.userRoleAccessControlHrmsTag != ACCESS_LEVELS.NO_ACCESS && this.clientAdminAccessControlHrmsTag != ACCESS_LEVELS.NO_ACCESS;
    this.showCustomTags = this.isGlobalAdmin ? this.clientAdminAccessControlCustomTag != ACCESS_LEVELS.NO_ACCESS :
      this.userRoleAccessControlCustomTag != ACCESS_LEVELS.NO_ACCESS && this.clientAdminAccessControlCustomTag != ACCESS_LEVELS.NO_ACCESS;

    this.hrmsTagsConfig.disabled = this.isGlobalAdmin ? this.clientAdminAccessControlHrmsTag != ACCESS_LEVELS.EDIT :
      this.userRoleAccessControlHrmsTag != ACCESS_LEVELS.EDIT && this.clientAdminAccessControlHrmsTag != ACCESS_LEVELS.EDIT;
    this.hrmsTagsConfig.dropDownOptions = this._client.smsSettings.hrmsFieldsChosenForMaster ? this._client.smsSettings.hrmsFieldsChosenForMaster.map(field => field.field) : [];
    this.hrmsTagsConfig.values = this._client.smsSettings.skillMasterHrmsTags ? this._client.smsSettings.skillMasterHrmsTags.map(field => field.field) : [];

    this.customTagsConfig.disabled = this.isGlobalAdmin ? this.clientAdminAccessControlCustomTag != ACCESS_LEVELS.EDIT :
      this.userRoleAccessControlCustomTag != ACCESS_LEVELS.EDIT && this.clientAdminAccessControlCustomTag != ACCESS_LEVELS.EDIT;
    this.customTagsConfig.dropDownOptions = this._client.smsSettings.fieldsForCustomTags ? this._client.smsSettings.fieldsForCustomTags.map(field => field.field) : [];
    this.customTagsConfig.values = this._client.smsSettings.skillMasterCustomTags ? this._client.smsSettings.skillMasterCustomTags.map(field => field.field) : [];
  }

  buildSkillMasterTagsForm() {
    this.skillMasterTagsSettingsFormGroup.addControl(
      'hrmsTags',
      new FormControl({ value: this.hrmsTagsConfig.values, disabled: !this.showHrmsTags }, [])
    );
    this.skillMasterTagsSettingsFormGroup.addControl(
      'customTags',
      new FormControl({ value: this.customTagsConfig.values, disabled: !this.showCustomTags }, [])
    );
  }

  saveSkillMasterTags(tagType, tagValuesKeyInSmsSettings, tagTypeForAPI) {
    this.onSave.emit({
      type: tagTypeForAPI,
      skillMasterTags: this.skillMasterTagsSettingsFormGroup.controls[tagType].value
        .map((selectedTag) => {
          return this._client.smsSettings[tagValuesKeyInSmsSettings].filter((tag) => tag.field == selectedTag);
        })
        .flat(),
    });
  }

  saveData() {
    if (this.editForm.invalid) return;
    this.validateProficiencyTooltipStatus();
    let d = this.editForm.value;
    d.type = 'skillTaxonomy';
    this.onSave.emit(d);
  }

  saveSkillRequestData() {
    if (this.skillRequestOptionsFormGroup.invalid) return;
    let d = this.skillRequestOptionsFormGroup.value;
    const payload = {
      allowSkillRequests: d.allowSkillRequests,
      type: 'skillAdditionRequests'
    };
    this.onSave.emit(payload);
  }

  compareSelect(o1: any, o2: any) {
    let keys = ['id', 'categoryId'];
    for (let i = 0; i < keys.length; i++) {
      if ((o1 && o1[keys[i]]) || (o2 && o2[keys[i]])) {
        return (o1 || {})[keys[i]] == (o2 || {})[keys[i]];
      }
    }
    return o1 == o2;
  }

  onSMSOptionSelected(smsOption, { value: selectedValue }) {
    [
      'usePrioritySkill',
      'useDemandValue',
      'manageInterviewFeedbackForm',
      'useLeafSkillItemExtTag',
      'useExtSkillId',
      'autoPopulateSkillHierarchyId',
      'showSocialEndorsement',
      'showCarouselOnMySkills',
      'enableDuplicateCheck',
      'useCustomThresholdForFuzzyMatch',
      'customThresholdForFuzzyMatch',
    ].includes(smsOption.key) && this.buildForms();
    if (smsOption.key === 'useSkillMasterTags') {
      this.showSkillMasterTags = selectedValue == SMS_OPTIONS.YES;
    }
  }

  evaluateEnterKey(event) {
    if ((event.srcElement.className || '').search('textarea') >= 0) return;
    event.preventDefault();
  }

  validateProficiencyTooltipStatus() {
    if (this.editForm?.value?.useSkillProficiency === SMS_OPTIONS.NO) {
      this.editForm.controls?.useProficiencyTooltip?.setValue(SMS_OPTIONS.NO);
      this.editForm.controls?.proficiencyOptions?.setValue([]);
    }
    if (this.editForm?.value?.proficiencyOptions?.indexOf('Description') === -1)
      this.editForm.controls?.useProficiencyTooltip?.setValue(SMS_OPTIONS.NO);
  }

  checkProficiencyMasterEnabled(): boolean {
    return this.editForm?.value?.useSkillProficiency === SMS_OPTIONS.YES;
  }

  renderSkillProficiencyRelationNode(key: string): boolean {
    return /useProficiencyTooltip|proficiencyOptions/.test(key)
      ? this.checkProficiencyMasterEnabled()
      : true;
  }
}