import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BadgeType } from '../../badges.model';
import { Router } from '@angular/router';
import { extractColors } from "extract-colors";
import { MULTIPLE_BADGE_LABEL } from 'src/app/constants/gamification.constants';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-badge-detail-modal',
  templateUrl: './badge-detail-modal.component.html',
  styleUrls: ['./badge-detail-modal.component.scss']
})
export class BadgeDetailModalComponent implements OnInit {
  @Input() data: any;
  @Input() isImmediate: boolean = false;
  @Input() showBadgeList: boolean = false;
  @Input() isLocked: boolean = false;
  @Output() onClose = new EventEmitter();

  public multipleBadgesCount: number = 0;
  public multipleBadgeLabel: string = MULTIPLE_BADGE_LABEL;
  public eBadgeType = BadgeType;


  constructor(
    private router: Router,
    private util: UtilitiesService
  ) { }

  ngOnInit(): void {
    this.checkForMultipleBadges();
    this.extractColors();
  }

  // ------------------------------------------------------------------
  // Public Methods
  // ------------------------------------------------------------------

  public close() {
    this.onClose.emit();
  }

  public navigateToBadges() {
    this.router.navigate(['sms/badges']);
    this.close();
  }

  public getBadgeLabel(badge: any) {
    return this.data.badgeType === BadgeType.MULTI_LEVEL ? `Level ${badge.level}: ${badge.badgeLevelName}` : `For ${badge.value}`;
  }

  get nextLevel() {
    return this.data.badges[this.data.currentUserBadgeInfo.level];
  }

  public getProgressBarValue(badge: any) {
    return badge?.badgeCount * 100 / badge?.maxCount;
  }

  public getBadgeImageUrl() {
    if (this.isLocked) {
      return `assets/icons/locked-badge.svg`;
    }
    if (this.data?.currentUserBadgeInfo?.badgeUrl) {
      return this.data?.currentUserBadgeInfo?.badgeUrl;
    }
    return `assets/badges/badge1.png`;
  }

  public showBadgeInfo() {
    if ((this.isLocked && this.data.badgeType !== BadgeType.MULTI_LEVEL) || this.multipleBadgesCount) return false;
    return true;
  }

  // ------------------------------------------------------------------
  // Private Methods
  // ------------------------------------------------------------------

  private checkForMultipleBadges() {
    if (Array.isArray(this.data)) {
      this.multipleBadgesCount = this.data.length;
    }
  }

  private extractColors() {
    if (!this.isLocked) {
      extractColors(this.data?.currentUserBadgeInfo?.badgeUrl || 'assets/badges/badge1.png').then((colors) => {
        const imageUrl = "url('../../../../../assets/badges/confetti.png')";
        const radialGradient = `${imageUrl} 50% 50% /  80% auto no-repeat, radial-gradient(circle, ${this.util.hexToRgba(colors[1].hex, 0.5)} 0%, ${this.util.hexToRgba(colors[0].hex, 0.5)} 100%)`;
        const linearGradient = `linear-gradient(180deg,  ${this.util.hexToRgba(colors[0].hex, 0.5)} -35.58%, ${this.util.hexToRgba(colors[1].hex, 0.5)} 128.85%)`;
        const gradientColor = this.isImmediate ? radialGradient : linearGradient;
        const element = document.getElementById('badgeImage');
        if (element) {
          element.style.background = gradientColor;
        }
      }
      ).catch(console.error);
    }
  }
}
