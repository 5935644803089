import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SMS_OPTIONS } from 'src/app/constants';
import { Client } from 'src/app/models/client.model';

const defaultValues = {
  openRequestAlias: "Open",
  resumeSubmittedAlias: "Resume Submitted",
  resumeApprovedAlias: "Approved",
  resumeRejectedAlias: "Rejected",
  resumeCancelledAlias: "Canceled",
  requestClosedAlias: "Closed",
  resumeUpdateDelayedAlias: "Delayed",
}

@Component({
  selector: 'app-resume-builder-settings',
  templateUrl: './resume-builder-settings.component.html',
  styleUrls: ['./resume-builder-settings.component.scss']
})
export class ResumeBuilderSettingsComponent implements OnInit {
  private _client: Client;
  @Input() canEdit: boolean = false;
  @Output() onSave = new EventEmitter();
  editForm: FormGroup;
  smsOptions: any[] = [
    {
      title: 'Resume workflow', key: 'resumeWorkflow', type: 'select', options:[SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [Validators.required], icon: 'settings_suggest',
    },
    {
      title: 'Auto-resume generation', key: 'autoResumeGeneration', type: 'select', options:[SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [Validators.required], icon: 'settings_suggest',
    },
    {
      title: 'Generated resume download access', key: 'resumeDownloadAccess', type: 'select', options:[SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [Validators.required], icon: 'settings_suggest',
    },
    {
      title: 'Allow resume POC in resume builder', key: 'resumePocInResumeBuilder', type: 'select', options:[SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [Validators.required], icon: 'settings_suggest',
    },
    {
      title: 'Restricted entity addition list',
      key: 'restrictedEntityAdditionList',
      type: 'select',
      options: [
        {key: 'experience', value: 'Addition of experience'},
        {key: 'education', value: 'Addition of education'},
        {key: 'currentHRMSExperienceAddProject', value: 'HRMS current experience projects'},
        {key: 'currentManualExperienceAddProject', value: 'Manual current experience projects'},
        {key: 'pastHRMSExperienceAddProject', value: 'HRMS past experience projects'},
        {key: 'pastManualExperienceAddProject', value: 'Manual past experience projects'}
      ],
      icon: 'settings_suggest',
      isMulti: true,
    },
    {
      title: 'Restricted entity for profile completeness percent calculation',
      key: 'restrictedComponentsForProfileProgressCalc',
      type: 'select',
      options: [
        {key: 'currentExperience', value: 'Current experiences'},
        {key: 'pastExperience', value: 'Past experiences'},
        {key: 'education', value: 'Educations'},
        {key: 'languages', value: 'Languages'},
        {key: 'markets', value: 'Markets'},
        {key: 'awards', value: 'Awards'},
        {key: 'extracurriculars', value: 'Extracurriculars'}
      ],
      icon: 'settings_suggest',
      isMulti: true,
    },{
      title: 'Show static templates', key: 'showStaticTemplates', type: 'select', options:[SMS_OPTIONS.YES, SMS_OPTIONS.NO], defaultValue: SMS_OPTIONS.YES,
      validators: [Validators.required], icon: 'settings_suggest',
    },
    { 
      title: 'Bench resume requests threshold in months',
      key: 'thresholdForBenchResumeRequest',
      type: 'select',
      options: Array.from({ length: 12 }, (_, index) => index + 1),
      icon: 'settings_suggest',
    },
    // Aliases setting 
    { title: 'Aliases for Resume builder', type: 'sub-title' },
    {
      title: 'Open Request', key: 'openRequestAlias', type: 'text',
      validators: [], icon: 'settings_suggest',
    },
    {
      title: 'Resume Submitted', key: 'resumeSubmittedAlias', type: 'text',
      validators: [], icon: 'settings_suggest',
    },
    {
      title: 'Resume Approved', key: 'resumeApprovedAlias', type: 'text',
      validators: [], icon: 'settings_suggest',
    },
    {
      title: 'Resume Rejected', key: 'resumeRejectedAlias', type: 'text',
      validators: [], icon: 'settings_suggest',
    },
    {
      title: 'Request Canceled', key: 'resumeCancelledAlias', type: 'text',
      validators: [], icon: 'settings_suggest',
    },
    {
      title: 'Request Closed', key: 'requestClosedAlias', type: 'text',
      validators: [], icon: 'settings_suggest',
    },
    {
      title: 'Request Delayed', key: 'resumeUpdateDelayedAlias', type: 'text',
      validators: [], icon: 'settings_suggest',
    },
  ];
  
  constructor(private fb: FormBuilder) {
    this.editForm = fb.group({});
  }

  ngOnInit(): void {
  }

  @Input()
  set client(client: Client) {
    this._client = client;
    this.buildForms();
  }
  get client() {
    return this._client;
  }

  buildForms() {
    this.smsOptions.map((s) => {
      if (s.type == 'sub-title') return;
      let currentValue = this.editForm.value;
      let value = currentValue[s.key] || this.client.smsSettings[s.key] || s.defaultValue;
      if (!this.editForm.contains(s.key)) {
        this.editForm.addControl(s.key, new FormControl({ value: value, disabled: !this.canEdit }, s.validators));
      }
    });
  }

  saveData() {
    if (this.editForm.invalid) return;
    let d = this.editForm.value;
    Object.keys(d).forEach((key) => {
      if (
        d[key] == undefined || 
        d[key] == null || 
        (typeof d[key] === 'string' && !d[key].trim()) ||
        (Array.isArray(d[key]) && d[key].length === 0)
      ) {
        d[key] = defaultValues[key];
      }
    });
    d.type = 'resumeBuilder';
    this.onSave.emit(d);
  }

  evaluateEnterKey(event) {
    if ((event.srcElement.className || '').search('textarea') >= 0) return;
    event.preventDefault()
  }

}