import { Injectable } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { SMS_OPTIONS } from 'src/app/constants';
import { ISkillCategoryErrorMessage, ISkillCategoryValidation, ISkillTypeSummary } from 'src/app/services/common-service.modal'
import { ISkillItem, ICategorySkillSummary } from '../my-skills-input-tab/my-skills-input-tab.model';

@Injectable({
  providedIn: 'root'
})
export class SkillTypeValidationService {
  constructor(private ds: DataService) { }

  getCategorySkillSummary(skillItems: ISkillItem[]): ICategorySkillSummary {
    const categorySkillSummary = {};
  
    this.ds.client.smsSettings.categoryWiseSettings.forEach((category) => {
      const skillTypeArray: any[] = [];
  
      // Skip processing if enablePrimarySkill is false for this category
      if (category.enablePrimarySkill === SMS_OPTIONS.NO) {
        return;
      }
  
      category?.skillTypes?.forEach((skillType) => {
        skillTypeArray.push({
          skillTypeId: skillType.skillTypeId,
          name: skillType.name,
          min: Number(skillType.min || 0), 
          max: Number(skillType.max || 0),  
          error: '',  
          totalSkills: 0,  
        });
      });
  
      skillTypeArray.push({
        skillTypeId: null,
        name: 'Untagged',
        min: 0,
        max: 0,
        error: '',
        totalSkills: 0,
      });
  
      categorySkillSummary[category.categoryId] = {
        name: this.getCategoryName(category.categoryId),
        totalSkills: 0,
        skillTypes: skillTypeArray,
      };
    });
  
    skillItems.forEach((skill) => {
      const categoryMap = categorySkillSummary[skill.skillCategoryId ? skill.skillCategoryId : skill.skillCategory.categoryId];
      
      if (categoryMap) {
        const skillTypeObj =
          categoryMap.skillTypes.find(
            (type) => type.skillTypeId === skill.skillTypeId
          ) || categoryMap.skillTypes.find((type) => type.name === 'Untagged');

        if (skillTypeObj) {
          skillTypeObj.totalSkills += 1;
          categoryMap.totalSkills += 1;
        }
      }
    });
  
    Object.keys(categorySkillSummary).forEach((categoryId) => {
      const category = categorySkillSummary[categoryId];
  
      const categoryConfig = this.ds.client.smsSettings.categoryWiseSettings.find(cat => cat.categoryId === categoryId);
      if (categoryConfig && categoryConfig.enablePrimarySkill === SMS_OPTIONS.NO) {
        return; // Skip this category's checks
      }
  
      category?.skillTypes?.forEach((skillTypeObj) => {
        const { totalSkills, min, max } = skillTypeObj;
  
        if (min > 0 && totalSkills < min) {
          skillTypeObj.error = `Add ${min - totalSkills} skill(s).`;
        }
        else if (max > 0 && totalSkills > max) {
          skillTypeObj.error = `Reduce ${totalSkills - max} skill(s).`;
        }
        else {
          skillTypeObj.error = '';
        }
      });
    });
  
    return categorySkillSummary;
  }
  

  getSkillTypeValidationMessages(categoryId: string, skillTypeSummary: ISkillTypeSummary) {
    const skillTypes = this.ds.client.smsSettings.categoryWiseSettings.find(category => category.categoryId === categoryId)?.skillTypes;
    const skillTypeValidationError = (skillTypeSummary[categoryId]?.skillTypes || [])
      .reduce((acc, validation) => {
        if (!validation?.error) return acc;

        const error = validation.error.replace(/Reduce (\d+) skill\(s\)\./, 'Reduce at least $1 ');

        // Condition for max validation error
        if (validation.max && validation.totalSkills > validation.max) {
          acc.push({
            max: validation.max,
            name: validation.name, 
            icon: 'icons/error-rounded-red.svg',
            class: 'bg-red',
            message: `Max ${validation.max} ${validation.name} skill(s) allowed. Currently, you have ${validation.totalSkills}. ${error} ${validation.name} skill(s) to proceed.`,
            fillColor: 'var(--warning-color-dark)',
            viewBox: '0 0 24 24',
            skillTypeId: skillTypes.find(skillType => skillType.name === validation.name)?.skillTypeId,
            error: true,
          });
        }
        // Condition for min validation warning
        else if (validation.min && validation.min > validation.totalSkills) {
          acc.push({
            max: validation.max,
            name: validation.name, 
            icon: 'icons/warning-open.svg',
            class: 'bg-yellow',
            message: `Min ${validation.min} ${validation.name} skill(s) required. Currently, you have ${validation.totalSkills}. ${error} ${validation.name} skill(s) to meet the minimum criteria as it directly impacts staffing.`,
            fillColor: 'var(--color-yellow-dark)',
            viewBox: '0 0 64 64',
            skillTypeId: skillTypes.find(skillType => skillType.name === validation.name)?.skillTypeId,
            warning: true,
          });
        }

      return acc;
    }, []);

    return this.setError(skillTypeValidationError);
  }


  showOldTopContentWithoutSkillTypes(): boolean {
    for (const category of this.ds.client.smsSettings.categoryWiseSettings) {
      const hasNonEmptyMinMax = category?.skillTypes?.some(
        skillType => skillType.min || skillType.max
      );
      if (category.enablePrimarySkill === SMS_OPTIONS.YES && hasNonEmptyMinMax) {
        return false;
      }
    }
    return true;
  }

  public skillTypeMinMaxEnabled(categoryId: string) {
    const category = this.ds.client.smsSettings.categoryWiseSettings.find(setting => setting.categoryId === categoryId);
    const hasNonEmptyMinMax = category?.skillTypes?.some(
      skillType => skillType.min || skillType.max
    );
    return hasNonEmptyMinMax && category?.enablePrimarySkill === SMS_OPTIONS.YES
  }

  private setError(skillTypeValidationError: ISkillCategoryValidation[]): ISkillCategoryErrorMessage {
    const skillTypeValidation: ISkillCategoryErrorMessage = {validation: skillTypeValidationError};
    skillTypeValidationError?.forEach(validation => {
      if (validation.skillTypeId && validation.error) skillTypeValidation[validation.skillTypeId] = {
        error: true,
        tooltipMessage: `Max ${validation.max + ' ' + validation.name} skill(s) allowed`
      };
    });

    return skillTypeValidation;
  }

  getErrorText(categorySkillSummary: ICategorySkillSummary, categoryId?: string): string {
    let tooltipText = '';
  
    // Helper function to process skills within a category
    const processCategory = (categoryId) => {
      const category = categorySkillSummary[categoryId];
      const categoryName = this.getCategoryName(categoryId); // Assuming a method to fetch category names
  
      let categoryText = '';
  
      category?.skillTypes?.forEach(skillType => {
        const skillName = skillType.name;
        const totalSkills = skillType.totalSkills;
        const min = skillType.min;
        const max = skillType.max;
  
        if (min > 0 && totalSkills < min) {
          categoryText += `Min ${min} ${skillName} skill(s) required.\n`;
        }
  
        // Max constraint message (new line for Max)
        if (max > 0 && totalSkills > max) {
          categoryText += `\nMax ${max} ${skillName} skill(s) allowed.\n`;
        }
      });
  
      // Only add the category name and text if there's an error
      if (categoryText) {
        tooltipText += `${categoryName}:\n\n${categoryText.trim()}\n\n`;
      }
    };
  
    // If a specific category ID is provided, process just that category
    if (categoryId) {
      processCategory(categoryId);
    } else {
      // Otherwise, loop through all categories
      for (const categoryId in categorySkillSummary) {
        processCategory(categoryId);
      }
    }
  
    return tooltipText.trim();
  }
  
  private getCategoryName(categoryId: string): string {
    return this.ds.client.smsSettings.skillCategories.find(cat => cat.categoryId === categoryId)?.name;
  }
}
