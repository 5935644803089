import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Client } from 'src/app/models/client.model';
import { EditableTableComponent } from 'src/app/modules/common-components/editable-table/editable-table.component';
import { SharedDialogComponent } from 'src/app/modules/common-components/shared-dialog/shared-dialog.component';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';

@Component({
  selector: 'app-category-wise-settings',
  templateUrl: './category-wise-settings.component.html',
  styleUrls: ['./category-wise-settings.component.scss']
})
export class CategoryWiseSettingsComponent implements OnInit {
  private _client: Client;
  @Input() canEdit: boolean = false;
  @Input() category;
  @Output() onSave = new EventEmitter();
  editForm: FormGroup;
  settings: any;
  approvalLevelColumns: any[];
  levelWiseApprovalSettings: any[] = [];
  allowMultilevelApprover: boolean;
  maxSkillApprovalLevelRow: any;
  smsOptions: any[] = [
    { title: 'Collect experience', key: 'collectExperience', type: 'select', options: ['Yes', 'No'], validators: [Validators.required], icon: 'settings_suggest', required: true },
    { title: 'Collect skill last used', key: 'collectSkillLastUsed', type: 'select', options: ['Yes', 'No'], validators: [Validators.required], icon: 'settings_suggest', required: true },
    { title: 'Let users save skills without all mandatory inputs', key: 'allowSaveSkillsWOMandatory', type: 'select', options: ['Yes', 'No'], validators: [Validators.required], icon: 'settings_suggest', required: true, tooltip: "This validation will only be applied on the UI & not on the Inbound user skills integration." },
    { title: 'Hide 3rd level skills in hierarchy from users', key: 'hide3rdLevelHierarchy', type: 'select', options: ['Yes', 'No'], validators: [Validators.required], icon: 'settings_suggest', required: true },
    { title: 'Hide 2nd level skills in hierarchy from users', key: 'hide2ndLevelHierarchy', type: 'select', options: ['Yes', 'No'], validators: [Validators.required], icon: 'settings_suggest', required: true },
    { title: 'Check for duplicates in resume skills', key: 'checkDuplicateResumeSkills', type: 'select', options: ['Yes', 'No'], validators: [Validators.required], icon: 'settings_suggest', required: true },
    { title: 'Allow skill rating suggestions', key: 'skillRatingSuggestions', type: 'select', options: ['Yes', 'No'], validators: [Validators.required], icon: "settings_suggest", required: true },
    { title: 'Criteria to sort skills', key: 'skillSortCriteria', type: 'select', options: [], validators: [Validators.required], icon: "settings_suggest", required: true },
    { title: 'Sorting direction', key: 'skillSortDirection', type: 'select', options: ['Ascending', 'Descending'], validators: [Validators.required], icon: "settings_suggest", required: true, checkValue: [{ key: 'skillSortCriteria', values: ['Skill Name', 'Skill Rating'] }] },
    { title: 'Restrict skills shown to users', key: 'skillRestriction', type: 'select', options: [], validators: [Validators.required], icon: "settings_suggest", required: true },
    { title: 'Show required proficiency to users', key: 'showRequiredProficiencyToUsers', type: 'select', options: ['Yes', 'No'], validators: [Validators.required], icon: "settings_suggest", required: true },
    { title: 'Skill Endorsements & Manager Rating', type: 'sub-title' },
    { title: 'Ratings manager', key: 'ratingsManager', type: 'select', options: ['Primary Reporting Manager', 'Secondary Reporting Manager', 'Practice Manager', 'Practice Lead', 'Direct & Indirect Reporting Manager'], validators: [Validators.required], icon: "settings_suggest", required: true },
    { title: 'Revert Rejected or Skipped Skill to Previous Endorsed State', key: 'revertRejectedEndorsement', type: 'select', options: ['Yes', 'No'], validators: [Validators.required], icon: "settings_suggest", required: true },
  ]
  approvalKeys: string[] = ['approvalConfiguration', 'approvalSkillTypeIds', 'approvalSkillTagIds', 'approvalThresholdRating'];
  approvalOptsMap: any = {
    approvalConfiguration: 'opts',
    approvalSkillTypeIds: 'approvalSkillTypeOpts',
    approvalSkillTagIds: 'approvalSkillTagOpts',
    approvalThresholdRating: 'approvalThresholdRatingOpts',
  }
  @Input() syncButtonText: any;
  constructor(private ds: DataService, private weds: WaitErrorDialogsService, private fb: FormBuilder, private dialog: MatDialog) { this.editForm = fb.group({}) }

  ngOnInit(): void {
    this.maxSkillApprovalLevelRow = this.ds.maxSkillApprovalLevel || 2;
    this.approvalLevelColumns = [
      { title: 'Endorser', key: 'approvalManager', type: 'select', disable: !this.canEdit, required: true, validators: [Validators.required], options: ['Primary Reporting Manager', 'Secondary Reporting Manager', 'Practice Manager', 'Practice Lead', 'Direct & Indirect Reporting Manager', 'Custom Approvers', 'Primary & Secondary Reporting Manager'] },
      { title: 'Allow Skip', key: 'allowApproverToSkip', type: 'select', disable: !this.canEdit, required: true, validators: [Validators.required], options: ['Yes', 'No'] },
      { title: 'Allow Remarks', key: 'allowApprovalRemarks', type: 'select', disable: !this.canEdit, required: true, validators: [Validators.required], options: ['Yes', 'No'] },
      { title: 'Allow Delegations', key: 'allowDelegations', type: 'select', disable: !this.canEdit, required: true, validators: [], options: ['Yes', 'No'] },
      { title: 'Custom Approver', key: 'customApprover', type: 'select', disable: !this.canEdit, required: true, validators: [], options: ['Practice', 'Business Unit', 'Location', 'Service Line'] },
    ]
  }

  @Input()
  set client(client: Client) {
    this.settings = client.smsSettings.categoryWiseSettings.filter((c) => c.categoryId == this.category.categoryId)[0];
    if (!this.settings) {
      this.settings = { categoryId: this.category.categoryId, skillRatings: [], skillTypes: [], skillTags: [] };
      client.smsSettings.categoryWiseSettings.push(this.settings);
    }
    if (this.settings.skillRatings.find((rating : any) => rating.requiresApproval == 'Yes' && rating.dataKey != 'managerialRating')) {
      this.allowMultilevelApprover = true;
    }
    if(this.settings.levelWiseApprovalSettings){
      for (let i = 0; i < this.settings.levelWiseApprovalSettings.length; i++) {
        let currentApprovalSettings = this.settings.levelWiseApprovalSettings[i];
        let currentApprovalObj = {};
        ['allowApprovalRemarks', 'allowApproverToSkip', 'approvalManager'].map((key)=>{
          let dataKey = `l${currentApprovalSettings.level + key}`;
          currentApprovalObj[dataKey] = currentApprovalSettings[key];
          if (key === 'approvalManager' && currentApprovalObj[dataKey] === 'Custom Approvers') {
            currentApprovalObj[`l${currentApprovalSettings.level}customApprover`] = currentApprovalSettings[`customApprover`];
          }
        })
        this.settings = { ...this.settings, ...currentApprovalObj};
      }
    }
    this._client = client;
    this.generateApprovalConfigurationOptions();
    this.generateSkillRestrictionOptions();
    this.generateSkillSortCriteriaOptions();
    this.buildForms();
  }
  get client() {
    return this._client;
  }

  generateApprovalConfigurationOptions() {
    if (this.settings.levelWiseApprovalSettings) {
      this.settings.levelWiseApprovalSettings.forEach((level: any, index: number) => {
        const levelIndex = index + 1;
        const { opts, approvalThresholdRatingOpts, approvalSkillTypeOpts, approvalSkillTagOpts } = this.generateOptionsForApprovalKeys(
          levelIndex,
          this.approvalKeys.reduce((obj, key) => ({ ...obj, [key]:this.settings[`${key}_${index}`]}), {})
        );
        this.smsOptions.push(
          {
            title: `Skill endorsements and manager rating filters for level ${levelIndex} of endorsement`,
            type: 'sub-title', subKey: 'approvalLevelTitle'
          },
          { title: 'Skill approval configuration', key: `approvalConfiguration_${levelIndex}`, type: 'select', options: opts, validators: [Validators.required], icon: "settings_suggest", required: true },
          {
            title: `${this.ds.dictionary.skillType}s To Send For Approval `,
            key: `approvalSkillTypeIds_${levelIndex}`,
            isMulti: true,
            type: 'select',
            options: approvalSkillTypeOpts,
            validators: [Validators.required],
            icon: 'settings_suggest',
            displayKey: 'name',
            valueKey: 'typeId',
            checkValue: [
              { key: 'enablePrimarySkill', values: ['Yes'] },
              { key: `approvalConfiguration_${levelIndex}`, values: ['Skill Types'] },
            ],
            required: true,
          },
          {
            title: 'Skill Tags To Send For Approval',
            key: `approvalSkillTagIds_${levelIndex}`,
            isMulti: true,
            type: 'select',
            options: approvalSkillTagOpts,
            validators: [Validators.required],
            icon: 'settings_suggest',
            displayKey: 'name',
            valueKey: 'tagId',
            checkValue: [
              { key: 'autoPopulateSkillTagging', values: ['Yes'] },
              { key: `approvalConfiguration_${levelIndex}`, values: ['Skill Tags'] },
            ],
            required: true,
          },
          {
            title: 'Threshold Rating',
            key: `approvalThresholdRating_${levelIndex}`,
            type: 'select',
            options: approvalThresholdRatingOpts,
            validators: [Validators.required],
            icon: 'settings_suggest',
            checkValue: [{ key: `approvalConfiguration_${levelIndex}`, values: ['Skills Rated Above Or Equal To Threshold Rating'] }],
            required: true,
          }
        );
      });
    }
  }


  generateOptionsForApprovalKeys(level = 1, previousLevelConf: any) {
    let opts = ['All Skills', 'Skills Rated Above Or Equal To Threshold Rating'];
    let approvalThresholdRatingOpts =
      this.client.smsSettings.skillRating == 'Yes/No'
        ? [0, 1]
        : Array.from(
            { length: (this.client.smsSettings.skillRating || 'Stars 1 - 5').split(/\-\s*/)[1] * 1 },
            (_, i) => i + 1
          );
    let approvalSkillTypeOpts = this.settings.skillTypes;
    let approvalSkillTagOpts = this.settings.skillTags;
    if (this.settings.enablePrimarySkill == 'Yes') opts.push('Skill Types');
    if (this.settings.autoPopulateSkillTagging == 'Yes') opts.push('Skill Tags');
    // If level is greater than 1, then validate based on previous level configuration
    if (level > 1) {
      const { approvalConfiguration = '', approvalThresholdRating, approvalSkillTypeIds = [], approvalSkillTagIds = [] } = previousLevelConf;
      if (!approvalConfiguration) opts = [];
      const removeOption = (key: string) => {
        opts = opts.filter((s) => s !== key);
      }
      if (approvalConfiguration != 'All Skills') {
        removeOption('All Skills');
        switch (approvalConfiguration) {
          case 'Skills Rated Above Or Equal To Threshold Rating':
            approvalThresholdRatingOpts = approvalThresholdRatingOpts.filter(
              (rating: number) => rating >= approvalThresholdRating
            );
            break;
          case 'Skill Types':
            approvalSkillTypeOpts =
              approvalSkillTypeOpts.filter((item: any) => approvalSkillTypeIds.includes(item.skillTypeId)) || [];
            removeOption('Skill Tags');
            break;
          case 'Skill Tags':
            approvalSkillTagOpts =
              approvalSkillTagOpts.filter((item: any) => approvalSkillTagIds.includes(item.skillTagId)) || [];
            removeOption('Skill Types');
            break;
          default: break;
        }
      }
    }
    return { opts, approvalThresholdRatingOpts, approvalSkillTypeOpts, approvalSkillTagOpts };
  }

  generateSkillRestrictionOptions() {
    let opts = ['All Skills'];
    if ((this.client.smsSettings.mapSkillsToDesignations || []).filter((c) => c.categoryId == this.category.categoryId)[0] && (this.client.features.admin['designation-clusters'] || 'No Access') != 'No Access') opts.push('Skills mapped to designation cluster');
    if ((this.client.smsSettings.mapSkillsToDesignations || []).filter((c) => c.categoryId == this.category.categoryId)[0]) opts.push('Skills mapped to designations');
    if (this.client.smsSettings.useServicesInSkills == 'Yes') opts.push('Skills mapped to practice');
    this.smsOptions.filter((item) => item.key == 'skillRestriction')[0].options = opts;
  }

  generateSkillSortCriteriaOptions() {
    let opts = ['Skill Name', 'Skill Rating'];
    if (this.settings.enablePrimarySkill == 'Yes') opts.push('Skill Types');
    if (this.settings.autoPopulateSkillTagging == 'Yes') opts.push('Skill Tags');
    this.smsOptions.filter((item) => item.key == 'skillSortCriteria')[0].options = opts;
  }

  buildForms() {
    this.smsOptions.map((s) => {
      let currentValue = this.editForm.value;
      let value = currentValue[s.key] || this.settings[s.key] || s.defaultValue;
      s.showControl = true;
      const isApprovalKey = this.approvalKeys.find((key) => s.key?.includes(key));
      if (s.type == 'sub-title' && (this.settings.skillRatings.filter((rating) => rating.dataKey == 'managerialRating')[0] || {}).useRating != 'Yes' && !this.settings.skillRatings.filter((rating) => rating.requiresApproval == 'Yes' && rating.useRating == 'Yes')[0]) s.showControl = false;
      if (s.key == 'ratingsManager' && (this.settings.skillRatings.filter((rating) => rating.dataKey == 'managerialRating')[0] || {}).useRating != 'Yes') s.showControl = false;
      if (isApprovalKey || s.subKey === 'approvalLevelTitle') {
        s.showControl = false;
        this.settings.skillRatings.forEach((rating : any) => {
          if (rating.requiresApproval == 'Yes') s.showControl = true
        });
      }
      (s.allowIf || []).map((key) => { if ((this.client.features.admin[key] || 'No Access') == 'No Access') s.showControl = false; });
      (s.checkValue || []).map((item) => { if (item.values.indexOf(this.editForm.contains(item.key) ? this.editForm.get(item.key).value : this.settings[item.key]) < 0) s.showControl = false; });
      if (s.key == 'showRequiredProficiencyToUsers' && !(this.client.smsSettings.mapSkillsToDesignations || []).length) s.showControl = false;
      if (this.editForm.contains(s.key)) this.editForm.removeControl(s.key);
      if (s.showControl)  {
        this.editForm.addControl(s.key, new FormControl({ value: value, disabled: !this.canEdit }, s.validators));
        if (isApprovalKey) this.validateApprovalConfigurationOptions(s.key);
      }
    });
  }
  saveData() {
    if (this.editForm.invalid) return;
    let d = this.editForm.value;
    d.type = 'categoryWiseSettings';
    d.categoryId = this.category.categoryId;
    this.onSave.emit(d);
  }

  onSaveDataEvent(data) {
    this.onSave.emit(data);
  }

  compareSelect(o1: any, o2: any) {
    let keys = ['id', 'categoryId'];
    for (let i = 0; i < keys.length; i++) {
      if ((o1 && o1[keys[i]]) || (o2 && o2[keys[i]])) {
        return (o1 || {})[keys[i]] == (o2 || {})[keys[i]];
      }
    }
    return o1 == o2;
  }

  onSMSOptionSelected(smsOption, event) {
    if (['approvalManager', 'skillSortCriteria', 'noOfApprovalLevel'].indexOf(smsOption.key) >= 0) this.buildForms();
    if (smsOption.key.includes('approvalManager')) this.buildForms();
    if (this.approvalKeys.find((key) => smsOption.key?.includes(key))) {
      this.validateApprovalConfigurationOptions(smsOption.key);
      this.buildForms();
    }
  }

  evaluateEnterKey(event) {
    if ((event.srcElement.className || '').search('textarea') >= 0) return;
    event.preventDefault()
  }

  applySameSettingsToAllCategories() {
    this.onSave.emit({ type: 'applySameSettingsToAllCategories', categoryId: this.category.categoryId });
  }

  syncEndorsement(){
    let d = { type: 'syncEndorsementLevelChanges', categoryId: this.category.categoryId }
    this.onSaveDataEvent(d);
  }

  onMultilevelEndorsementsSave(data){
    if (!data.length) { this.weds.showDialog({ type: 'generic', code: 336 }); return; }
    let hash = {};
    let invalidItems = [];
    data.map((item, index) => {
      item.level = index + 1;
      if(!hash[item.approvalManager != 'Custom Approvers' ? item.approvalManager : item.approvalManager + '_' + item.customApprover ]) hash[item.approvalManager != 'Custom Approvers' ? item.approvalManager : item.approvalManager + '_' + item.customApprover] = 1;
      else invalidItems.push(item)
      return item;
    });
    if(invalidItems.length){
      this.weds.showDialog({ type: 'generic', code: 337 });
      return;
    }
    let d = { type: 'levelWiseApprovalSettings', levelWiseApprovalSettings: data, categoryId: this.category.categoryId }
    let dialogRef = this.dialog.open(SharedDialogComponent, { panelClass: 'shared-dialog', disableClose: false });
    dialogRef.componentInstance.data = { type: 'generic', title: 'Confirm Save', message: 'This is a dangerous setting, The changes might take few hours to reflect! ', btnTitle: 'Ok', btn2Title: 'Cancel' }
    const onEvent = dialogRef.componentInstance.onEvent.subscribe((data: any) => {
      dialogRef.close();
      if(data.event == 'BTN_CLICK'){
        this.onSaveDataEvent(d);
      }
    })
    dialogRef.afterClosed().subscribe(() => { onEvent.unsubscribe(); });
  }

  validateApprovalConfigurationOptions(key: string) {
    const level = parseInt(key.split('_')[1]);
    for (let i = (level + 1); i <= this.settings.levelWiseApprovalSettings.length; i++) {
      const configOptions = this.generateOptionsForApprovalKeys(
        i,
        this.approvalKeys.reduce((obj, key) => ({ ...obj, [key]: this.editForm.get(`${key}_${level}`)?.value }), {})
      );
      this.smsOptions = this.smsOptions.map((option) => {
        const key = (option.key || '').split('_')[0];
        const levelKey = parseInt((option.key || '').split('_')[1]);
        if (this.approvalKeys.includes(key) && levelKey == i) option.options = configOptions[this.approvalOptsMap[key]];
        return option;
      });
    }
  }
}
