import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Client } from 'src/app/models/client.model';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { ACCESS_LEVELS, SMS_OPTIONS } from "../../../../constants";

@Component({
  selector: 'app-skill-management-settings',
  templateUrl: './skill-management-settings.component.html',
})
export class SkillManagementSettingsComponent implements OnInit {
  private _client: Client;
  @Input() canEdit: boolean = false;
  @Output() onSave = new EventEmitter();
  editForm: FormGroup;

  smsOptions: any[] = [
    {
      title: 'Skill Rating Mechanism',
      key: 'skillRating',
      type: 'select',
      options: [
        'Stars 1-5',
        'Stars 1-4'
      ],
      validators: [Validators.required],
      icon: 'settings_suggest',
      defaultValue: 'Stars 1-5',
      required: true,
    },
    {
      title: 'Show Interview Feedback Form',
      key: 'manageInterviewFeedbackForm',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [Validators.required],
      icon: 'settings_suggest',
    },
    {
      title: 'Show Carousel on My skill page',
      key: 'showCarouselOnMySkills',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [Validators.required],
      icon: 'settings_suggest',
    },
    {
      title: 'Repeat my skills info carousel after? (Days)',
      key: 'repeatCarouselMySkills',
      type: 'number',
      validators: [Validators.required, Validators.min(0)],
      defaultValue: 0,
      checkValue: [{ key: 'showCarouselOnMySkills', values: [SMS_OPTIONS.YES] }],
      icon: 'settings_suggest',
    },
    {
      title: 'Enable Onboarding',
      key: 'enableOnboarding',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [Validators.required],
      icon: 'settings_suggest',
      defaultValue: SMS_OPTIONS.YES
    },
  ];

  skillRequestOptionsFormGroup: FormGroup;
  mySkillsSummaryFormGroup: FormGroup;
  showSkillAdditionRequests: boolean = false;
  isGlobalAdmin: boolean = false;

  skillRequestOptions: any[] = [
    { 
      title: 'Allow Skill Requests by Employees', 
      key: 'allowSkillRequests', 
      type: 'select', 
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO], 
      validators: [Validators.required], 
      icon: "settings_suggest", 
      defaultValue: SMS_OPTIONS.NO, 
      required: true 
    }
  ]
  mySkillsSummaryOptions: any[] = [
    { 
      title: 'Show Summary View', 
      key: 'showSummaryView', 
      type: 'select', 
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO], 
      validators: [Validators.required], 
      icon: "settings_suggest", 
      defaultValue: SMS_OPTIONS.NO, 
      required: true 
    },
    { 
      title: 'Manage Skill Description', 
      key: 'manageSkillsDescription', 
      type: 'textarea', 
      validators: [Validators.maxLength(1000)], 
      icon: 'settings_suggest' 
    },
  ]

  constructor(
    private ds: DataService,
    private fb: FormBuilder,
    private util: UtilitiesService,
  ) {
    this.editForm = this.fb.group({});    
    this.skillRequestOptionsFormGroup = this.fb.group({});
    this.mySkillsSummaryFormGroup = this.fb.group({});
  }

  ngOnInit(): void { }

  @Input()
  set client(client: Client) {
    this._client = client;
    this.setAccessValues();
    this.buildForms();
    this.buildSkillRequestOptionsForm();
    this.buildMySkillsSummaryForm();
  }
  get client() {
    return this._client;
  }

  setAccessValues() {
    this.isGlobalAdmin = this.ds.user.role.globalAdmin;
    this.showSkillAdditionRequests = this.isGlobalAdmin || this.ds.user.role.admin?.skillAdditionRequests === ACCESS_LEVELS.EDIT;
  }

  buildForms() {
    this.smsOptions.map((s) => {
      let currentValue = this.editForm.value;
      let value =
        currentValue[s.key] || this.client.smsSettings[s.key] || s.defaultValue;
      s.showControl = true;
      (s.allowIf || []).map((key) => {
        if ((this.client.features.admin[key] || ACCESS_LEVELS.NO_ACCESS) == ACCESS_LEVELS.NO_ACCESS)
          s.showControl = false;
      });
      (s.checkValue || []).map((item) => {
        if (
          item.values.indexOf(
            this.editForm.contains(item.key)
              ? this.editForm.get(item.key).value
              : this.client.smsSettings[item.key]
          ) < 0
        )
          s.showControl = false;
      });
      if (this.editForm.contains(s.key)) this.editForm.removeControl(s.key);
      if (s.showControl)
        this.editForm.addControl(
          s.key,
          new FormControl(
            { value: value, disabled: !this.canEdit },
            s.validators
          )
        );
    });
  }

  buildSkillRequestOptionsForm() {
    this.skillRequestOptions.forEach(option => {
      let currentValue = this.skillRequestOptionsFormGroup.value;
      let value = currentValue[option.key] || this.client.smsSettings[option.key];

      this.skillRequestOptionsFormGroup.addControl(
        option.key,
        new FormControl(
          { value: value, disabled: !this.canEdit },
          option.validators
        )
      );
    })
  }

  buildMySkillsSummaryForm() {
    this.mySkillsSummaryOptions.forEach(option => {
      let currentValue = this.mySkillsSummaryFormGroup.value;
      let value = currentValue[option.key] || this.client.smsSettings[option.key];

      this.mySkillsSummaryFormGroup.addControl(
        option.key,
        new FormControl(
          { value: value, disabled: !this.canEdit },
          option.validators
        )
      );
    })
  }

  saveData() {
    if (this.editForm.invalid) return;
    this.validateProficiencyTooltipStatus();
    let d = this.editForm.value;
    d.type = 'skillManagementGeneralSettings';
    this.onSave.emit(d);
  }

  saveTooltipData(event) {
    this.onSave.emit(event);
  }

  saveSkillRequestData() {
    if (this.skillRequestOptionsFormGroup.invalid) return;
    let d = this.skillRequestOptionsFormGroup.value;
    const payload = {
      allowSkillRequests: d.allowSkillRequests,
      type: 'skillAdditionRequests'
    };
    this.onSave.emit(payload);
  }


  saveMySkillsSummaryData() {
    if (this.mySkillsSummaryFormGroup.invalid) return;
    let d = this.mySkillsSummaryFormGroup.value;
    const payload = {
      showSummaryView: d.showSummaryView,
      manageSkillsDescription: d.manageSkillsDescription ? d.manageSkillsDescription : '',
      type: 'mySkillsSummary'
    };
    this.onSave.emit(payload);
  }

  compareSelect(o1: any, o2: any) {
    let keys = ['id', 'categoryId'];
    for (let i = 0; i < keys.length; i++) {
      if ((o1 && o1[keys[i]]) || (o2 && o2[keys[i]])) {
        return (o1 || {})[keys[i]] == (o2 || {})[keys[i]];
      }
    }
    return o1 == o2;
  }

  onSMSOptionSelected(smsOption, { value: selectedValue }) {
    [
      'manageInterviewFeedbackForm',
      'showCarouselOnMySkills',
    ].includes(smsOption.key) && this.buildForms();
  }

  evaluateEnterKey(event) {
    if ((event.srcElement.className || '').search('textarea') >= 0) return;
    event.preventDefault();
  }

  validateProficiencyTooltipStatus() {
    if (this.editForm?.value?.useSkillProficiency === SMS_OPTIONS.NO) {
      this.editForm.controls?.useProficiencyTooltip?.setValue(SMS_OPTIONS.NO);
      this.editForm.controls?.proficiencyOptions?.setValue([]);
    }
    if (this.editForm?.value?.proficiencyOptions?.indexOf('Description') === -1)
      this.editForm.controls?.useProficiencyTooltip?.setValue(SMS_OPTIONS.NO);
  }

  checkProficiencyMasterEnabled(): boolean {
    return this.editForm?.value?.useSkillProficiency === SMS_OPTIONS.YES;
  }

  renderSkillProficiencyRelationNode(key: string): boolean {
    return /useProficiencyTooltip|proficiencyOptions/.test(key)
      ? this.checkProficiencyMasterEnabled()
      : true;
  }
}