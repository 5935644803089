import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DataService } from '../services/data.service';
import { ImmediateBadgeUpdatesService } from '../services/immediate-badge-updates.service';
import { WaitErrorDialogsService } from '../services/wait-error-dialogs.service';
import { DIALOG_TYPES } from '../constants';

@Injectable()
export class GeneralApiInterceptor implements HttpInterceptor {

  constructor(
    private ds: DataService,
    private immediateBadgeUpdates: ImmediateBadgeUpdatesService,
    private weds: WaitErrorDialogsService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap(result => {
        if (result?.['body']?.['status'] === 'Logout') {
          this.ds.onLogout();
        }
        // Handle user engagement data
        const userEngagement = result?.['body']?.['userEngagement'];
        if (userEngagement?.['data']) {
          const userEngagementData = userEngagement['data'];
          if (userEngagementData?.['badges']?.length > 0) {
            this.immediateBadgeUpdates.setBadgeUpdates(userEngagementData['badges']);
          }
        } else if (userEngagement?.['error']) {
          console.error(userEngagement['error']);
        }
      }),
      catchError((requestError: HttpErrorResponse) => {
        this.handleApiFailure(requestError);
        return EMPTY;
      })
    );
  }

  handleApiFailure(err: any): void {
    switch (err?.status) {
      case 401:
      case 402:
        this.ds.onLogout();
        break;
      case 403:
        this.weds.showDialog({
          type: DIALOG_TYPES.GENERIC,
          code: 403,
          message: err.error.message,
          dontCloseAllDialogs: true,
          isApiFailureDialog: true,
        });
        break;
      default:
        this.weds.showDialog({
          type: DIALOG_TYPES.GENERIC,
          code: 0,
          dontCloseAllDialogs: true,
          isApiFailureDialog: true,
        });
        break;
    }
  }
}
