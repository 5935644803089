<div *ngIf="settings">
    <div class="h4 black mb-3">Skill Tags</div>
    <form [formGroup]="editForm" (ngSubmit)="saveSMSOptions()" (keydown.enter)="$event.preventDefault()">
        <div class="admin-form-con admin-input-con-full">
            <mat-form-field appearance="outline" class="admin-input-form-field" style="width:100%;margin-top:10px;">
                <mat-label>Auto Populate Skill Tagging</mat-label>
                <mat-icon matPrefix>settings_suggest</mat-icon>
                <mat-select formControlName="autoPopulateSkillTagging" required
                    (selectionChange)="onSkillTagsSelectionChange($event)">
                    <mat-option value="Yes">Yes</mat-option>
                    <mat-option value="No">No</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('autoPopulateSkillTagging').invalid">{{'This input is
                    mandatory'}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="admin-input-form-field" style="width:100%;margin-top:10px;"
                *ngIf="editForm.get('autoPopulateSkillTagging').value=='Yes'">
                <mat-label>Auto Populate Skill Tagging Based On</mat-label>
                <mat-icon matPrefix>settings_suggest</mat-icon>
                <mat-select formControlName="autoPopulateSkillTaggingCriteria" required multiple>
                    <mat-option value="Specializations">Specializations</mat-option>
                    <mat-option value="Demand Unit">Demand Unit</mat-option>
                    <mat-option value="Skill Cluster">Skill Cluster</mat-option>
                    <mat-option value="SKU">SKU</mat-option>
                    <mat-option value="Designation">Designation</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('autoPopulateSkillTaggingCriteria').invalid">{{'This input is
                    mandatory'}}
                </mat-error>
            </mat-form-field>
        </div>
    </form>
    <div class="table-con" *ngIf="editForm.get('autoPopulateSkillTagging').value=='Yes'">
        <app-editable-table #skillTags [canEdit]="canEdit" [enableDelete]="true" [columns]="skillTagColumns"
            class="sms-editable-table" [dataSource]="settings.skillTags" maxRows="3"
            footNotes="You need to create minimum of 2 skill tags to enable skill tags. If you create 3 tags, the 1st one will be required and 2nd one will be good to have"
            [enableDrag]="true">
        </app-editable-table>
    </div>
    <div class="admin-save-button-con" *ngIf="canEdit">
        <button *ngIf="editForm.get('autoPopulateSkillTagging').value=='Yes'" mat-stroked-button
            class="secondary-button" type="button" (click)="addSkillTagsRow()">Add</button>
        <button mat-stroked-button class="primary-button" (click)="saveData()">Save</button>
    </div>
</div>