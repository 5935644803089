import { Injectable } from "@angular/core";
import { Badge } from "../modules/badges/badges.model";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ImmediateBadgeUpdatesService {
  private badgeUpdates = new Subject<Badge[]>();

  public getBadgeUpdates(): Observable<Badge[]> {
    return this.badgeUpdates.asObservable();
  }

  public setBadgeUpdates(badges: Badge[]) {
    this.badgeUpdates.next(badges);
  }
}
