<div class="d-flex flex-column gap-3">
    <div class="h3 black ">Custom Tags</div>
    <section class="d-flex flex-column gap-2">
        <div class="d-flex justify-content-between">
            <mat-form-field *ngIf="canEdit" class="input-form-field admin-input-con-half" appearance="outline">
                <mat-label>Add new custom tag</mat-label>
                <input matInput placeholder="Custom tag" [(ngModel)]="newCustomTag" (keyup.enter)="addCustomTag()"/>
            </mat-form-field>
            <mat-form-field appearance="outline" class="input-form-field admin-input-con-half">
                <mat-label>Choose from available custom tags</mat-label>
                <mat-icon matPrefix>settings_suggest</mat-icon>
                <mat-select multiple [formControl]="customTagsAvailableFieldsFormControl"
                    (selectionChange)="customTagsChosenForMaster($event)">
                    <mat-option *ngFor="let field of userCustomTagPresets" [value]="field?.dataKey">
                        {{field?.field}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="d-flex flex-column gap-3">
            <form [formGroup]="fieldsForCustomTagsFormGroup">
                <div class="d-flex flex-column custom-tags border-radius-4">
                    <div
                        class="d-flex custom-tag flex-row align-items-center col-gap-2 pt-2 pr-4 pb-2 pl-4 h4 black font-weight-bold">
                        <span class="flex-grow-1">Tag</span>
                        <div class="d-flex flex-row align-items-center col-gap-3">
                            <span class="w-200">Mandatory/Optional</span>
                            <span class="w-200">Input Type</span>
                            <span class="w-200 pr-5">Additional Fields</span>
                            <mat-icon class="white">clear</mat-icon>
                        </div>
                    </div>
                    <div class="d-flex custom-tag flex-row align-items-center col-gap-3 pt-1 pr-4 pb-1 pl-4"
                        *ngFor="let field of fieldsForCustomTags; index as i;">
                        <span class="flex-grow-1">{{ field.field }}</span>
                        <span class="d-flex flex-row align-items-center col-gap-3 w-200">
                            <mat-form-field appearance="outline" class="admin-input-form-field">
                                <mat-label>Choose option</mat-label>
                                <mat-select [disabled]="field?.dataKey === 'staffingRole'" [formControlName]="field.dataKey + 'isMandatory'">
                                    <mat-option *ngFor="let option of requiredOptions" [value]="option">{{option}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </span>
                        <span class="d-flex flex-row align-items-center col-gap-3 w-200">
                            <mat-form-field appearance="outline" class="admin-input-form-field">
                                <mat-label>Choose option</mat-label>
                                <mat-select [formControlName]="field.dataKey + 'isMultiSelect'">
                                    <mat-option *ngFor="let option of inputTypeOptions" [value]="option">{{option}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </span>
                        <span class="d-flex flex-row align-items-center col-gap-3 w-200 pr-5">
                            <mat-form-field appearance="outline" class="admin-input-form-field">
                                <mat-label>Choose options</mat-label>
                                <mat-select [disabled]="field?.dataKey === 'staffingRole'" [formControlName]="field.dataKey + 'additionalFields'" multiple>

                                    <!-- Preconfigured Additional fields -->
                                    <mat-option *ngFor="let addtionalField of preconfiguredAddtionalField" [value]="addtionalField">{{addtionalField}}
                                    </mat-option>

                                    <!-- Additional fields coming from BE -->
                                    <mat-option *ngFor="let addtionalField of field.addtionalFields" [value]="addtionalField">{{addtionalField}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </span>
                        <mat-icon *ngIf="canEdit" (click)="removeFieldCustomTags(field.dataKey)" class="opacity-2 opacity-hover-7 cursor-pointer">clear</mat-icon>
                    </div>
                </div>
            </form>
        </div>
        <div *ngIf="canEdit" class="admin-save-button-con">
            <button type="submit" (click)="saveCustomTags()" mat-stroked-button class="primary-button">Save</button>
        </div>
    </section>
</div>
