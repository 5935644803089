import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Client } from 'src/app/models/client.model';

@Component({
  selector: 'app-homepage-settings',
  templateUrl: './homepage-settings.component.html'
})
export class HomepageSettingsComponent implements OnInit {
  private _client: Client;
  @Input() canEdit: boolean = false;
  @Output() onSave = new EventEmitter();
  editForm: FormGroup;

  smsOptions: any[] = [
    {
      title: 'Show Homepage Tech Feed',
      key: 'showHomepageFeed',
      type: 'select',
      options: ['Yes', 'No'],
      validators: [Validators.required],
      icon: 'settings_suggest',
    },
  ]

  constructor(private fb: FormBuilder) {
    this.editForm = fb.group({});
    this.smsOptions.forEach((smsOption) => {
      this.editForm.addControl(smsOption.key, new FormControl('', smsOption.validators));
    });
  }

  ngOnInit(): void {}

  @Input()
  set client(client: Client) {
    this._client = client;
    this.buildForms();
  }
  get client() {
    return this._client;
  }

  buildForms(){
    this.smsOptions.forEach((s) => {
      let currentValue = this.editForm.value;
      let value = currentValue[s.key] || this.client.smsSettings[s.key] || s.defaultValue;
      s.showControl = true;
      this.editForm.get(s.key).setValue(value);
      (s.checkValue || []).map((item) => {
        if( item.values.indexOf(this.editForm.get(item.key) ? this.editForm.get(item.key).value : this.client.smsSettings[item.key]) < 0 ) s.showControl = false;
      })
    })
  }

  saveData() {
    if (this.editForm.invalid) return;
    let d = this.editForm.value;
    d.type = 'homepageSettings';
    this.onSave.emit(d);
  }

  evaluateEnterKey(event) {
    if ((event.srcElement.className || '').search('textarea') >= 0) return;
    event.preventDefault();
  }

  compareSelect(o1: any, o2: any) {
    let keys = ['id', 'categoryId'];
    for (let i = 0; i < keys.length; i++) {
      if ((o1 && o1[keys[i]]) || (o2 && o2[keys[i]])) {
        return (o1 || {})[keys[i]] == (o2 || {})[keys[i]];
      }
    }
    return o1 == o2;
  }

}
