<div class="hrms-masters">
    <div class="h3 black ">HRMS Masters</div>
    <main class="d-flex flex-column gap-3">
        <section class="d-flex flex-column gap-3">
            <div class="d-flex flex-column gap-3">

                <!-- HRMS Fields available to select in HRMS Master -->
                <mat-form-field class="admin-input-con-full font-weight-normal" appearance="outline">
                    <mat-label>Choose from available HRMS fields</mat-label>
                    <mat-select multiple [formControl]="hrmsMasterAvailableFieldsFormControl"
                        (selectionChange)="hrmsFieldsChosenForMasterChanged($event)">
                        <mat-option *ngFor="let field of hrmsMasterFields" [value]="field.field">
                            {{field.field}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- Selected HRMS Fields in HRMS Master -->
                <form [formGroup]="hrmsFieldsChosenForMasterFormGroup" (ngSubmit)="saveHrmsMasters()">
                    <div class="d-flex flex-column hrms-tags border-radius-4">
                        <div
                            class="d-flex hrms-tag flex-row align-items-center col-gap-2 pt-2 pr-4 pb-2 pl-4 h4 black font-weight-bold">
                            <span class="flex-grow-1">Tag</span>
                            <div class="d-flex flex-row align-items-center col-gap-3">
                                <span class="w-200">Mandatory/Optional</span>
                                <span class="w-200">Input Type</span>
                                <span class="w-200 pr-5">Additional Fields</span>
                                <mat-icon class="white">clear</mat-icon>
                            </div>
                        </div>
                        <div class="d-flex hrms-tag flex-row align-items-center col-gap-3 pt-1 pr-4 pb-1 pl-4"
                            *ngFor="let field of hrmsFieldsChosenForMaster; index as i;">
                            <span class="flex-grow-1">{{ field.field }}</span>
                            <span class="d-flex flex-row align-items-center col-gap-3 w-200">
                                <mat-form-field appearance="outline" class="admin-input-form-field">
                                    <mat-label>Choose option</mat-label>
                                    <mat-select [formControlName]="field.dataKey + 'isMandatory'">
                                        <mat-option *ngFor="let option of requiredOptions" [value]="option">{{option}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </span>
                            <span class="d-flex flex-row align-items-center col-gap-3 w-200">
                                <mat-form-field appearance="outline" class="admin-input-form-field">
                                    <mat-label>Choose option</mat-label>
                                    <mat-select [formControlName]="field.dataKey + 'isMultiSelect'">
                                        <mat-option *ngFor="let option of inputTypeOptions" [value]="option">{{option}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </span>
                            <span class="d-flex flex-row align-items-center col-gap-3 w-200 pr-5">
                                <mat-form-field appearance="outline" class="admin-input-form-field">
                                    <mat-label>Choose options</mat-label>
                                    <mat-select [formControlName]="field.dataKey + 'additionalFields'" multiple>
    
                                        <!-- Preconfigured Additional fields -->
                                        <mat-option *ngFor="let addtionalField of preconfiguredAddtionalField" [value]="addtionalField">{{addtionalField}}
                                        </mat-option>
    
                                        <!-- Additional fields coming from BE -->
                                        <mat-option *ngFor="let addtionalField of field.addtionalFields" [value]="addtionalField">{{addtionalField}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </span>
                            <mat-icon *ngIf="canEdit" (click)="removeFieldFromHrmsMasters(field.dataKey)" class="opacity-2 opacity-hover-7 cursor-pointer">clear</mat-icon>
                        </div>
                    </div>
                </form>
            </div>
            <div *ngIf="canEdit" class="admin-save-button-con">
                <button type="submit" (click)="saveHrmsMasters()" mat-stroked-button class="primary-button">Save</button>
            </div>
        </section>
        <!-- <section class="d-flex flex-column gap-3 admin-input-con-half">
           <app-drag-and-drop [input]="DataOrderComponentConfig"></app-drag-and-drop>
            <div class="admin-save-button-con">
                <button type="submit" (click)="saveHrmsMasters()" mat-stroked-button class="primary-button">Save</button>
            </div>
        </section> -->
    </main>
</div>