export interface ILearningTypes {
  name?: string;
  enable: boolean;
  isCustom?: boolean;
  id?: string;
  key?: string;
}

export const DEFAULT_LEARNING_TYPES = [
  {
      "id": "certification",
      "name": "CERTIFICATION",
      "enable": true,
      "isCustom": false
  },
  {
      "id": "course",
      "name": "COURSE",
      "enable": true,
      "isCustom": false
  },
  {
      "id": "assesment",
      "name": "ASSESSMENT",
      "enable": true,
      "isCustom": false
  },
  {
      "id": "assignemnt",
      "name": "ASSIGNMENT",
      "enable": true,
      "isCustom": false
  }
]