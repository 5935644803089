import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { take } from 'rxjs/operators';
import { DIALOG_TYPES, RESPONSE_STATUS, SMS_OPTIONS } from 'src/app/constants';
import { API_ENDPOINT } from 'src/app/constants/api-endpoint.constants';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { IStaffingRoleListResponse, IStaffingRoleModalData } from './staffing-role-modal.model';
import { IStaffingRole } from 'src/app/models/user.model';
import { StaffingRoleService } from 'src/app/services/staffing-role.service';

@Component({
  selector: 'app-staffing-role-modal',
  templateUrl: './staffing-role-modal.component.html',
  styleUrls: ['./staffing-role-modal.component.scss']
})
export class StaffingRoleModalComponent implements OnInit {

  @Input() data: IStaffingRoleModalData = {
    isProfileModal: false,
    selectedStaffingRole: []
  };
  @Output() onEvent = new EventEmitter();
  public staffingRoleForm: FormGroup;
  public waveEmoji = '\u{1F44B}';
  public maxLimit: number = 0; 
  public staffingRoleInput = {
    key: 'staffingRoles',
    disabled: false, 
    title: 'Choose from below options ', 
    placeholder: 'Search for staffing role',
    type: 'auto-complete-multi', 
    selectedItems: [], 
    isMulti: false, 
    options: [], 
    responseKey: 'data',
    nameField: 'tagValue',
    idField: '_id',
    hideSelectAllCheckbox: true,
    actionableLinkText: 'None of these apply? Enter desired role(s)',
    disableSelection: false
  };
  public selectedRoles = [];
  public desiredRolesInput = {
    key: 'desiredRoles',
    value: [],
    subTitle: 'Enter roles that best describe your work.',
    placeholder: 'Enter desired role(s) and hit enter',
  }
  public isStaffingRoleMandatory: boolean = false;
  public showDesiredRoles: boolean = false;

  constructor(
    private fb: FormBuilder,
    private ds: DataService,
    private weds: WaitErrorDialogsService,
    private staffingRoleService: StaffingRoleService
  ) { }

  ngOnInit(): void {
    const { smsSettings } = this.ds.client;
    this.maxLimit = smsSettings.maximumStaffingRoles;
    this.isStaffingRoleMandatory = smsSettings.isStaffingRoleMandatory === SMS_OPTIONS.YES;

    this.buildStaffingRoleForm();
    this.getUserStaffingRoles();   
    this.getStaffingRolesList();
    this.setData();
  }

  public onMultiAutoCompleteChange(event) {
    if(event.type === 'linkClicked') {
      this.showDesiredRoles = true;
      this.staffingRoleInput = {
        ...this.staffingRoleInput,
        selectedItems: [],
        disableSelection: true,
        disabled: true,
        title: 'None of these apply'
      }
    }
    const selectedItems = event['selectedItems'];
    this.selectedRoles = selectedItems;
    this.staffingRoleForm.get('staffingRoles').setValue(this.selectedRoles);

  }

  public onRemove(item: IStaffingRole) {
    this.selectedRoles = this.selectedRoles.filter(role => role !== item);
    this.staffingRoleForm.get('staffingRoles').setValue(this.selectedRoles);
    this.staffingRoleInput = {
      ...this.staffingRoleInput,
      selectedItems: this.selectedRoles
    }
  }

  public onDismiss() {
    this.onEvent.emit({ type: 'close' });
  }

  public onRequestRoleClose() {
    this.showDesiredRoles = false;
    this.staffingRoleInput = {
      ...this.staffingRoleInput,
      disableSelection: false,
      disabled: false,
      title: 'Choose from below options'
    }
    this.staffingRoleForm?.get('desiredRoles').setValue([])
  }

  public onSave(){
    const loaded = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -5, dontCloseAllDialogs: true });
    const saveDataConfig = this.getSaveDataConfig();
    this.ds.saveData(saveDataConfig.endPoint, saveDataConfig.payload)
    .pipe(take(1))
    .subscribe((rs: {status: string, error?: any}) => {
      this.weds.closeDialog(loaded);
      if (rs.status == RESPONSE_STATUS.SUCCESS) {
        const staffingRoles = this.staffingRoleForm?.value?.staffingRoles || [];
        const desiredRoles = this.staffingRoleForm?.value?.desiredRoles || [];
        if (staffingRoles.length || (!staffingRoles.length && !desiredRoles.length)) {
          this.ds.saveUser({ ...this.ds.user, staffingRole: staffingRoles });
          this.onEvent.emit({ type: 'save', selectedRoles: staffingRoles });
        }
        this.weds.showDialog({type: DIALOG_TYPES.GENERIC, code: -1 });
      } else {
        this.weds.showDialog({ type: DIALOG_TYPES.GENERIC, code: 99, message: rs.error?.description });
      }
    })
  }

  public get isMaxLimitReached() {
    return this.selectedRoles?.length > this.maxLimit; 
  }

  public get isFormEmpty () {
    const { staffingRoles = [], desiredRoles = [] } = this.staffingRoleForm.value;
    return this.ds.client.smsSettings.isStaffingRoleMandatory === SMS_OPTIONS.YES ? !staffingRoles.length && !desiredRoles.length : false;
  }

  private setData () {
    this.staffingRoleInput = {
      ...this.staffingRoleInput,
      selectedItems: this.data.selectedStaffingRole || [],
    }
    this.selectedRoles = this.data.selectedStaffingRole || [];
  }

  private getSaveDataConfig() {
    const formValues = this.staffingRoleForm.value;
    const staffingRoles = formValues.staffingRoles?.map(role => role._id) || [];
    const requestedRoles = formValues.desiredRoles || [];
    const staffingRolePayload = {
      tag: 'staffingRole',
      userId: this.ds.user.userId,
      tagIds: staffingRoles,
    }

    return {
      endPoint: !requestedRoles.length ? API_ENDPOINT.SAVE_USER_CUSTOM_TAGS : API_ENDPOINT.SAVE_REQUESTED_STAFFING_ROLE,
      payload : {
        userId: this.ds.user.userId,
       ...(!requestedRoles.length  && {...staffingRolePayload}),
       ...(requestedRoles.length  && {requestedStaffingRole: requestedRoles})
      }
    }
  }

  private buildStaffingRoleForm() {
    this.staffingRoleForm = this.fb.group({
      staffingRoles: [{  value: this.selectedRoles, disabled: false }],
      desiredRoles: [{ value: [], disabled: false }]
    });
  }

  private getStaffingRolesList() {
    const loader = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true  });
    const payload = { clientId: this.ds.currentAdminClientId, attributeType: 'staffingRole', tagType: 'Custom' };
    this.ds.searchData(API_ENDPOINT.GET_TAG_VALUES_FOR_TAG_SMS, payload )
    .pipe(take(1))
    .subscribe((rs: IStaffingRoleListResponse) => {
      this.weds.closeDialog(loader);
      if (rs.status === RESPONSE_STATUS.SUCCESS) {
        this.handleSuccess(rs);
      } else {
        this.weds.showDialog({ type: DIALOG_TYPES.GENERIC, code: rs.error ? rs.error.code : 99 });
      }
    });
  }

  private handleSuccess(res: IStaffingRoleListResponse) {
    this.staffingRoleInput = {
      ...this.staffingRoleInput,
      options: res.data
    }
  }

  private getUserStaffingRoles() {
    this.staffingRoleService.fetchUserStaffingRoles().pipe(take(1)).subscribe(roles => {
      this.data.selectedStaffingRole = roles
      this.setData();
    });
  }

}
