export interface ISMSSettings {
    faqText:                                           string;
    skuAlias:                                          string;
    roleAlias:                                         string;
    updatedBy:                                         string;
    ratingType:                                        string;
    myTeamAlias:                                       string;
    skillRating:                                       string;
    maxBookmarks:                                      string;
    resultStatus:                                      YesOrNo;
    bookmarkAlias:                                     string;
    optionalAlias:                                     string;
    servicesAlias:                                     string;
    useExtSkillId:                                     YesOrNo;
    useVersioning:                                     YesOrNo;
    editableFields:                                    YesOrNo;
    mandatoryAlias:                                    string;
    resumeWorkflow:                                    YesOrNo;
    showSkillGraph:                                    YesOrNo;
    useDemandValue:                                    YesOrNo;
    useHighDemandSkillTagging:                         YesOrNo;
    useSkillOrigin:                                    YesOrNo;
    emailSenderName:                                   string;
    isHotjarEnabled:                                   YesOrNo;
    isAllEmployeesProfileAccessEnabled:                 YesOrNo;
    skillGroupAlias:                                   string;
    topPicksEnabled:                                   YesOrNo;
    useResultStatus:                                   YesOrNo;
    demandValueScale:                                  string;
    designationAlias:                                  string;
    editPastProjects:                                  number;
    helpEmailAddress:                                  string;
    openRequestAlias:                                  string;
    showHomepageFeed:                                  YesOrNo;
    teamDetailsAlias:                                  string;
    usePrioritySkill:                                  YesOrNo;
    useStaffingSkill:                                  YesOrNo;
    duWeightageForSKU:                                 string;
    skillClusterAlias:                                 string;
    updatedBySlUserId:                                 string;
    uploadResumeAlias:                                 string;
    useSkillAdjacency:                                 YesOrNo;
    useSkillWeightage:                                 YesOrNo;
    addSkillFromCourse:                                YesOrNo;
    allowMachineRating:                                YesOrNo;
    allowSkillRequests:                                YesOrNo;
    coreMandatoryAlias:                                string;
    manageCourseSkills:                                YesOrNo;
    prioritySkillScale:                                string;
    proficiencyOptions:                                string[];
    requestClosedAlias:                                string;
    specializationMode:                                string;
    useSkillComplexity:                                YesOrNo;
    useSkillMasterTags:                                YesOrNo;
    afterCompletionOfLC:                               string[];
    fieldsForCustomTags:                               ICustomTag[];
    manageProjectSkills:                               YesOrNo;
    mapCategoriesToSkus:                               IMapCategoriesToSkus[];
    minResumeUploadDate:                               string;
    resumeApprovedAlias:                               string;
    resumeRejectedAlias:                               string;
    skillMasterHrmsTags:                               IHrmsFieldsChosenForMaster[];
    useServicesInSkills:                               YesOrNo;
    useSkillClusterTags:                               YesOrNo;
    useSkillProficiency:                               YesOrNo;
    autoResumeGeneration:                              YesOrNo;
    canScanForDuplicates:                              YesOrNo;
    categoryWiseSettings:                              ICategoryWiseSetting[];
    designationPathAlias:                              string;
    enableDuplicateCheck:                              YesOrNo;
    limitDirectBookmarks:                              YesOrNo;
    locationClusterAlias:                              string;
    manageLearningsAlias:                              string;
    myLearningModuleType:                              string;
    resumeCancelledAlias:                              string;
    resumeDownloadAccess:                              YesOrNo;
    resumeSubmittedAlias:                              string;
    shouldShowAllCourses:                              YesOrNo;
    showSkillDefinitions:                              YesOrNo;
    specializationsAlias:                              string;
    tagFiltersEnabledFor:                              string[];
    useSkillIntroduction:                              YesOrNo;
    addResumeUploadedDate:                             YesOrNo;
    designationLevelAlias:                             string;
    extDesignationIdAlias:                             string;
    showSocialEndorsement:                             YesOrNo;
    skillMasterCustomTags:                             ICustomTag[];
    useProficiencyTooltip:                             YesOrNo;
    allowSecureBulkUploads:                            YesOrNo;
    levelOfSpecialization0:                            string;
    levelOfSpecialization1:                            string;
    levelOfSpecialization2:                            string;
    levelOfSpecialization3:                            string;
    levelOfSpecialization4:                            string;
    repeatCarouselMySkills:                            string;
    showAllSpecialisations:                            YesOrNo;
    showCarouselOnMySkills:                            YesOrNo;
    skillClusterCustomTags:                            ICustomTag[];
    specializationTierType:                            string;
    useCoreMandatorySkills:                            YesOrNo;
    useLeafSkillItemExtTag:                            YesOrNo;
    allowBlankMachineRating:                           YesOrNo;
    configureSkillsTooltip1:                           string;
    configureSkillsTooltip2:                           string;
    configureSkillsTooltip3:                           string;
    configureSkillsTooltip5:                           string;
    designationClusterAlias:                           string;
    hrmsFieldsChosenForTags:                           IHrmsFieldsChosenForMaster[];
    locationWeightageForSKU:                           string;
    mapSkillClustersToUsers:                           YesOrNo;
    mapSkillsToDesignations:                           IMapCategoriesToSkus[];
    optionalSkillsWeightage:                           string;
    specializationSelection:                           string;
    useCertificationsMaster:                           YesOrNo;
    autoStartPreSelectedSpec:                          YesOrNo;
    externalCoursesSelection:                          string;
    limitHRMSFieldsInReports:                          YesOrNo;
    mandatorySkillsWeightage:                          string;
    numActiveExternalCourses:                          number;
    numActiveSpecializations:                          number;
    resumeUpdateDelayedAlias:                          string;
    skillHierarchyCustomTags:                          ICustomTag[];
    skillRecommendationAlias:                          string;
    useAdditionalSkillFields:                          string[];
    additionalMandatoryFields:                         string[];
    allowManualCertifications:                         YesOrNo;
    conditionalMandatoryAlias:                         string;
    designationPathsThreshold:                         string;
    hideProjectWithZeroSkills:                         YesOrNo;
    hrmsFieldsChosenForMaster:                         IHrmsFieldsChosenForMaster[];
    isCoreMandatoryCompulsory:                         YesOrNo;
    maxDesignationJumpAllowed:                         string;
    numDaysDropSpecialization:                         string;
    numDaysGapForProjectMerge:                         number;
    repeatEducationalCarousal:                         string;
    specializationTypeTagging:                         YesOrNo;
    useAdditionalMasterFields:                         string[];
    certificationRequestEmails:                        string[];
    dropSpecializationsEnabled:                        YesOrNo;
    hrmsFieldsChosenForReports:                        any[];
    isMandatorySkillCompulsory:                        YesOrNo;
    levelOfSpecializationCount:                        string;
    manageCertificationsSkills:                        YesOrNo;
    primarySpecializationAlias:                        string;
    showTrendingSpecialiations:                        YesOrNo;
    allowSkillAdjacencyDownload:                       YesOrNo;
    domainSkillsWeightageForSKU:                       string;
    manageInterviewFeedbackForm:                       YesOrNo;
    mapExternalIdToDesignations:                       YesOrNo;
    numMonthsToShowFeedbackForm:                       string;
    skillWeightageForDemandUnit:                       string;
    useEndorsementStatusInScans:                       string[];
    useFinishingLearningElement:                       string;
    useTagFilteringOnTheBasisOf:                       string;
    allowManagersToAssignCourses:                      YesOrNo;
    autoPopulateSkillHierarchyId:                      YesOrNo;
    condMandatorySkillsWeightage:                      string;
    coreMandatorySkillsWeightage:                      string;
    customThresholdForFuzzyMatch:                      number;
    secondarySpecializationAlias:                      string;
    skillProfEndorsStatusPending:                      string;
    skillProfEndorsStatusSkipped:                      string;
    useSkillClusterBasedInsights:                      YesOrNo;
    addUUIDinClusterMappingReport:                     YesOrNo;
    designationPathsScoringMethod:                     string;
    isLeafSkillsCustomTagsEnabled:                     YesOrNo;
    skillProfEndorsStatusEndorsed:                     string;
    skillProfEndorsStatusRejected:                     string;
    specializationCategoryTagging:                     YesOrNo;
    specializationRequestsEnabled:                     YesOrNo;
    additionalSpecializationFields:                    string[];
    allowSocialEndorsementRequests:                    YesOrNo;
    skillClusterForManageLearnings:                    YesOrNo;
    skillClusterforManageLearnings:                    YesOrNo;
    allowSkillClusterServiceLinking:                   YesOrNo;
    numActivePrimarySpecializations:                   number;
    specializationMatchScoreEnabled:                   string[];
    useCustomThresholdForFuzzyMatch:                   YesOrNo;
    mapSpecializationsToDesignations:                  YesOrNo;
    skillProfEndorsStatusNotRequired:                  string;
    designationWeightageForDemandUnit:                 string;
    dropPrimarySpecializationsEnabled:                 YesOrNo;
    numActiveSecondarySpecializations:                 number;
    allowDropPreSelectedSpecialization:                YesOrNo;
    leafSkillExtTagGeneratedOutsideSms:                YesOrNo;
    userCertificationsAdditionalFields:                any[];
    isSkillClusterExternalIDFromOutside:               YesOrNo;
    skillRecommendationFieldsForWebhook:               string[];
    specializationSuggestionsOnHomePage:               YesOrNo;
    supportedFileFormatsForUploadResume:               string[];
    skillRatingTypeForLCCompletionUpdate:              string;
    useSpecializationsInDesignationPaths:              YesOrNo;
    skillRecommendationFeatureDescription:             string;
    autoPopulateRequestedSpecializationSkills:         YesOrNo;
    checkSkillGapsForSpecializationAssignment:         YesOrNo;
    useSkillCategoryWeightsInDesignationPaths:         YesOrNo;
    categoriesEnabledForSpecializationRequests:        string[];
    externalIdAsUniqueIdentifierForDesignations:       YesOrNo;
    tagFilteringInskillClusterForManageLearnings:      string[];
    restrictDesignationPathsToDesignationClusters:     YesOrNo;
    numberOfDigitsUsedForAutoPopulateSkillHierarchyId: number;
    skillHierarchy:                                    ISkillHierarchy[];
    skillCategories:                                   IMapCategoriesToSkus[];
    customOptions:                                     ICustomOption[];
    practices:                                         IPractice[];
    endorsementsAlias:                                 string;
    coolingOffPeriodForRejectedSpecializations:        number;
}

export enum YesOrNo {
    No = "No",
    Yes = "Yes",
}

export enum tagFieldSuffixes {
    additionalFields = 'additionalFields',
    isMandatory = 'isMandatory',
    isMultiSelect = 'isMultiSelect',
}

export enum tagFieldRequiredOptions {
    Mandatory = 'Mandatory',
    Optional = 'Optional'
}

export enum tagFieldTypeOptions {
    MultiSelect = 'Multi-select',
    Select = 'Select'
}

export interface ICategoryWiseSetting {
    skillTags:                                      ISkillTag[];
    categoryId:                                     string;
    skillTypes:                                     ISkillType[];
    skillRatings:                                   ISkillRating[];
    ratingsManager:                                 string;
    lmsApproverName:                                string;
    skillRestriction:                               string;
    collectExperience:                              YesOrNo;
    skillSortCriteria:                              string;
    enablePrimarySkill:                             YesOrNo;
    skillSortDirection:                             string;
    approvalSkillTagIds:                            string[];
    approvalSkillTypeIds:                           string[];
    collectSkillLastUsed:                           YesOrNo;
    approvalConfiguration:                          string;
    approvalSkillTagIds_1:                          string[];
    approvalSkillTagIds_2:                          string[];
    hide2ndLevelHierarchy:                          YesOrNo;
    hide3rdLevelHierarchy:                          YesOrNo;
    approvalSkillTypeIds_1:                         string[];
    approvalSkillTypeIds_2:                         string[];
    skillRatingSuggestions:                         YesOrNo;
    approvalConfiguration_1:                        string;
    approvalConfiguration_2:                        string;
    approvalThresholdRating:                        number;
    autoPopulateSkillTagging:                       YesOrNo;
    approvalThresholdRating_1:                      number;
    approvalThresholdRating_2:                      number;
    levelWiseApprovalSettings:                      ILevelWiseApprovalSetting[];
    revertRejectedEndorsement:                      YesOrNo;
    allowSaveSkillsWOMandatory:                     YesOrNo;
    checkDuplicateResumeSkills:                     YesOrNo;
    showRequiredProficiencyToUsers:                 YesOrNo;
    autoPopulateSkillTaggingCriteria:               string[];
    allowSystemRatingToOverwriteUserApprovedRating: YesOrNo;
}

export interface ILevelWiseApprovalSetting {
    level:                number;
    approvalManager:      string;
    allowDelegations:     YesOrNo;
    allowApproverToSkip:  YesOrNo;
    allowApprovalRemarks: YesOrNo;
}

export interface ISkillRating {
    name:                     string;
    title:                    string;
    weight:                   number;
    dataKey:                  string;
    useRating:                YesOrNo;
    modifiedByClient?:        boolean;
    requiresApproval:         YesOrNo;
    disablerequiresApproval?: boolean;
}
export interface ISkillTag {
    name:             string;
    order:            number;
    isInvalid:        boolean;
    skillTagId:       string;
    autoPopulate:     YesOrNo;
    allowDeletion:    YesOrNo;
    modifiedByClient: boolean;
}
export interface ISkillType {
    name:             string;
    skillTypeId:      string;
    modifiedByClient: boolean;
}
export interface ICustomOption {
    customOptionId: string;
    name:           string;
    integration:    string;
    displayName:    string;
    order:          number;
    options:        any[];
}

export interface ICustomTag {
    field:                    string;
    dataKey:                  string;
    dataType:                 string;
    additionalFields:         any[];
    selectedAdditionalField?: string;
    selectedAdditionalFields: string[];
}
export interface IHrmsFieldsChosenForMaster {
    field:                    string;
    dataKey:                  string;
    selectedAdditionalFields: string[];
}

export interface IMapCategoriesToSkus {
    name:       string;
    order:      number;
    tblId?:     number;
    categoryId: string;
}

export interface IPractice {
    practiceId: string;
    name:       string;
}

export interface ISkillHierarchy {
    hierarchyId: string;
    name:        string;
    order:       number;
}

export interface DesignationsWeightLabels {
  key:   string;
  label: string;
}
