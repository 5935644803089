import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { DIALOG_TYPES, RESPONSE_STATUS, SMS_OPTIONS } from '../constants';
import { API_ENDPOINT } from '../constants/api-endpoint.constants';
import { map, take, tap } from 'rxjs/operators';
import { WaitErrorDialogsService } from './wait-error-dialogs.service';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { StaffingRoleModalComponent } from '../modules/base/staffing-role-modal/staffing-role-modal.component';

const STAFFING_ROLE = 'staffingRole';
const STAFFING_ROLE_MODAL_SHOWN = 'isStaffingRoleModalShown';
const TREATMENT_MAP_GET_MY_SKILLS_V3 = 'T2';

@Injectable({
  providedIn: 'root'
})
export class StaffingRoleService {

  public uploadedFileLoc: string = null;

  constructor(
    private ds: DataService,
    private weds: WaitErrorDialogsService, 
    private dialog: MatDialog,
  ) { }

  public showStaffingRoleModal() {
    this.fetchUserStaffingRoles().pipe(take(1)).subscribe((staffingRoles) => {
      if (this.isStaffingRoleEnabled && (!staffingRoles?.length || staffingRoles?.length > this.ds?.client?.smsSettings?.maximumStaffingRoles)) {
        this.openStaffingRoleModal();
        if (this.ds?.client?.smsSettings?.isStaffingRoleMandatory === SMS_OPTIONS.NO) localStorage.setItem(STAFFING_ROLE_MODAL_SHOWN, 'true');
      }
    });
  }

  public extractStaffingRolesFromResume() {
    const treatmentMap = JSON.parse(localStorage.getItem("treatmentMap"));
    const isExtractStaffingRoleWithAI = this.ds.client?.smsSettings?.extractStaffingRoleWithAI === SMS_OPTIONS.YES;
    
    if( treatmentMap?.getMySkills !== TREATMENT_MAP_GET_MY_SKILLS_V3 || !isExtractStaffingRoleWithAI) {
      return;
    }
    const payload = {
      clientId: this.ds.client?.clientId,
      userId: this.ds.user?.userId,
      loc: this.uploadedFileLoc,
    }
    this.ds.saveData(API_ENDPOINT.EXTRACT_STAFFING_ROLES_FROM_RESUME, payload).pipe(take(1)).subscribe();
    this.uploadedFileLoc = null;
  }

  public fetchUserStaffingRoles(): Observable<any[]> {
    const loader = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
    const payload = { userId: this.ds.user?.userId, tags: [STAFFING_ROLE] };
  
    return this.ds.saveData(API_ENDPOINT.GET_USER_CUSTOM_TAGS, payload).pipe(
      take(1),
      tap(() => this.weds.closeDialog(loader)),
      map((rs: any) => {
        if (rs.status === RESPONSE_STATUS.SUCCESS) {
          return rs.data?.[0]?.values?.map(tag => ({
            ...tag,
            _id: tag.id
          })) ?? [];
        } else {
          this.weds.showDialog({ type: DIALOG_TYPES.GENERIC, code: 99 });
        }
      })
    );
  }

  public get isStaffingRoleEnabled(): boolean {
    const { selectedUserCustomTags = [], fieldsForCustomTags = [] } = this.ds.client?.smsSettings || {};

    const isStaffingRoleInSelectedUserCustomTags = selectedUserCustomTags.includes('staffingRole');
    const isStaffingRoleInFieldsForCustomTags = fieldsForCustomTags.some(field => field?.dataKey === 'staffingRole');
    
    return isStaffingRoleInSelectedUserCustomTags && isStaffingRoleInFieldsForCustomTags;
  }

  private openStaffingRoleModal() {
    const dialogWidth = window.innerWidth < 900 ? '90vw' : '600px';
    const dialogHeight = window.innerWidth < 900 ? '90vh' : '640px';

    let dialogRef = this.dialog.open(StaffingRoleModalComponent, { width: dialogWidth, height: dialogHeight, panelClass: 'profile-modal', disableClose: true });
    dialogRef.componentInstance.data = {
      selectedStaffingRole: [],
      dontCloseProgrammatically: true,
    }
    const onEvent = dialogRef.componentInstance.onEvent.subscribe(() => {
      dialogRef.close();
    });
    dialogRef.afterClosed().subscribe(() => { onEvent.unsubscribe(); });
  }
  
}
