<div class="projects-settings-con">
    <div class="h3 black ">Profile & Resume Builder Settings</div>
    <form [formGroup]="editForm" (ngSubmit)="saveData()" (keydown.enter)="evaluateEnterKey($event)" class="admin-form">
        <div class="admin-form-con">
            <div *ngFor="let smsOption of smsOptions"
                [ngClass]="smsOption.type=='textarea'||!smsOption.key ? 'admin-input-con-full' : 'admin-input-con-half'">
                <div *ngIf="smsOption.type=='title'" class="h3 black " style="margin:10px 0;">
                    {{smsOption.title}}</div>
                <div *ngIf="smsOption.type=='sub-title'" class="h4 black" style="margin:10px 0;">
                    {{smsOption.title}}</div>
                <div *ngIf="smsOption.type=='text'">
                    <mat-form-field appearance="outline" class="admin-input-form-field">
                        <mat-label>{{smsOption.title}}</mat-label>
                        <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                        <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                            [required]="smsOption.required" autocomplete="off"
                            maxlength="{{ smsOption.maxLength ? smsOption.maxLength : '70' }}" />
                        <mat-error *ngIf="editForm.get(smsOption.key).invalid">{{smsOption.errorText||'This input is
                            mandatory'}}</mat-error>
                    </mat-form-field>
                </div>
                <div *ngIf="smsOption.type=='select'">
                    <mat-form-field appearance="outline" class="admin-input-form-field">
                        <mat-label>{{smsOption.title}}</mat-label>
                        <mat-icon matPrefix *ngIf="smsOption.icon">{{smsOption.icon}}</mat-icon>
                        <mat-select formControlName="{{smsOption.key}}" [required]="smsOption.required" [multiple]='smsOption.isMulti'>
                            <mat-option *ngFor="let option of smsOption.options"
                                [value]="option?.key || option">
                                {{option?.value || option}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="editForm.get(smsOption?.key)?.invalid">{{smsOption.errorText||'This input is
                            mandatory'}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="admin-save-button-con" *ngIf="canEdit">
            <button type="submit" mat-stroked-button class="primary-button">Save</button>
        </div>
    </form>
</div>