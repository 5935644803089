import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-badge-counter',
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" 
         [style.width]="size + 'px'" 
         [style.height]="size + 'px'"
         viewBox="0 0 42 46" 
         fill="none">
      <path
        d="M17.25 1.74241C19.5705 0.402668 22.4295 0.402668 24.75 1.74241L37.5346 9.12361C39.8551 10.4634 41.2846 12.9393 41.2846 15.6188V30.3812C41.2846 33.0607 39.8551 35.5366 37.5346 36.8764L24.75 44.2576C22.4295 45.5973 19.5705 45.5973 17.25 44.2576L4.46539 36.8764C2.14488 35.5366 0.715391 33.0607 0.715391 30.3812V15.6188C0.715391 12.9393 2.14488 10.4634 4.46539 9.12361L17.25 1.74241Z"
        fill="#F2F7FE" 
        stroke="#1F40CB" />
      <text x="50%" 
            y="52%" 
            text-anchor="middle" 
            dominant-baseline="middle" 
            class="fs-12 primary fw-500"
            fill="#1F40CB">
        +{{ count }}
      </text>
    </svg>
  `,
})
export class BadgeCounterComponent {
  @Input() count: number = 0;
  @Input() size: number = 42;
}