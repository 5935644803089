<div class="d-flex flex-column gap-2">
    <div class="h3 black">User Custom Tags Settings</div>

    <form [formGroup]="userCustomTagsSettingsFormGroup">
        <main class="admin-form-con">
            <section class="admin-input-con-half">
                <app-data-ordering-control formControlName="userCustomTag" [input]="userCustomTagsConfig" (onSave)="saveUserCustomTags()">
                </app-data-ordering-control>
            </section>
        </main>
    </form>
</div>