import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IFilter, ILearningElementCardData, TREATMENT_MAP } from '../../my-learnings/my-learning.model';
import { FormControl, FormGroup } from '@angular/forms';
import { MyLearningService } from '../../my-learnings/my-learning.service';
import { progressCardGroup } from './progress-card-group.model';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-progress-card-group',
  templateUrl: './progress-card-group.component.html',
  styleUrls: ['./progress-card-group.component.scss'],
})
export class ProgressCardGroupComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() config: progressCardGroup[];
  @Input() headerConfig;
  @ViewChild('cardHolder') cardHolder: ElementRef;

  public isExpanded: boolean = false;
  public isLoading: boolean = true;
  public numberOfCardsToShow: number = 4;
  public selectedTabIndex: number = 0;
  public searchFilters: FormGroup = new FormGroup({});
  public myLearningTreatment: string;
  public TREATMENT_MAP = TREATMENT_MAP;

  private previousPage: number = null;
  private nextPage: number = 1;
  private limit: number = 20;

  private destroy = new Subject<IFilter[]>();

  constructor(
    private router: Router,
    private ds: DataService,
    private weds: WaitErrorDialogsService,
    private cdr: ChangeDetectorRef,
    private myLearningService: MyLearningService,
    private util: UtilitiesService
  ) { }

  get totalCount() {
    return this.config.reduce((acc, item) => acc + (item.totalDataCount || 0), 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.config?.currentValue) {
      this.myLearningTreatment = this.util.getTreatmentMap(this.TREATMENT_MAP.MY_LEARNINGS);
      this.config = this.myLearningTreatment === this.TREATMENT_MAP.V1 ? [{...changes.config.currentValue[0], allowAdditionalFilters: false}] : changes.config.currentValue;
      this.config?.forEach((group: progressCardGroup) => {
        if (!changes.firstChange && group.getDataFromParent) {
          this.setComponent(group);
        } else {
          if (group.fetchData) {
            this.initFilterSubscription(group);
            this.getFilters(group);
            this.getData(group);
          }
        }
      });
    }
  }

  ngAfterViewInit(): void {
    const selectedTab = this.config[this.selectedTabIndex];
    this.setComponent(selectedTab);
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  public onTabChange() {
    const group = this.config[this.selectedTabIndex];
    if (!group.cards?.length && !group.getDataFromParent) {
      this.getData(group);
      return;
    } 
    this.processData(group);
  }

  public toggleContainerExpansion() {
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded) {
      this.config[this.selectedTabIndex].cardsShown = [...this.config[this.selectedTabIndex].cards];
    } else {
      this.processData(this.config[this.selectedTabIndex]);
    }
  }

  public redirectToViewAll() {
    this.router.navigate([this.router.url + '/viewAll'], {
      queryParams: {
        ...this.config[this.selectedTabIndex],
        headerConfig: JSON.stringify({...this.headerConfig, groupName: this.config[this.selectedTabIndex].groupName}),
        hideViewMoreBtn: true,
        getDataFromParent: true,
        allowAdditionalFilters: false,
        additionalFilters: [],
        filters: JSON.stringify(this.config[this.selectedTabIndex]?.filters || {}),
        showGrayBG: true,
      } 
    });
  }

  public onFilterApply() {
    const selectedTab = this.config[this.selectedTabIndex];
    this.getData(selectedTab);
  }

  private initFilterSubscription(group: progressCardGroup) {
    group?.fetchData
      ?.pipe(
        switchMap((filters: IFilter[]) => {
          this.setSkeletonLoaders(group);
          const url = `${group.getDataApi}?page=${this.nextPage||1}&limit=${this.limit}`;
          return this.ds[group.getDataApiMethod](url, { filters });
        }),
        takeUntil(this.destroy)
      )
      .subscribe(
        (res: ILearningElementCardData) => {
          this.previousPage = res.previous;
          this.nextPage = res.next;
          group.cards = group.responseFormat ? res?.data?.[0]?.data : res?.data;
          this.processData(group);
          group.totalDataCount = res.count;
        },
        (err) => {
          this.weds.showDialog({
            type: 'generic',
            code: 0,
        });
    });
  }

  private setComponent(group: progressCardGroup) {
    this.getFilters(group);
    this.setNumberOfCardsToShow(group);
    if (group.getDataFromParent) {
      this.onTabChange();
    }
  }

  private getData(group: progressCardGroup) {
    const additionalFilter = this.getDataWithFilter();
    const filters = [
      ...(group.filters || []),
      ...(group?.allowAdditionalFilters ? additionalFilter : [])
    ];
    group.cards = [];
    group.cardsShown = [];
    this.setSkeletonLoaders(group);
    group?.fetchData?.next(filters);
  }

  private processData(group: progressCardGroup) {
    group.cardsShown = group.cards?.length ? [...group.cards] : [];
    
    if (!group.hideViewMoreBtn) {
      group.cardsShown = group.cardsShown.slice(0, this.numberOfCardsToShow);
    }
    this.isLoading = false;
  }

  private setNumberOfCardsToShow(group: progressCardGroup) {
    const cardCountInOneRow = Math.floor((this.cardHolder?.nativeElement?.clientWidth + 16) / 289) || 4;
    this.numberOfCardsToShow = group.hideViewMoreBtn ? cardCountInOneRow * 3 : cardCountInOneRow;
  }

  private setSkeletonLoaders(group: progressCardGroup) {
    if (!group.cardsShown?.[0]?.pk) {
      group.cardsShown = new Array(this.numberOfCardsToShow).fill(0);
    }
  }

  private getFilters(group: progressCardGroup) {
    if (!group.allowAdditionalFilters) return;
    const fields = group.additionalFilters?.map(filter => filter.key);
    const payload = { fields };
    const filters = group.additionalFilters;
    
    this.myLearningService.getConstantFiltersValue(payload, filters).pipe(take(1)).subscribe((filters: IFilter[]) => {
     group.additionalFilters = filters;
      this.buildFilter(group);
    });
    
  }

  private buildFilter(group: progressCardGroup) {
    group.additionalFilters?.forEach(filter => {
      group.appliedFilterControl.addControl(filter.key, new FormControl([]));
    });
  }

  private getDataWithFilter() {
    const filterValue = this.config[this.selectedTabIndex]?.appliedFilterControl?.value;
    const filters = [];
    for(let key in filterValue) {
      filters.push({key: key.toLowerCase(), value: filterValue[key]});
    }
    return filters;
  }
}
