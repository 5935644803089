<div class="skill-taxonomy-con">
    <div class="h3 black ">Skill Taxonomy Settings</div>
    <form [formGroup]="editForm" (ngSubmit)="saveData()" (keydown.enter)="evaluateEnterKey($event)" class="admin-form">
        <div class="admin-form-con">
            <div *ngFor="let smsOption of smsOptions"
                [ngClass]="smsOption.type=='textarea' ? 'admin-input-con-full' : 'admin-input-con-half'"
                [ngStyle]="{'display':smsOption.showControl&&renderSkillProficiencyRelationNode(smsOption.key)?'block':'none'}">
                <div *ngIf="smsOption.showControl">
                    <div *ngIf="smsOption.type=='text'">
                        <mat-form-field appearance="outline" class="admin-input-form-field">
                            <mat-label>{{smsOption.title}}</mat-label>
                            <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                            <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                                [required]="smsOption.required" autocomplete="off"
                                maxlength="{{ smsOption.maxLength ? smsOption.maxLength : '70' }}" />
                            <mat-error *ngIf="editForm.get(smsOption.key).invalid">{{smsOption.errorText||'This input is
                                mandatory'}}</mat-error>
                        </mat-form-field>
                    </div>

                    <div *ngIf="smsOption.type=='textarea'">
                        <mat-form-field appearance="outline" class="admin-input-form-field admin-text-area-field">
                            <mat-label>{{smsOption.title}}</mat-label>
                            <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                            <textarea matInput [placeholder]="smsOption.title" formControlName="{{smsOption.key}}"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="20" class="config-tooltip h5 black"></textarea>
                        </mat-form-field>

                    </div>
                    <div *ngIf="smsOption.type=='number'">
                        <mat-form-field appearance="outline" class="admin-input-form-field">
                            <mat-label>{{smsOption.title}}</mat-label>
                            <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                            <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                                [required]="smsOption.required" autocomplete="off" [type]="smsOption.type"
                                [min]="smsOption.min||0" />
                            <mat-error *ngIf="editForm.get(smsOption.key).invalid">{{smsOption.errorText||'This input is
                                mandatory'}}</mat-error>
                        </mat-form-field>
                    </div>

                    <div *ngIf="smsOption.type=='select'">
                        <mat-form-field appearance="outline" class="admin-input-form-field">
                            <mat-label>{{smsOption.title}}</mat-label>
                            <mat-icon matPrefix *ngIf="smsOption.icon">{{smsOption.icon}}</mat-icon>
                            <mat-select formControlName="{{smsOption.key}}" [required]="smsOption.required"
                                (selectionChange)="onSMSOptionSelected(smsOption, $event)"
                                [multiple]="smsOption.isMulti" [compareWith]="compareSelect">
                                <mat-option *ngFor="let option of smsOption.options"
                                    [value]="option.skillTagId || option.skillTypeId ? option.skillTagId || option.skillTypeId : option">
                                    {{smsOption.displayKey?option[smsOption.displayKey]:option}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="editForm.get(smsOption.key).invalid">{{smsOption.errorText||'This input is
                                mandatory'}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div *ngIf="smsOption.type=='date'">
                        <mat-form-field appearance="outline" class="admin-input-form-field">
                            <mat-label>{{smsOption.title}}</mat-label>
                            <mat-icon matPrefix>date_range</mat-icon>
                            <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                                [matDatepicker]="picker" [required]="smsOption.required" autocomplete="off"
                                (focus)="picker.open()" />
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="editForm.get(smsOption.key).invalid">{{smsOption.errorText||'This input is
                                mandatory'}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="admin-save-button-con" *ngIf="canEdit">
            <button type="submit" mat-stroked-button class="primary-button">Save</button>
        </div>
    </form>
    <div class="d-flex mt-4 flex-row">
        <div *ngIf="showSkillMasterTags" class="d-flex flex-half flex-column gap-3 pt-3">
            <div *ngIf="showHrmsTags || showCustomTags" class="h3 black ">Skill Master Tags</div>
            <form [formGroup]="skillMasterTagsSettingsFormGroup">
                <main class="admin-form-con">
                    <section *ngIf="showHrmsTags" class="admin-input-con-half">
                        <app-data-ordering-control formControlName="hrmsTags" [input]="hrmsTagsConfig"
                            (onSave)="saveSkillMasterTags('hrmsTags', 'hrmsFieldsChosenForMaster', 'skillMasterHrmsTags')"></app-data-ordering-control>
                    </section>
                    <section *ngIf="showCustomTags" class="admin-input-con-half">
                        <app-data-ordering-control formControlName="customTags" [input]="customTagsConfig"
                            (onSave)="saveSkillMasterTags('customTags', 'fieldsForCustomTags', 'skillMasterCustomTags')"></app-data-ordering-control>
                    </section>
                </main>
            </form>
        </div>
    </div>
</div>