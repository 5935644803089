<div class="d-flex flex-column gap-2">
    <div class="h3 black">Staffing Roles Settings</div>
    <div class="mb-2">Add the staffing role master in <strong> Custom Tags > Staffing roles </strong> to complete the setup.</div>
    <form [formGroup]="staffingRoleForm">
        <div class="d-flex justify-content-between flex-wrap">
            <mat-form-field appearance="outline" class="input-form-field admin-input-con-half">
                <mat-label>Is Staffing Role Mandatory</mat-label>
                <mat-icon matPrefix>settings_suggest</mat-icon>
                <mat-select formControlName="isStaffingRoleMandatory">
                    <mat-option value="Yes">Yes</mat-option>
                    <mat-option value="No">No</mat-option>
                </mat-select>
            </mat-form-field>
        
            <mat-form-field appearance="outline" class="input-form-field admin-input-con-half">
                <mat-label>Maximum Staffing Role Limit</mat-label>
                <mat-icon matPrefix>settings_suggest</mat-icon>
                <input matInput type="number" formControlName="maxStaffingRoleLimit" min="1" max="{{maxStaffingRoleMasterCount}}" (keypress)="utils.preventCharacters($event, ['.'])">
                <mat-error *ngIf="staffingRoleForm.get('maxStaffingRoleLimit').value > maxStaffingRoleMasterCount"> Maximum staffing role limit exceeds available options on staffing role master.</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="input-form-field admin-input-con-half">
                <mat-label>Extract Staffing role with AI</mat-label>
                <mat-icon matPrefix>settings_suggest</mat-icon>
                <mat-select formControlName="extractStaffingRoleWithAI">
                    <mat-option value="Yes">Yes</mat-option>
                    <mat-option value="No">No</mat-option>
                </mat-select>
                <mat-hint>Caution: This feature has cost implications. Connect with product team before enabling.</mat-hint>
            </mat-form-field>
        </div>

        <div class="admin-save-button-con">
            <button type="submit" (click)="saveData()" mat-stroked-button class="primary-button">Save</button>
        </div>
    </form>
</div>
  