import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Client } from 'src/app/models/client.model';
import { ISettingsOption } from './settings.model';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit {
  private _client: Client;
  @Input() canEdit: boolean = false;
  @Input() settingsOptionTitle: string = 'Settings';
  @Input() settingsOptions: ISettingsOption[] = [];
  @Output() onSave = new EventEmitter();
  editForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.editForm = fb.group({});
  }

  ngOnInit(): void {
    this.settingsOptions.forEach((option) => {
      this.editForm.addControl(option.key, new FormControl('', option.validators));
    });
  }

  @Input()
  set client(client: Client) {
    this._client = client;
    setTimeout(() => {
      this.buildForms();
    }, 0);
  }

  get client() {
    return this._client;
  }

  buildForms() {
    this.settingsOptions.forEach((option) => {
      let currentValue = this.editForm.get(option.key)?.value || this.client.smsSettings[option.key] || option.defaultValue || option.options[1];
      option.showControl = true;
      this.editForm.get(option.key)?.setValue(currentValue);

      (option.checkValue || []).forEach((item) => {
        if (item.values.indexOf(this.editForm.get(item.key)?.value ?? this.client.smsSettings[item.key]) < 0) {
          option.showControl = false;
        }
      });
    });
  }

  saveData() {
    if (this.editForm.invalid) return;
    const data = { ...this.editForm.value, type: this.settingsOptions[0].settingsType };
    this.onSave.emit(data);
  }

  evaluateEnterKey(event: KeyboardEvent) {
    if ((event.target as HTMLInputElement).className.includes('textarea')) return;
    event.preventDefault();
  }

  compareSelect(o1: any, o2: any) {
    const keys = ['id', 'categoryId'];
    return keys.some(key => o1?.[key] === o2?.[key]) || o1 === o2;
  }
}
