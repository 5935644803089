<div class="skill-taxonomy-con">
    <div class="h3 black">Skill Management</div>
    <div class="h4 mt-4 black">General Settings</div>
    <form [formGroup]="editForm" (ngSubmit)="saveData()" (keydown.enter)="evaluateEnterKey($event)" class="admin-form">
        <div class="admin-form-con">
            <div *ngFor="let smsOption of smsOptions"
                [ngClass]="smsOption.type=='textarea' ? 'admin-input-con-full' : 'admin-input-con-half'"
                [ngStyle]="{'display':smsOption.showControl&&renderSkillProficiencyRelationNode(smsOption.key)?'block':'none'}">
                <div *ngIf="smsOption.showControl">
                    <div *ngIf="smsOption.type=='text'">
                        <mat-form-field appearance="outline" class="admin-input-form-field">
                            <mat-label>{{smsOption.title}}</mat-label>
                            <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                            <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                                [required]="smsOption.required" autocomplete="off"
                                maxlength="{{ smsOption.maxLength ? smsOption.maxLength : '70' }}" />
                            <mat-error *ngIf="editForm.get(smsOption.key).invalid">{{smsOption.errorText||'This input is
                                mandatory'}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div *ngIf="smsOption.type=='textarea'">
                        <mat-form-field appearance="outline" class="admin-input-form-field admin-text-area-field">
                            <mat-label>{{smsOption.title}}</mat-label>
                            <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                            <textarea matInput [placeholder]="smsOption.title" formControlName="{{smsOption.key}}"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="20" class="config-tooltip h5 black"></textarea>
                        </mat-form-field>
                    </div>
                    <div *ngIf="smsOption.type=='number'">
                        <mat-form-field appearance="outline" class="admin-input-form-field">
                            <mat-label>{{smsOption.title}}</mat-label>
                            <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                            <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                                [required]="smsOption.required" autocomplete="off" [type]="smsOption.type"
                                [min]="smsOption.min||0" />
                            <mat-error *ngIf="editForm.get(smsOption.key).invalid">{{smsOption.errorText||'This input is
                                mandatory'}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div *ngIf="smsOption.type=='select'">
                        <mat-form-field appearance="outline" class="admin-input-form-field">
                            <mat-label>{{smsOption.title}}</mat-label>
                            <mat-icon matPrefix *ngIf="smsOption.icon">{{smsOption.icon}}</mat-icon>
                            <mat-select formControlName="{{smsOption.key}}" [required]="smsOption.required"
                                (selectionChange)="onSMSOptionSelected(smsOption, $event)"
                                [multiple]="smsOption.isMulti" [compareWith]="compareSelect">
                                <mat-option *ngFor="let option of smsOption.options"
                                    [value]="option.skillTagId || option.skillTypeId ? option.skillTagId || option.skillTypeId : option">
                                    {{smsOption.displayKey?option[smsOption.displayKey]:option}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="editForm.get(smsOption.key).invalid">{{smsOption.errorText||'This input is
                                mandatory'}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div *ngIf="smsOption.type=='date'">
                        <mat-form-field appearance="outline" class="admin-input-form-field">
                            <mat-label>{{smsOption.title}}</mat-label>
                            <mat-icon matPrefix>date_range</mat-icon>
                            <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                                [matDatepicker]="picker" [required]="smsOption.required" autocomplete="off"
                                (focus)="picker.open()" />
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="editForm.get(smsOption.key).invalid">{{smsOption.errorText||'This input is
                                mandatory'}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="admin-save-button-con" *ngIf="canEdit">
            <button type="submit" mat-stroked-button class="primary-button">Save</button>
        </div>
    </form>
    <div class="d-flex mt-4 flex-column">
        <div class="h4 black">My Skills Summary</div>
        <form class="admin-form" [formGroup]="mySkillsSummaryFormGroup" (ngSubmit)="saveMySkillsSummaryData()" (keydown.enter)="evaluateEnterKey($event)">
            <div class="admin-form-con">
                <div *ngFor="let option of mySkillsSummaryOptions" class="admin-input-con-full">
                    <div *ngIf="option.type === 'select'">
                        <mat-form-field appearance="outline" class="admin-input-form-field">
                            <mat-label>{{option.title}}</mat-label>
                            <mat-icon matPrefix *ngIf="option.icon">{{option.icon}}</mat-icon>
                            <mat-select formControlName="{{option.key}}" [required]="option.required"
                                [compareWith]="compareSelect">
                                <mat-option *ngFor="let option of option.options" [value]="option">
                                    {{option}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="mySkillsSummaryFormGroup.get(option.key).invalid">
                                {{option.errorText ||'This input is mandatory'}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div *ngIf="option.type=='textarea'">
                        <mat-form-field appearance="outline" class="admin-input-form-field admin-text-area-field">
                            <mat-label>{{option.title}}</mat-label>
                            <mat-icon matPrefix>{{option.icon}}</mat-icon>
                            <textarea matInput [placeholder]="option.title" formControlName="{{option.key}}"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="20" class="config-tooltip h5 black"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="d-flex flex-grow-1 justify-content-center" *ngIf="canEdit">
                    <button type="submit" mat-stroked-button class="primary-button">Save</button>
                </div>
            </div>          
        </form>
    </div>
    <div *ngIf="showSkillAdditionRequests" class="d-flex mt-4 flex-column">
        <div class="d-flex flex-column flex-half gap-3 pt-2 pb-4 pl-3">
            <div class="h4 black">Skill Addition Requests</div>
            <form class="admin-form" [formGroup]="skillRequestOptionsFormGroup" (ngSubmit)="saveSkillRequestData()" (keydown.enter)="evaluateEnterKey($event)">
                <div class="admin-form-con flex-column">
                    <div *ngFor="let option of skillRequestOptions" class="admin-input-con-half">
                        <div *ngIf="option.type === 'select'">
                            <mat-form-field appearance="outline" class="admin-input-form-field">
                                <mat-label>{{option.title}}</mat-label>
                                <mat-icon matPrefix *ngIf="option.icon">{{option.icon}}</mat-icon>
                                <mat-select formControlName="{{option.key}}" [required]="option.required"
                                    [compareWith]="compareSelect">
                                    <mat-option *ngFor="let option of option.options" [value]="option">
                                        {{option}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="skillRequestOptionsFormGroup.get(option.key).invalid">
                                    {{option.errorText ||'This input is mandatory'}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="d-flex flex-grow-1 justify-content-center" *ngIf="canEdit">
                        <button type="submit" mat-stroked-button class="primary-button">Save</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <app-tooltip-settings [levels]="editForm.get('skillRating').value" [canEdit]="canEdit" [client]="client" (onSave)="saveTooltipData($event)"></app-tooltip-settings>
</div>