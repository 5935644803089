<div class="skill-ratings-con">
    <app-skill-ratings-settings [canEdit]="canEdit" [category]="category" [client]="client"
        (onSave)="onSaveDataEvent($event)"></app-skill-ratings-settings>
</div>
<div class="types-tags-con" *ngIf="client">
    <app-skill-types-settings class="types-tags" [canEdit]="canEdit" [category]="category" [client]="client"
        (onSave)="onSaveDataEvent($event)">
    </app-skill-types-settings>
    <app-skill-tags-settings class="types-tags" [canEdit]="canEdit" [category]="category" [client]="client"
        (onSave)="onSaveDataEvent($event)">
    </app-skill-tags-settings>
</div>
<div style="width:100%" class="category-wise-settings-con">
    <form [formGroup]="editForm" (ngSubmit)="saveData()" (keydown.enter)="evaluateEnterKey($event)" class="admin-form">
        <div class="admin-form-con">
            <div *ngFor="let smsOption of smsOptions"
                [ngClass]="smsOption.type=='textarea'||!smsOption.key ? 'admin-input-con-full' : 'admin-input-con-half'"
                [ngStyle]="{'display':smsOption.showControl?'block':'none'}">
                <div *ngIf="smsOption.type=='title'" class="h3 black " style="margin:10px 0;">
                    {{smsOption.title}}</div>
                <div *ngIf="smsOption.type=='sub-title'" class="h4 black" style="margin:10px 0;">
                    {{smsOption.title}}</div>
                <div *ngIf="smsOption.showControl">
                    <div *ngIf="smsOption.type=='text'">
                        <mat-form-field appearance="outline" class="admin-input-form-field">
                            <mat-label>{{smsOption.title}}</mat-label>
                            <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                            <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                                [required]="smsOption.required" autocomplete="off"
                                maxlength="{{ smsOption.maxLength ? smsOption.maxLength : '70' }}" />
                            <mat-error *ngIf="editForm.get(smsOption.key).invalid">{{smsOption.errorText||'This input is
                                mandatory'}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div *ngIf="smsOption.type=='textarea'">
                        <mat-form-field appearance="outline"
                            class="admin-input-form-field admin-text-area-field">
                            <mat-label>{{smsOption.title}}</mat-label>
                            <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                            <textarea matInput [placeholder]="smsOption.title" formControlName="{{smsOption.key}}" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="20"
                                class="config-tooltip h5 black"></textarea>
                        </mat-form-field>
                    </div>
                    <div *ngIf="smsOption.type=='select'">
                        <mat-form-field appearance="outline" class="admin-input-form-field">
                            <mat-label>{{smsOption.title}}</mat-label>
                            <mat-icon matPrefix *ngIf="smsOption.icon">{{smsOption.icon}}</mat-icon>
                            <mat-select [matTooltip]="smsOption.tooltip" formControlName="{{smsOption.key}}" [required]="smsOption.required"
                                (selectionChange)="onSMSOptionSelected(smsOption, $event)" [multiple]="smsOption.isMulti"
                                [compareWith]="compareSelect">
                                <mat-option *ngFor="let option of smsOption.options"
                                    [value]="option.skillTagId || option.skillTypeId ? option.skillTagId || option.skillTypeId : option">
                                    {{smsOption.displayKey?option[smsOption.displayKey]:option}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="editForm.get(smsOption.key).invalid">{{smsOption.errorText||'This input is
                                mandatory'}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div *ngIf="smsOption.type=='date'">
                        <mat-form-field appearance="outline" class="admin-input-form-field">
                            <mat-label>{{smsOption.title}}</mat-label>
                            <mat-icon matPrefix>date_range</mat-icon>
                            <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                                [matDatepicker]="picker" [required]="smsOption.required" autocomplete="off"
                                (focus)="picker.open()" />
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="editForm.get(smsOption.key).invalid">{{smsOption.errorText||'This input is
                                mandatory'}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>

            </div>
        </div>
        <div class="admin-save-button-con" *ngIf="canEdit">
          <button type="submit" mat-stroked-button class="primary-button">Save</button>
      </div>
        <div *ngIf="allowMultilevelApprover">
          <div class="multi-level-endorsement-title-con">
            <div class="h4 black">Multilevel Endorsements</div>
            <button type="button" mat-stroked-button class="secondary-button" (click)="syncEndorsement()">{{syncButtonText}}</button>
          </div>
          <div *ngIf="client" class="multi-level-endorsement-table">
            <app-editable-table [endorseLevelSettings]="true" #multilevelEndorsements [canEdit]="canEdit" [enableDelete]="true" [columns]="approvalLevelColumns" [maxRows]="maxSkillApprovalLevelRow"  [footNotes]="'You need a minimum of 1 approver for endorsements'" [saveButtonText]="'Save'" (onSave)="onMultilevelEndorsementsSave($event)" [addButtonText]="'Add'" [dataSource]="this.settings.levelWiseApprovalSettings"  [enableDrag]="false"></app-editable-table>
          </div>
        </div>
        <div class="admin-save-button-con" *ngIf="canEdit">
            <button type="button" mat-stroked-button class="tertiary-button"
                (click)="applySameSettingsToAllCategories()">Apply same settings to all categories</button>
        </div>
    </form>
</div>
