<!-- Skeleton -->
<div *ngIf="!badgesLoaded" class="badges-con">
    <div skeletonNew [isLoading]="!badgesLoaded" skeletonWidth="300px" skeletonHeight="24px"
        skeletonBorderRadius="16px">
    </div>
    <div skeletonNew [isLoading]="!badgesLoaded" skeletonWidth="300px" skeletonHeight="45px"
        skeletonBorderRadius="16px">
    </div>
    <div skeletonNew [isLoading]="!badgesLoaded" skeletonWidth="300px" skeletonHeight="18px"
        skeletonBorderRadius="16px">
    </div>
</div>

<div *ngIf="badgesLoaded" 
    [ngClass]="{'badges-con': !hasEnclosingDiv, 'badges-con-enclosed': hasEnclosingDiv}">
    <div class="h4 black-text">{{ badgesText }}</div>
    <div class="badges-images-component d-flex flex-column w-100">
        <ng-container [ngTemplateOutlet]="badges?.length === 0 ? lockedBadges : 
                                badges?.length <= 5 ? badgesList : 
                                manyBadges">
        </ng-container>
    </div>
    <button 
        matTooltip="View All Badges" 
        mat-stroked-button
        class="tertiary-button assign-spec-button h6 primary fw-500 p-0 text-left w-fit-content" 
        (click)="navigateToBadges()">
        View All Badges
        <mat-icon>arrow_right_alt</mat-icon>
    </button>
</div>

<ng-template #lockedBadges>
    <div #badgesContainer>
        <app-svg-icon *ngFor="let i of lockedBadgesArray" class="locked-badge-icon" src="icons/locked-badge.svg"
            height={{badgeSize}} width={{badgeSize}}>
        </app-svg-icon>
    </div>
    <div class="fs-12 gray">You have not unlocked any badges yet</div>
</ng-template>

<ng-template #badgesList>
    <div #badgesContainer class="d-flex flex-wrap">
        <img *ngFor="let badge of badges; let i = index; trackBy: trackByBadgeId"
            [src]="badge.badgeUrl ? badge.badgeUrl : placeholderBadgeImage(i + 1)" [alt]="badge.name"
            [style.height]="badgeSize + 'px'" [style.width]="badgeSize + 'px'" />
    </div>
</ng-template>

<ng-template #manyBadges>
    <div #badgesContainer class="d-flex flex-wrap">
        <img *ngFor="let badge of badges.slice(0,4); let i = index; trackBy: trackByBadgeId"
            [src]="badge.badgeUrl ? badge.badgeUrl : placeholderBadgeImage(i + 1)" [alt]="badge.name"
            [style.height]="badgeSize + 'px'" [style.width]="badgeSize + 'px'" />
        <app-badge-counter [count]="totalCount - 4" [size]="badgeSize">
        </app-badge-counter>
    </div>
</ng-template>