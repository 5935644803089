<div class="recommendations-con">
    <div class="h3 black ">Skill Recommendations Settings</div>
    <form [formGroup]="recommendationsForm" (ngSubmit)="saveRecommendations()" (keydown.enter)="$event.preventDefault()"
        class="admin-form">
        <div class="admin-form-con">
            <div *ngFor="let recommendation of recommendationOptions"
                [ngClass]="recommendation.type=='textarea' ? 'admin-input-con-full' : 'admin-input-con-half'"
                [ngStyle]="{'display':recommendation.showControl?'block':'none'}">
                <div *ngIf="recommendation.type=='select'&&recommendation.showControl">
                    <mat-form-field appearance="outline" class="admin-input-form-field">
                        <mat-label>{{recommendation.title}}</mat-label>
                        <mat-icon matPrefix *ngIf="recommendation.icon">{{recommendation.icon}}</mat-icon>
                        <mat-select formControlName="{{recommendation.key}}" [required]="recommendation.required"
                            (selectionChange)="onRecommendationOptionSelected(recommendation, $event)"
                            [multiple]="recommendation.isMulti" [compareWith]="compareSelect">
                            <mat-option *ngFor="let option of recommendation.options"
                                [value]="option.skillTagId || option.skillTypeId ? option.skillTagId || option.skillTypeId : option">
                                {{recommendation.displayKey?option[recommendation.displayKey]:option}}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="recommendationsForm.get(recommendation.key).invalid">{{recommendation.errorText||'This
                            input is
                            mandatory'}}</mat-error>
                    </mat-form-field>
                </div>
                <div *ngIf="recommendation.type=='textarea'">
                    <mat-form-field *ngIf="recommendation.type=='textarea'&&recommendation.showControl" appearance="outline"
                        class="admin-input-form-field admin-text-area-field">
                        <mat-label>{{recommendation.title}}</mat-label>
                        <mat-icon matPrefix>{{recommendation.icon}}</mat-icon>
                        <textarea matInput [placeholder]="recommendation.title" formControlName="{{recommendation.key}}"
                            cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="20" class="config-tooltip h5 black"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="admin-save-button-con" *ngIf="canEdit">
            <button type="submit" mat-stroked-button class="primary-button">Save</button>
        </div>
    </form>
</div>