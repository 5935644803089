<div class="p2p-settings-con">
  <div class="h3 black">{{ settingsOptionTitle }}</div>
  <form [formGroup]="editForm" (ngSubmit)="saveData()" (keydown.enter)="evaluateEnterKey($event)" class="admin-form">
    <div class="admin-form-con">
      <div *ngFor="let option of settingsOptions" [ngClass]="option.type === 'textarea' ? 'admin-input-con-full' : 'admin-input-con-half'"
        [ngStyle]="{'display': option.showControl ? 'block' : 'none'}">
        <div *ngIf="option.showControl">
          <div *ngIf="option.type === 'select'">
            <mat-form-field appearance="outline" class="admin-input-form-field">
              <mat-label>{{ option.title }}</mat-label>
              <mat-icon matPrefix *ngIf="option.icon">{{ option.icon }}</mat-icon>
              <mat-select formControlName="{{ option.key }}">
                <mat-option *ngFor="let item of option.options" [value]="item">
                  {{ option.displayKey ? item[option.displayKey] : item }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="editForm.get(option.key).touched && editForm.get(option.key).invalid">
                {{ option.errorText || 'This input is mandatory' }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="admin-save-button-con" *ngIf="canEdit">
      <button type="submit" mat-stroked-button class="primary-button">Save</button>
    </div>
  </form>
  <div class="dynamic-content">
    <ng-content></ng-content>
  </div>
</div>
