<div class="demand-unit-settings-con" *ngIf="client">
    <div class="h3 black ">{{(client?.features?.admin?.['demand-units']||'No Access')=='No Access'? 
            'Skill Cluster Settings': 'Demand Unit & Skill Cluster Settings'}}</div>
    <form [formGroup]="editForm" (ngSubmit)="saveData()" (keydown.enter)="evaluateEnterKey($event)" class="admin-form">
        <div class="d-flex flex-column gap-3">
            <div class="admin-form-con">
                <div *ngFor="let smsOption of smsOptions"
                    [ngClass]="smsOption.type=='textarea'||!smsOption.key ? 'admin-input-con-full' : 'admin-input-con-half'"
                    [ngStyle]="{'display':smsOption.showControl?'block':'none'}">
                    <div *ngIf="smsOption.type=='title'" class="h3 black " style="margin:10px 0;">
                        {{smsOption.title}}</div>
                    <div *ngIf="smsOption.type=='sub-title'" class="h4 black" style="margin:10px 0;">
                        {{smsOption.title}}</div>
                    <div *ngIf="(smsOption.type=='text'||smsOption.type=='number')&&smsOption.showControl">
                        <mat-form-field appearance="outline" class="admin-input-form-field">
                            <mat-label>{{smsOption.title}}</mat-label>
                            <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                            <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                                [required]="smsOption.required" autocomplete="off" [type]="smsOption.type"
                                [min]="smsOption.min||0" [max]="smsOption.max||100"
                                maxlength="{{ smsOption.maxLength ? smsOption.maxLength : '70' }}" />
                            <mat-error *ngIf="editForm.get(smsOption.key).touched&&editForm.get(smsOption.key).invalid">
                                {{editForm.get(smsOption.key).errors.error||smsOption.errorText}}</mat-error>
                        </mat-form-field>
                    </div>
    
                    <mat-form-field *ngIf="smsOption.type=='textarea'&&smsOption.showControl" appearance="outline"
                        class="admin-input-form-field admin-text-area-field">
                        <mat-label>{{smsOption.title}}</mat-label>
                        <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                        <textarea matInput [placeholder]="smsOption.title" formControlName="{{smsOption.key}}"
                            cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="20" class="config-tooltip h5 black"></textarea>
                    </mat-form-field>
    
                    <div *ngIf="smsOption.type=='select'&&smsOption.showControl">
                        <mat-form-field appearance="outline" class="admin-input-form-field">
                            <mat-label>{{smsOption.title}}</mat-label>
                            <mat-icon matPrefix *ngIf="smsOption.icon">{{smsOption.icon}}</mat-icon>
                            <mat-select formControlName="{{smsOption.key}}" [required]="smsOption.required"
                                (selectionChange)="onSMSOptionSelected(smsOption, $event)" [multiple]="smsOption.isMulti"
                                [compareWith]="compareSelect">
                                <mat-option *ngFor="let option of smsOption.options"
                                    [value]="option.skillTagId || option.skillTypeId ? option.skillTagId || option.skillTypeId : option">
                                    {{smsOption.displayKey?option[smsOption.displayKey]:option}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="editForm.get(smsOption.key).invalid">{{smsOption.errorText||'This input is
                                mandatory'}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div *ngIf="smsOption.type=='date'&&smsOption.showControl">
                        <mat-form-field appearance="outline" class="admin-input-form-field">
                            <mat-label>{{smsOption.title}}</mat-label>
                            <mat-icon matPrefix>date_range</mat-icon>
                            <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                                [matDatepicker]="picker" [required]="smsOption.required" autocomplete="off"
                                (focus)="picker.open()" />
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="editForm.get(smsOption.key).invalid">{{smsOption.errorText||'This input is
                                mandatory'}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="admin-save-button-con" *ngIf="canEdit">
                <button type="submit" mat-stroked-button class="primary-button">Save</button>
            </div>
        </div>
    </form>
    <div *ngIf="showSkillClustersTags" class="d-flex flex-column gap-3 pt-3">
        <div class="h3 black ">Skill Cluster Tags</div>
        <form [formGroup]="skillClusterTagsSettingsFormGroup">
            <main class="admin-form-con">
                <section *ngIf="showHrmsTags" class="admin-input-con-half">
                    <app-data-ordering-control formControlName="hrmsTags" [input]="hrmsTagsConfig" (onSave)="saveSkillClusterHrmsTags()"></app-data-ordering-control>
                </section>
                <section *ngIf="showCustomTags" class="admin-input-con-half">
                    <app-data-ordering-control formControlName="customTags" [input]="customTagsConfig" (onSave)="saveSkillClusterCustomTags()"></app-data-ordering-control>
                </section>
            </main>
        </form>
        <form class="mt-4" [formGroup]="tagsToBeShownAsFiltersFormGroup">
            <main class="admin-form-con">
                <section class="admin-input-con-half">
                    <app-data-ordering-control formControlName="tagsToBeShownAsFiltersToEmployees" [input]="tagsToBeShownAsFiltersToEmployeesConfig" (onSave)="saveTagsToBeShownAsFilters('Employees')"></app-data-ordering-control>
                </section>
                <section class="admin-input-con-half">
                    <app-data-ordering-control formControlName="tagsToBeShownAsFiltersToManagers" [input]="tagsToBeShownAsFiltersToManagersConfig" (onSave)="saveTagsToBeShownAsFilters('Managers')"></app-data-ordering-control>
                </section>
            </main>
        </form>
    </div>
    <form [formGroup]="weightagesForm" (ngSubmit)="saveWeightagesData()" (keydown.enter)="evaluateEnterKey($event)" class="admin-form">
        <div class="d-flex flex-column gap-3">
            <div class="admin-form-con">
                <div *ngFor="let smsOption of weightagesOptions"
                    [ngClass]="smsOption.type=='textarea'||!smsOption.key ? 'admin-input-con-full' : 'admin-input-con-half'"
                    [ngStyle]="{'display':smsOption.showControl?'block':'none'}">
                    <div *ngIf="smsOption.type=='title'" class="h3 black " style="margin:10px 0;">
                        {{smsOption.title}}</div>
                    <div *ngIf="smsOption.type=='sub-title'" class="h4 black" style="margin:10px 0;">
                        {{smsOption.title}}</div>
                    <div *ngIf="(smsOption.type=='text'||smsOption.type=='number')&&smsOption.showControl">
                        <mat-form-field appearance="outline" class="admin-input-form-field">
                            <mat-label>{{smsOption.title}}</mat-label>
                            <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                            <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                                [required]="smsOption.required" autocomplete="off" [type]="smsOption.type"
                                [min]="smsOption.min||0" [max]="smsOption.max||100"
                                maxlength="{{ smsOption.maxLength ? smsOption.maxLength : '70' }}" (change)="changeWeightage($event, smsOption.weightagesPart)"/>
                            <mat-error *ngIf="weightagesForm.get(smsOption.key).invalid">
                                {{weightagesForm.get(smsOption.key).errors.error||smsOption.errorText}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="admin-save-button-con" *ngIf="canEdit">
                <button type="submit" mat-stroked-button class="primary-button">Save</button>
            </div>
        </div>
    </form>
</div>
