export class Client {
    name: string;
    legalName: string;
    address: string;
    contacts: any[];
    licenseStartDt: string;
    licenseEndDt: string;
    primaryColorLightest: string;
    primaryColorLight: string;
    primaryColor: string;
    primaryColorDark: string;
    accentColor: string;
    accentColorDark: string;
    clientId: string;
    logoRect?: string;
    logoSquare?: string;
    disablePrismLogo?: string;
    loadingGIF?: any;
    logoRect128?: string;
    logoSquare128?: string;
    logoSquareRMS?: any;
    logoRectRMS?: any;
    logoSquareOP?: any;
    logoRectOP?: any;
    security?: any;
    integrations?: any;
    smsSettings?: any;
    features: any;
    usePrioritySkill: any;
    prioritySkillScale: any;
    faqData: any;
    skillGroup: string;
    sku: string;
    demandUnits: string;
    allDemandUnits: string;
    categoryWiseSettings: any[] = [];
    hrmsMasterFields: any[] = [];
    additionalMandatoryFields: any[] = [];

    deserialize(input: any) {
        if (!input) return null;
        Object.assign(this, input);
        return this;
    }
}

export interface IDictionary {
    skillsAndCompetencies: string;
    specializations: string;
    mandatory: string;
    optional: string;
    demandUnit: string;
    services: string;
    sku: string;
    skillGroup: string;
    conditionalMandatory: string;
    coreMandatory: string;
    secondarySpecialization: string;
    primarySpecialization: string;
    tertiarySpecialization: string;
    role: string;
    designation: string;
    extDesignationId: string;
    designationLevel: string;
    designationPath: string;
    designationCluster: string;
    skillCluster: string;
    locationCluster: string;
    bookmark: string;
    skillRecommendation: string;
    uploadResume: string;
    myTeam: string;
    teamDetails: string;
    manageLearnings: string;
    exitRating: string;
    skillAdditionRequests: string;
    openRequest: string;
    requestClosed: string;
    resumeApproved: string;
    resumeRejected: string;
    resumeCancelled: string;
    resumeSubmitted: string;
    resumeUpdateDelayed: string;
    rating: string;
    myLearningInitialStatus: string;  
    endorsements: string;
    myLearnings: string;
    skillType: string;
    highDemandSkill: string;
    customFeedOnHomePage?: string
  }
  
