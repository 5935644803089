import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Client } from 'src/app/models/client.model';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';

@Component({
  selector: 'app-courses-settings',
  templateUrl: './courses-settings.component.html',
  styleUrls: ['./courses-settings.component.scss']
})
export class CoursesSettingsComponent implements OnInit {
  private _client: Client;
  @Input() canEdit: boolean = false;
  @Output() onSave = new EventEmitter();
  editForm: FormGroup;
  arr30 = Array.from({ length: 30 }, (_, i) => i + 1);
  smsOptions: any[] = [
    { title: 'Manage course skills in SMS', key: 'manageCourseSkills', type: 'select', options: ['Yes', 'No'], validators: [Validators.required], icon: 'settings_suggest' },
    { title: 'Show All Courses', key: 'shouldShowAllCourses', type: 'select', options: ['Yes', 'No'], validators: [Validators.required], icon: "settings_suggest" },
    { title: 'External Courses selection', key: 'externalCoursesSelection', type: 'select', options: ['Top Picks', 'Pre-selected'], validators: [Validators.required], icon: "settings_suggest" },
    { title: 'Number of active external courses per employee', key: 'numActiveExternalCourses', type: 'select', options: this.arr30, validators: [Validators.required], icon: "settings_suggest" },
    { title: 'Add Course skills to user skills upon completing the course', key: 'addSkillFromCourse', type: 'select', options: ['Yes', 'No'], validators: [Validators.required], icon: "settings_suggest" },
    { title: 'Allow managers to assign courses', key: 'allowManagersToAssignCourses', type: 'select', options: ['Yes', 'No'], icon: "settings_suggest" },
    { title: 'Use result status for mark the course as complete', key: 'useResultStatus', type: 'select', options: ['Yes', 'No'], icon: "settings_suggest"}
  ];
  constructor(private ds: DataService, private weds: WaitErrorDialogsService, private fb: FormBuilder) {
    this.editForm = fb.group({});
  }
  ngOnInit(): void {
  }

  @Input()
  set client(client: Client) {
    this._client = client;
    if ((this.client.features.admin.externalCourses || 'No Access') == 'No Access') {
      this.smsOptions = this.smsOptions.filter((option=>['manageCourseSkills', 'shouldShowAllCourses', 'allowManagersToAssignCourses','useResultStatus'].includes(option.key)))
    }
    this.buildForms();
  }
  get client() {
    return this._client;
  }

  buildForms() {
    this.smsOptions.map((s) => {
      let currentValue = this.editForm.value;
      let value = currentValue[s.key] || this.client.smsSettings[s.key] || s.defaultValue;
      s.showControl = true;
      (s.allowIf || []).map((key) => { if ((this.client.features.admin[key] || 'No Access') == 'No Access') s.showControl = false; });
      (s.checkValue || []).map((item) => { if (item.values.indexOf(this.editForm.contains(item.key) ? this.editForm.get(item.key).value : this.client.smsSettings[item.key]) < 0) s.showControl = false; });
      if (this.editForm.contains(s.key)) this.editForm.removeControl(s.key);
      if (s.showControl) this.editForm.addControl(s.key, new FormControl({ value: value, disabled: !this.canEdit }, s.validators));
    });
  }
  saveData() {
    if (this.editForm.invalid) return;
    let d = this.editForm.value;
    d.type = 'courses';
    this.onSave.emit(d);
  }

  compareSelect(o1: any, o2: any) {
    let keys = ['id', 'categoryId'];
    for (let i = 0; i < keys.length; i++) {
      if ((o1 && o1[keys[i]]) || (o2 && o2[keys[i]])) {
        return (o1 || {})[keys[i]] == (o2 || {})[keys[i]];
      }
    }
    return o1 == o2;
  }

  onSMSOptionSelected(smsOption, event) {
  }

  evaluateEnterKey(event) {
    if ((event.srcElement.className || '').search('textarea') >= 0) return;
    event.preventDefault()
  }
}
