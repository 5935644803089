export interface Badge {
    _id: string;
    badgeCount: number;
    badgeId: string;
    badgeUrl?: string;
    currentLevel: number | null;
    enabled: boolean;
    locked: boolean;
    userId: string;
    badgeType: string;
    isRepeatable: boolean;
    userBadgeId: string;
    name: string;
  }

  export interface BadgesResponse {
    badges: Badge[];
    totalCount: number;
  }

  export enum BadgeType {
    ONE_TIME = 'one-time',
    MULTI_LEVEL = 'multi-level'
  }
