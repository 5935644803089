<mat-divider class="mt-3"></mat-divider>
  <div class="d-flex mt-3 mb-2 align-items-center">
    <div class="h4">Learning Type</div>
    <button mat-stroked-button *ngIf="canEdit" type="button" class="ml-auto primary-button" (click)="addNewItem()">
      <div class="h5 fw-500 item-center label">
          <mat-icon matPrefix class="fs-18 d-flex align-items-center">add</mat-icon>
          <span class="text-uppercase mr-1">Add</span>
      </div>
    </button>
  </div>
  <mat-divider></mat-divider>
<div class="mb-4">
  <table mat-table [dataSource]="data" class="w-100" multiTemplateDataRows>
    <ng-container matColumnDef="learningType">
      <th mat-header-cell *matHeaderCellDef>Learning types</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="text-right">Actions</th>
      <td mat-cell *matCellDef="let element" class="text-right">
        <div class="d-flex gap-2 align-items-center">
          <mat-icon class="ml-auto fs-16 border-radius-1 h-16px color-color-red-60 cursor-pointer" *ngIf="element.isCustom && canEdit" (click)="deleteType(element)">delete</mat-icon>
          <mat-divider vertical *ngIf="element.isCustom && canEdit"></mat-divider>
          <mat-slide-toggle [ngClass]="{'ml-auto': !element.isCustom || !canEdit}" color="primary" [disabled]="!canEdit" [checked]="element.enable" labelPosition="before" (click)="$event.stopPropagation()" (change)="onToggle(element)">
            <span class="caption gray">Enabled</span>
          </mat-slide-toggle>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="tableColumns.length">
        <div class="element-detail" [@detailExpand]="element == expandedElem ? 'expanded' : 'collapsed'">
          <div class="py-4 w-100 detail-div">
            <label class="h5 black">{{element?.id === expandedElem?.id && element.name? 'Edit learning type' : 'Add learning type'}}</label>
            <div class="d-flex gap-3 align-items-center">
              <form [formGroup]="formGroup" (keydown.enter)="$event.preventDefault()" class="my-3 w-50">
                <div>
                  <mat-form-field appearance="outline" class="input-form-field d-block">
                    <mat-label class="mb-2">Learning type</mat-label>
                    <input matInput placeholder="Enter learning type" formControlName="name" required autocomplete="off" />
                  </mat-form-field>
                  <mat-error *ngIf="formGroup.get('name')?.errors && formGroup?.get('name').touched">
                    {{ formGroup.get('name')?.errors?.required && 'this input is mandatory' || 
                      formGroup.get('name')?.errors?.pattern && 'Invalid input character' ||
                      formGroup.get('name')?.errors?.duplicateNames && 'Type already exist'
                    }}
                  </mat-error>
                </div>
              </form>
              <button mat-stroked-button *ngIf="element.isCustom && canEdit" type="button" class="primary-button" (click)="addLearningType()">
                <div class="h5 fw-500 item-center label">
                    <span class="text-uppercase mr-1"> Add </span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: tableColumns;" class="element-row" matRipple [class.expanded-row]="expandedElem == element" (click)="onRowClick(element)">
    </tr>
    <tr mat-row *matRowDef="let element; columns: ['expandedDetail'];" class="detail-row"></tr>
  </table>
</div>
