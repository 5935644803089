<div class="alias-con">
    <div class="h3 black ">Aliases for key SMS terms</div>
    <form [formGroup]="aliasForm" (ngSubmit)="saveAliases()" (keydown.enter)="$event.preventDefault()"
        class="admin-form">
        <div class="admin-form-con">
            <div *ngFor="let alias of aliases"
                [ngClass]="alias.type=='textarea' ? 'admin-input-con-full' : 'admin-input-con-half'"
                [ngStyle]="{'display':alias.showControl?'block':'none'}">
                <div *ngIf="alias.showControl">
                    <mat-form-field appearance="outline" class="admin-input-form-field">
                        <mat-label>{{alias.title}}</mat-label>
                        <mat-icon matPrefix>{{alias.icon}}</mat-icon>
                        <input matInput placeholder="{{alias.title}}" formControlName="{{alias.key}}"
                            [required]="alias.required" autocomplete="off"
                            maxlength="{{ alias.maxLength ? alias.maxLength : '70' }}" />
                        <mat-error *ngIf="aliasForm.get(alias.key).invalid">{{alias.errorText||'This input is
                            mandatory'}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="admin-save-button-con" *ngIf="canEdit">
            <button type="submit" mat-stroked-button class="primary-button">Save</button>
        </div>
    </form>
</div>