import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Client } from 'src/app/models/client.model';
import { EditableTableComponent } from 'src/app/modules/common-components/editable-table/editable-table.component';
import { ValidateString } from 'src/app/services/validation.service';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';

@Component({
  selector: 'app-skill-types-settings',
  templateUrl: './skill-types-settings.component.html',
  styleUrls: ['./skill-types-settings.component.scss']
})
export class SkillTypesSettingsComponent implements OnInit {
  private _client: Client;
  @Input() canEdit: boolean = false;
  @Input() category;
  @Output() onSave = new EventEmitter();
  skillTypesFormValid: boolean = true; 
  settings: any;
  skillTypesColumns: any[];
  editForm: FormGroup;
  smsOptions: any[] = [
    { title: 'Enable Skill Types', key: 'enablePrimarySkill', type: 'select', validators: [Validators.required], icon: 'settings_suggest', required: true },
  ]
  @ViewChild('skillTypes') skillTypesTable: EditableTableComponent;
  constructor(private ds: DataService, private weds: WaitErrorDialogsService, private fb: FormBuilder, private dialog: MatDialog) { this.editForm = this.fb.group({}); }

  ngOnInit(): void {
    this.skillTypesColumns = [
      { title: 'Skill Types', key: 'name', type: 'text', disabled: !this.canEdit, required: true, validators: [Validators.required, ValidateString(2)], placeholder: 'E.g.: Primary, Secondary, Others' },
      { title: 'Minimum', key: 'min', type: 'number', disabled: !this.canEdit, required: false, validators: [] },
      { title: 'Maximum', key: 'max', type: 'number', disabled: !this.canEdit, required: false, validators: [] },
    ];
  }

  @Input()
  set client(client: Client) {
    this.settings = client.smsSettings.categoryWiseSettings.filter((item) => item.categoryId == this.category.categoryId)[0];
    this._client = client;
    this.buildForms();
  }
  get client() {
    return this._client;
  }

  buildForms() {
    this.smsOptions.map((s) => {
      let currentValue = this.editForm.value;
      let value = currentValue[s.key] || this.settings[s.key] || s.defaultValue;
      s.showControl = true;
      (s.allowIf || []).map((key) => { if ((this.client.features.admin[key] || 'No Access') == 'No Access') s.showControl = false; });
      (s.checkValue || []).map((item) => { if (item.values.indexOf(this.editForm.contains(item.key) ? this.editForm.get(item.key).value : this.client.smsSettings[item.key]) < 0) s.showControl = false; });
      if (this.editForm.contains(s.key)) this.editForm.removeControl(s.key);
      if (s.showControl) this.editForm.addControl(s.key, new FormControl({ value: value, disabled: !this.canEdit }, s.validators));
    });
  }

  compareSelect(o1: any, o2: any) {
    let keys = ['id', 'categoryId'];
    for (let i = 0; i < keys.length; i++) {
      if ((o1 && o1[keys[i]]) || (o2 && o2[keys[i]])) {
        return (o1 || {})[keys[i]] == (o2 || {})[keys[i]];
      }
    }
    return o1 == o2;
  }

  onSkillTypesValidityChange(isValid: boolean) {
    this.skillTypesFormValid = isValid;
  }

  saveData() {
    if (this.editForm.invalid || !this.skillTypesFormValid) return;
    let d = this.editForm.value;
    d.type = 'skillType'
    d.categoryId = this.settings.categoryId;
    if (d.enablePrimarySkill == 'Yes') {
      let data = this.skillTypesTable.getDataFromForm();
      if (!data.length) { this.weds.showDialog({ type: 'generic', code: 180 }); return; }
      d.skillTypes = data;
    }
    this.onSave.emit(d);
  }
  addSkillTypesRow() {
    this.skillTypesTable.addRow();
  }
}
