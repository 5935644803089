import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { PaginatorUiComponent } from '../paginator-ui/paginator-ui.component';
import { progressCardGroup } from '../progress-card-group/progress-card-group.model';
import { IProgressCard } from '../progress-card/progress-card.model';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { IFilter, ILearningElementCardData } from '../../my-learnings/my-learning.model';
import { MyLearningService } from '../../my-learnings/my-learning.service';

@Component({
  selector: 'app-progress-card-view-all',
  templateUrl: './progress-card-view-all.component.html',
  styleUrls: ['./progress-card-view-all.component.scss']
})
export class ProgressCardViewAllComponent implements OnInit, OnDestroy {
  @ViewChild('paginator') paginator: PaginatorUiComponent;
  cards: Array<IProgressCard>;
  progressCardGroupConfig: progressCardGroup = {cards: []};
  limit: number = 10;
  totalCardCount: number = 0;
  headerConfig = {}

  public LearningElementTypeOptions;
  public filterType: FormControl = new FormControl([]);

  private fetchData = new Subject<IFilter[]>();
  private destroy = new Subject<IFilter[]>();
  private emptyCards = new Array(8).fill(0);

  constructor(private location: Location,
    private route: ActivatedRoute,
    private ds: DataService,
    private weds: WaitErrorDialogsService,
    private myLearningService: MyLearningService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      this.headerConfig = JSON.parse(queryParams.headerConfig)
      this.progressCardGroupConfig = {
        ...queryParams,
        getDataFromParent: queryParams.getDataFromParent === 'true',
        allowAdditionalFilters: queryParams.allowAdditionalFilters === 'true',
        cards: this.emptyCards,
        cardsShown: [],
        filters: JSON.parse(queryParams.filters),
        fetchData: null
      }     
    });

    this.getTypeConstants();
    const filter = this.progressCardGroupConfig?.filters?.find(filter => filter.key === 'type');
    if (filter) {
      this.filterType.setValue(filter.value);
    }
    this.initFilterSubscription()
    this.fetchData.next(this.progressCardGroupConfig?.filters);
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  getData(filters: IFilter[]) {
    this.setProgressCards(this.emptyCards);
    this.fetchData.next(this.progressCardGroupConfig?.filters);
  }

  setProgressCards(cards: Array<IProgressCard>) {
    this.progressCardGroupConfig = {
      ...this.progressCardGroupConfig,
      cards: cards
    }
  }

  onPaginationEvent(event) {
    if (event.type == 'getData') {
      this.getData(this.progressCardGroupConfig?.filters|| []);
    }
  }

  backClicked(){
    this.location.back();
  }

  onFilterChange() {
    if (!this.filterType.value.length) {
      const index = this.progressCardGroupConfig?.filters?.indexOf(filter => filter.key === 'type');
      this.progressCardGroupConfig?.filters?.splice(index, 1);
    } else {
      const filter = this.progressCardGroupConfig?.filters?.find(filter => filter.key === 'type');
      if (filter) {
        filter.value = this.filterType.value;
      } else {
        this.progressCardGroupConfig?.filters?.push({key: 'type', value: this.filterType.value})
      }
    }
    
    this.getData(this.progressCardGroupConfig?.filters)
  }

  private initFilterSubscription() {
    this.fetchData
      .pipe(
        switchMap((filters: IFilter[]) => {
          this.limit = this.paginator?.limit || 10;
          const currentPage = this.paginator?.pageNumber || 1;
          const url = `${this.progressCardGroupConfig.getDataApi}?page=${currentPage}&limit=${this.limit}`;
          return this.ds[this.progressCardGroupConfig.getDataApiMethod](url, { filters });
        }),
        takeUntil(this.destroy)
      )
      .subscribe(
        (res: ILearningElementCardData) => {
          this.setProgressCards(this.progressCardGroupConfig.responseFormat ? res.data?.[0]?.data : res?.data);
          this.totalCardCount = res.count;
          setTimeout(() => {
            this.paginator?.calculatePageNumbers({ totalDataCnt: this.totalCardCount });
          }, 100);
        },
        (err) => {
          this.weds.showDialog({ type: 'generic', code: 0 });
        }
      );
  }

  private getTypeConstants() {
    this.myLearningService.getConstantFiltersValue({fields: ['TYPE']}, [{key: 'TYPE'}]).subscribe(data => {
      this.LearningElementTypeOptions = [...data[0].options];
    });
  }
}
