<div class="reports-center-settings-con">
  <div class="h4 black">Certification Settings</div>
  <form [formGroup]="editForm" class="admin-form">
    <div class="admin-form-con">
      <div *ngFor="let smsOption of smsOptions"
      [ngClass]="{
        'admin-input-con-full': smsOption.type === 'textarea' || smsOption.type === 'sub-title',
        'admin-input-con-half': smsOption.type !== 'textarea' && smsOption.type !== 'sub-title',
        'd-block': smsOption.showControl,
        'd-none': !smsOption.showControl
      }">
          <div *ngIf="smsOption.type=='select'&&smsOption.showControl">
              <mat-form-field appearance="outline" class="admin-input-form-field">
                  <mat-label>{{smsOption.title}}</mat-label>
                  <mat-icon matPrefix *ngIf="smsOption.icon">{{smsOption.icon}}</mat-icon>
                  <mat-select formControlName="{{smsOption.key}}" [required]="smsOption.required"
                      [multiple]="smsOption.isMulti">
                      <mat-option *ngFor="let option of smsOption.options"
                          [value]="option.value" [disabled]="option.disabled">
                          {{smsOption.displayKey?option[smsOption.displayKey]:option}}
                      </mat-option>
                  </mat-select>
                  <mat-error *ngIf="editForm.get(smsOption.key).invalid">{{smsOption.errorText||'This input is
                      mandatory'}}</mat-error>
              </mat-form-field>
          </div>
          <div *ngIf="smsOption.type=='multiSelectChip' && editForm?.controls?.[smsOption.key]" class="input-con-half">
            <app-multi-select-chip [disabled]="!canEdit" [input]="smsOption.subOptions" [formControlName]="smsOption.key">
            </app-multi-select-chip>
          </div>
      </div>
    </div> 
  </form>
</div>
