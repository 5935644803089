<div class="d-flex flex-column align-items-start gap-12" *ngIf="(myLearningTreatment === TREATMENT_MAP.V1 && config[0]?.cards?.length) || myLearningTreatment === TREATMENT_MAP.V2">
    <div class="d-flex align-items-start flex-column" *ngIf="!headerConfig?.hideViewMoreBtn">
    <span class="d-flex">
      <mat-icon *ngIf="headerConfig?.headerIcon" class="translateY-2px fs-20">{{headerConfig?.headerIcon}}</mat-icon>
      <label class="h4">{{ headerConfig?.groupName }} <span *ngIf="config.length > 1">({{totalCount}})</span></label>
    </span>
  </div>

  <div class="d-flex flex-column align-items-start gap-3 custom-width" [ngClass]="{
      'card-group-box': headerConfig?.showGrayBG,
      'full-height': headerConfig?.hideViewMoreBtn
  }">

    <mat-tab-group class="w-100" [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChange()" [ngClass]="{'hide-tab-header': config.length === 1 || myLearningTreatment === TREATMENT_MAP.V1}">
      <ng-container *ngFor="let tab of config">
        <mat-tab>
          <ng-template mat-tab-label>
            <div>{{tab.groupName}} <span *ngIf="tab.allowAdditionalFilters">({{tab.totalDataCount || 0}})</span></div>
          </ng-template>
          <div class="pt-3 caption gray" *ngIf="tab?.subTitle && myLearningTreatment === TREATMENT_MAP.V2">{{tab.subTitle}}</div>
          <div class="d-flex gap-2 align-items-baseline" *ngIf="tab.allowAdditionalFilters">
            <div class="caption gray">Filter by:</div>
            <div class="mt-3">
              <form [formGroup]="tab.appliedFilterControl">
                  <ng-container *ngFor="let filter of tab?.additionalFilters">
                      <div matTooltip="Click anywhere to apply Filters" *ngIf="filter?.options?.length">
                          <mat-form-field appearance="outline" class="filter">
                            <mat-select [placeholder]="filter?.title?.toLowerCase() | titlecase" multiple [formControlName]="filter?.key" (selectionChange)="onFilterApply()">
                                <mat-option *ngFor="let option of filter?.options" [value]="option?.pk">{{option?.title}}</mat-option>
                            </mat-select>
                            <mat-icon matSuffix class="mat-custom-arrow">keyboard_arrow_down</mat-icon>
                          </mat-form-field>
                      </div>
                  </ng-container>
              </form>
            </div>
          </div>
          <div class="d-flex gap-3 flex-wrap w-100 align-items-stretch mt-3" #cardHolder>
            <div *ngFor="let card of tab.cardsShown" class="d-flex align-items-end">
                <app-progress-card [data]=card></app-progress-card>
            </div>
          </div>
  
          <div class="mt-3" *ngIf="tab.cards?.length > numberOfCardsToShow && !isLoading && !tab.hideViewMoreBtn">
              <ng-container *ngIf="tab.cards?.length <= 4 ; else viewAllBtn">
                  <button mat-button class="secondary-button h6 item-center"
                      (click)="toggleContainerExpansion()">
                      <label class="cursor-pointer"> {{ isExpanded ? 'Show Less' : 'Show More' }}</label>
                      <mat-icon>{{ isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
                  </button>
              </ng-container>
  
              <ng-template #viewAllBtn >
                  <button mat-button class="secondary-button h6 item-center"
                      (click)="redirectToViewAll()">
                      <label class="cursor-pointer">View All {{ tab.totalDataCount }} learnings</label>
                      <mat-icon class="translateY-2px fs-18 cursor-pointer">east</mat-icon>
                  </button>
              </ng-template>
          </div>
  
          <div *ngIf="!isLoading && !tab.cardsShown.length" class="no-data h4 gray my-3 w-100">
              <img src="assets/nodata.png" />
              <div>Sorry, there's no data available for you at this moment.</div>
          </div>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </div>

</div>