import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Client } from 'src/app/models/client.model';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';

@Component({
  selector: 'app-skill-ratings-settings',
  templateUrl: './skill-ratings-settings.component.html',
  styleUrls: ['./skill-ratings-settings.component.scss']
})
export class SkillRatingsSettingsComponent implements OnInit {
  private _client: Client;
  @Input() canEdit: boolean = false;
  @Input() category: any;
  @Output() onSave = new EventEmitter();

  settings: any;
  skillRatingsColumns: any[];
  constructor(private ds: DataService, private weds: WaitErrorDialogsService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.skillRatingsColumns = [
      { title: 'Rating type', key: 'name', disabled: true, type: 'text' },
      { title: 'Custom Name', key: 'title', type: 'text', placeholder: 'Custom rating name', disabled: !this.canEdit, validators: [Validators.min(3)] },
      { title: 'Weightage', key: 'weight', type: 'number', disabled: !this.canEdit, validators: [Validators.required, Validators.min(0), Validators.max(10)] },
      { title: 'Manager approval', key: 'requiresApproval', type: 'select', options: ["Yes", "No"], disabled: !this.canEdit, validators: [Validators.required] },
      { title: 'Use Rating', key: 'useRating', type: 'select', options: ["Yes", "No"], disabled: !this.canEdit, validators: [Validators.required] },
    ];
  }

  @Input()
  set client(client: Client) {
    this.settings = client.smsSettings.categoryWiseSettings.filter((item) => item.categoryId == this.category.categoryId)[0];
    this._client = client;
    this.updateMissingData(this.settings.skillRatings);
  }
  get client() {
    return this._client;
  }

  updateMissingData(skillRatings) {
    let hash = {};
    skillRatings.map((s) => { hash[s.dataKey] = 1; });
    let ratings = { 'incomingRating': 'Incoming Rating', 'projectRating': 'Project Rating', 'trainingRating': 'Training Rating', 'selfAcquiredRating': 'Self-Acquired Rating', 'aspirationalRating': 'Aspirational Rating', 'managerialRating': 'Managerial Rating' };
    for (let key in ratings) {
      let s: any = { name: ratings[key], dataKey: key, weight: 1, requiresApproval: 'No', useRating: 'No' };
      if (!hash[s.dataKey]) skillRatings.push(s);
    };
    skillRatings.map((s) => {
      if (['aspirationalRating', 'trainingRating', 'managerialRating'].indexOf(s.dataKey) >= 0) { s.requiresApproval = 'No'; s.disablerequiresApproval = true; } else delete s.disablerequiresApproval;
    })
  }

  saveData(data) {
    let d = { type: 'skillRatings', skillRatings: data, categoryId: this.category.categoryId };
    let flag = true; data.map((rating) => { if (rating.useRating == 'Yes') flag = false; })
    if (flag) {
      this.weds.showDialog({ type: 'generic', code: 132 }); return;
    }
    this.onSave.emit(d);
  }

  compareSelect(o1: any, o2: any) {
    let keys = ['id', 'categoryId'];
    for (let i = 0; i < keys.length; i++) {
      if ((o1 && o1[keys[i]]) || (o2 && o2[keys[i]])) {
        return (o1 || {})[keys[i]] == (o2 || {})[keys[i]];
      }
    }
    return o1 == o2;
  }
}
