import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Client } from 'src/app/models/client.model';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-recommendation-settings',
  templateUrl: './app-recommendation-settings.component.html',
  styleUrls: ['./app-recommendation-settings.component.scss']
})
export class AppRecommendationSettingsComponent {
  private _client: Client;
  @Input() canEdit: boolean = false;
  @Output() onSave = new EventEmitter();
  recommendationsForm: FormGroup;
  smsSettings: Client;
  showSelfAcquiredRatingInDropDown: boolean = false;
  ratingTypes: any = {};
  recommendationOptions: any[] = [
    { title: 'Allow Machine Rating', key: 'allowMachineRating', type: 'select', options: ['Yes', 'No'], validators: [Validators.required], icon: "settings_suggest", required: false },
    { title: 'Allow Blank Machine Rating', key: 'allowBlankMachineRating', type: 'select', options: ['Yes', 'No'], validators: [Validators.required], icon: "settings_suggest", required: false },
    { title: 'Select rating type to be updated', key: 'ratingType', type: 'select', options: [], validators: [Validators.required], icon: "settings_suggest", required: false, displayKey: 'title', dataKey: 'dataKey', dataObjMap: 'ratingTypes' },
    {
      title: 'Choose Skill recommendation fields for webhook',
      key: 'skillRecommendationFieldsForWebhook',
      type: 'select',
      options: [
        { title: "Skill Source", dataKey: "skillSource" },
        { title: "Skill Update Source", dataKey: "skillUpdateSource"},
        { title: "Shadow Rating", dataKey: "shadowRating" },
        { title: "Evidences", dataKey: "evidences" },
        { title: "Resume Info", dataKey: "resumeInfo"}
      ],
      icon: 'settings_suggest',
      isMulti: true,
      displayKey: 'title', 
      dataKey: 'dataKey'
    },
    { title: 'Feature Description', key: 'skillRecommendationFeatureDescription', type: 'textarea', options: [], validators: [Validators.maxLength(1000)], icon: "settings_suggest", required: false, defaultValue: "Our AI-ML enabled Skill Recommendation Engine curates personalized Skill Recommendations based on users' touch points across the systems. The Engine learns through millions of touchpoints within the industry. The data sources for the recommendations include your various touch points such as projects, courses, certifications, etc." }
  ];

  constructor(private fb: FormBuilder, private util: UtilitiesService) {
    this.recommendationsForm = this.fb.group({});
  }

  @Input()
  set client(client: Client) {
    this._client = client;
    this.smsSettings = new Client().deserialize(client).smsSettings;
    this.showOrHideSelfAcquiredRatingInDropdown();
    this.buildForms();
  }
  get client() {
    return this._client;
  }

  showOrHideSelfAcquiredRatingInDropdown() {
    this.recommendationOptions[2].options = [];
    this.ratingTypes = {};
    this.showSelfAcquiredRatingInDropDown = false;
    for (let category of this.smsSettings?.categoryWiseSettings) {
      for (let rating of category.skillRatings) {
        if (rating.dataKey === 'incomingRating' && !this.ratingTypes['incomingRating'] && rating.useRating === 'Yes') {
          this.ratingTypes['incomingRating'] = rating;
          this.recommendationOptions[2].options.push(rating);
        }
        if (rating.dataKey === 'selfAcquiredRating' && rating.useRating === 'Yes' && !this.ratingTypes['selfAcquiredRating']) {
          this.ratingTypes['selfAcquiredRating'] = rating;
          this.showSelfAcquiredRatingInDropDown = true;
          this.recommendationOptions[2].options.push(rating);
        }
      }
      if (this.showSelfAcquiredRatingInDropDown) break;
    }
  }

  buildForms() {
    this.recommendationOptions.map((s) => {
      let currentValue = this.recommendationsForm.value;
      let value = currentValue[s.key] || this.client.smsSettings[s.key] || s.defaultValue;
      if(s.key == "skillRecommendationFieldsForWebhook") {
        value = s.options.filter(({ dataKey }) => this.client.smsSettings[s.key]?.includes(dataKey));
      } else if (s.dataKey && typeof (value) !== 'object' && s.dataObjMap) {
        value = this[s?.dataObjMap]?.[value];
      }
      s.showControl = true;
      (s.allowIf || []).map((key) => { if ((this.client.features.admin[key] || 'No Access') == 'No Access') s.showControl = false; });
      (s.checkValue || []).map((item) => { if (item.values.indexOf(this.recommendationsForm.contains(item.key) ? this.recommendationsForm.get(item.key).value : this.client.smsSettings[item.key]) < 0) s.showControl = false; });
      if (this.recommendationsForm.contains(s.key)) this.recommendationsForm.removeControl(s.key);
      if (s.showControl) this.recommendationsForm.addControl(s.key, new FormControl({ value: value, disabled: !this.canEdit }, s.validators));
    });

  }

  saveRecommendations() {
    if (this.recommendationsForm.invalid) return;
    let d = this.recommendationsForm.value;
    this.onSave.emit({
      ...d,
      ratingType: d.ratingType?.['dataKey'],
      skillRecommendationFieldsForWebhook: d.skillRecommendationFieldsForWebhook.map(({ dataKey }) => dataKey),
      type: 'recommendations',
    });
  }

  onRecommendationOptionSelected(recommendationOptions, event) {
    if (['allowMachineRating', 'allowBlankMachineRating', 'ratingType'].indexOf(recommendationOptions.key) >= 0) this.buildForms();
  }

  compareSelect(o1: any, o2: any) {
    let keys = ['id', 'categoryId', 'dataKey'];
    for (let i = 0; i < keys.length; i++) {
      if ((o1 && o1[keys[i]]) || (o2 && o2[keys[i]])) {
        return (o1 || {})[keys[i]] == (o2 || {})[keys[i]];
      }
    }
    return o1 == o2;
  }

}
