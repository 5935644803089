import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Client } from 'src/app/models/client.model';

@Component({
  selector: 'app-tooltip-settings',
  templateUrl: './tooltip-settings.component.html',
  styleUrls: ['./tooltip-settings.component.scss']
})
export class TooltipSettingsComponent implements OnChanges {

  private _client: Client;
  @Input() levels: string = 'Stars 1-5';
  @Input() canEdit: boolean = false;
  @Output() onSave = new EventEmitter();
  editForm: FormGroup;
  emptyForm: boolean = false;
  fullSMSOptions: any[] = [
    { title: 'Label for Rating Level 1', key: 'skillToolTipLabel1', type: 'text', icon: 'settings_suggest' },
    { title: 'Description for Rating Level 1', key: 'configureSkillsTooltip1', type: 'textarea', validators: [Validators.maxLength(1000)], icon: 'settings_suggest' },
    { title: 'Label for Rating Level 2', key: 'skillToolTipLabel2', type: 'text', icon: 'settings_suggest' },
    { title: 'Description for Rating Level 2', key: 'configureSkillsTooltip2', type: 'textarea', validators: [Validators.maxLength(1000)], icon: 'settings_suggest' },
    { title: 'Label for Rating Level 3', key: 'skillToolTipLabel3', type: 'text', icon: 'settings_suggest' },
    { title: 'Description for Rating Level 3', key: 'configureSkillsTooltip3', type: 'textarea', validators: [Validators.maxLength(1000)], icon: 'settings_suggest' },
    { title: 'Label for Rating Level 4', key: 'skillToolTipLabel4', type: 'text', icon: 'settings_suggest' },
    { title: 'Description for Rating Level 4', key: 'configureSkillsTooltip4', type: 'textarea', validators: [Validators.maxLength(1000)], icon: 'settings_suggest' },
    { title: 'Label for Rating Level 5', key: 'skillToolTipLabel5', type: 'text', icon: 'settings_suggest' },
    { title: 'Description for Rating Level 5', key: 'configureSkillsTooltip5', type: 'textarea', validators: [Validators.maxLength(1000)], icon: 'settings_suggest' },
  ];
  smsOptions: any[] = [];
  constructor(private fb: FormBuilder) {
    this.editForm = this.fb.group({});
  }

  ngOnChanges(changes:SimpleChanges): void {
    if (changes.levels && changes.levels.currentValue) {
      this.buildForms();
    }
  }

  @Input()
  set client(client: Client) {
    this._client = client;
    this.emptyForm = true;
    this.buildForms();
  }
  get client() {
    return this._client;
  }

  buildForms() {
    if (this.levels === 'Stars 1-4') {
      this.smsOptions = this.fullSMSOptions.slice(0, -2); 
    } else {
      this.smsOptions = this.fullSMSOptions;
    }
    this.smsOptions.map((s) => {
      let currentValue = this.editForm.value;
      let value = currentValue[s.key] || this.client.smsSettings[s.key] || s.defaultValue;
      s.showControl = s.showControl || true;
      (s.allowIf || []).map((key) => { if ((this.client.features.admin[key] || 'No Access') == 'No Access') s.showControl = false; });
      (s.checkValue || []).map((item) => { if (item.values.indexOf(this.editForm.contains(item.key) ? this.editForm.get(item.key).value : this.client.smsSettings[item.key]) < 0) s.showControl = false; });
      if (this.editForm.contains(s.key)) this.editForm.removeControl(s.key);
      if (s.showControl) this.editForm.addControl(s.key, new FormControl({ value: value, disabled: !this.canEdit }, s.validators));
      if (s.showControl) this.emptyForm = false;
    });
  }

  saveData() {
    if (this.editForm.invalid) return;
    let d = this.editForm.value;
    d.type = 'skillTooltip';
    this.onSave.emit(d);
  }

  compareSelect(o1: any, o2: any) {
  }

  onSMSOptionSelected(smsOption, event) {
  }

  evaluateEnterKey(event) {
    if ((event.srcElement.className || '').search('textarea') >= 0) return;
    event.preventDefault()
  }

}
