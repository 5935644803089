import { Component, OnInit } from '@angular/core';
import { BadgesService } from './modules/badges/badges.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'prism-ui';

  // Injecting BadgesService to initialize the badge updates
  constructor(
    private badgesService: BadgesService  
  ) { }

  ngOnInit(): void { }
}
