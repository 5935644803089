import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SharedDialogComponent } from 'src/app/modules/common-components/shared-dialog/shared-dialog.component';
import { tagFieldRequiredOptions, tagFieldSuffixes, tagFieldTypeOptions } from '../sms-settings.model';
@Component({
  selector: 'app-hrms-masters-settings',
  templateUrl: './hrms-masters-settings.component.html',
  styleUrls: ['./hrms-masters-settings.component.scss'],
})
export class HrmsMastersSettingsComponent implements OnInit {
  @Input() hrmsMastersConfig: any;
  @Output() onSave = new EventEmitter();
  public requiredOptions = [tagFieldRequiredOptions.Mandatory, tagFieldRequiredOptions.Optional];
  public inputTypeOptions = [tagFieldTypeOptions.MultiSelect, tagFieldTypeOptions.Select];
  canEdit: string
  hrmsMasterFields: Array<any>
  hrmsFieldsChosenForMaster: Array<any>

  preconfiguredAddtionalField: string[] = ['External Id'];

  hrmsMasterAvailableFieldsFormControl = new FormControl();
  hrmsFieldsChosenForMasterFormGroup = new FormGroup({});

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.canEdit = this.hrmsMastersConfig.canEdit;
    this.hrmsMasterFields = this.hrmsMastersConfig.hrmsMasterFields;
    this.hrmsFieldsChosenForMaster = this.hrmsMastersConfig.hrmsFieldsChosenForMaster;

    this.hrmsMasterAvailableFieldsFormControl.setValue(this.hrmsFieldsChosenForMaster.map((hrmsField) => hrmsField.field));
    this.hrmsFieldsChosenForMaster.forEach((field) => {
      this.hrmsFieldsChosenForMasterFormGroup.addControl(
        field.dataKey + tagFieldSuffixes.additionalFields,
        new FormControl({ value: field['selectedAdditionalFields'] || [], disabled: !this.canEdit }, [])
      );
      this.hrmsFieldsChosenForMasterFormGroup.addControl(
        field.dataKey + tagFieldSuffixes.isMandatory,
        new FormControl({ value: field['isMandatory'] ? tagFieldRequiredOptions.Mandatory : tagFieldRequiredOptions.Optional, disabled: !this.canEdit }, [])
      );
      this.hrmsFieldsChosenForMasterFormGroup.addControl(
        field.dataKey + tagFieldSuffixes.isMultiSelect,
        new FormControl({ value: field['isMultiSelect'] ? tagFieldTypeOptions.MultiSelect : tagFieldTypeOptions.Select, disabled: !this.canEdit }, [])
      );
    });
    if(!this.canEdit) this.hrmsMasterAvailableFieldsFormControl.disable();
  }

  // method to change order/heirarchy of tags
  changeTagsHierarchy(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.hrmsFieldsChosenForMaster, event.previousIndex, event.currentIndex);
  }

  // method to set hrms masters fields
  hrmsFieldsChosenForMasterChanged({ value: selectedValues }) {
    this.hrmsFieldsChosenForMaster = this.hrmsMasterFields.filter(availableField => selectedValues.includes(availableField.field))
    this.hrmsFieldsChosenForMaster.forEach((field) => {
      if(!this.hrmsFieldsChosenForMasterFormGroup.contains(field.dataKey)) {
        this.hrmsFieldsChosenForMasterFormGroup.addControl(
          field.dataKey + tagFieldSuffixes.additionalFields,
          new FormControl({ value: [], disabled: !this.canEdit }, [])
        );
        this.hrmsFieldsChosenForMasterFormGroup.addControl(
          field.dataKey + tagFieldSuffixes.isMandatory,
          new FormControl({ value: tagFieldRequiredOptions.Optional, disabled: !this.canEdit }, [])
        );
        this.hrmsFieldsChosenForMasterFormGroup.addControl(
          field.dataKey + tagFieldSuffixes.isMultiSelect,
          new FormControl({ value: tagFieldTypeOptions.MultiSelect, disabled: !this.canEdit }, [])
        );
      }
    })
  }

  // method to remove field from hrms masters
  removeFieldFromHrmsMasters(fieldToRemove) {
    this.hrmsFieldsChosenForMaster = this.hrmsFieldsChosenForMaster.filter(field => field.dataKey != fieldToRemove);
    this.hrmsMasterAvailableFieldsFormControl.setValue(this.hrmsFieldsChosenForMaster.map((field) => field.field));
    this.hrmsFieldsChosenForMasterFormGroup.removeControl(fieldToRemove);
  }

  // method to save hrms masters data
  saveHrmsMasters() {
    let dialogRef = this.dialog.open(SharedDialogComponent, { panelClass: 'shared-dialog', disableClose: false });
    dialogRef.componentInstance.data = { type: 'generic', title: 'Confirm save', message: 'This is a dangerous setting, are you absolutely sure you want to save these settings?', btnTitle: 'Yes', btn2Title: 'Cancel' };
    const onEvent = dialogRef.componentInstance.onEvent.subscribe((data: any) => {
      dialogRef.close();
      if (data.event == 'BTN_CLICK') {
        this.hrmsFieldsChosenForMaster.forEach((masterField) => {
          masterField['selectedAdditionalFields'] = this.hrmsFieldsChosenForMasterFormGroup.value[masterField.dataKey + tagFieldSuffixes.additionalFields];
          masterField['isMandatory'] = this.hrmsFieldsChosenForMasterFormGroup.value[masterField.dataKey + tagFieldSuffixes.isMandatory] === tagFieldRequiredOptions.Mandatory;
          masterField['isMultiSelect'] = this.hrmsFieldsChosenForMasterFormGroup.value[masterField.dataKey + tagFieldSuffixes.isMultiSelect] === tagFieldTypeOptions.MultiSelect;
        });
        this.onSave.emit({ type: 'hrmsMaster', hrmsFieldsChosenForMaster: this.hrmsFieldsChosenForMaster });
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      onEvent.unsubscribe();
    });
  }

  saveHrmsTagsHeirarchy() {
  }
}
