import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Client } from 'src/app/models/client.model';
import { DataService } from 'src/app/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class AvatarService {
 
  private avatarUrlSubject: BehaviorSubject<string>;

  constructor(private ds: DataService) {
    this.avatarUrlSubject = new BehaviorSubject(null);
  }

  public get avatarUrl$(): Observable<string> {
    return this.avatarUrlSubject.asObservable();
  }

  public setAvatarUrl(updatedUrl: string): void {
    this.avatarUrlSubject.next(updatedUrl);
  }

  public hasAccessAvatar(updatedClient?: Client): boolean {
    const client = updatedClient || this.ds.client;
    return (client?.smsSettings?.['allow3dAvatars'] || 'No') !== 'No';
  }
}
