import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { IReviewSkillParams } from './review-skill.model';
import { SkillTypeValidationService } from 'src/app/modules/my-skills/services/skill-type-validation.service';
import { DIALOG_ACTION_TYPE } from '../../my-profile/my-profile/my-profile.modal';

@Component({
  selector: 'app-review-skill',
  templateUrl: './review-skill.component.html',
  styleUrls: ['./review-skill.component.scss']
})
export class ReviewSkillComponent implements OnInit {

  @Output() onEvent = new EventEmitter();

  public reviewSkillParams: IReviewSkillParams;
  public displayedColumns = ['skill', 'type'];
  public skillForm = this.fb.group({});

  private settings;
  private skillQuestions = [
    { key: "skillTypeId", title: this.ds.dictionary.skillType, type: "Tagging", form: true, validators: [], placeholder: 'None', options: [] },
  ]
  constructor(private ds: DataService,
    private fb: FormBuilder,
    private skillTypeValidationService: SkillTypeValidationService 
  ) { }

  ngOnInit(): void {
    this.settings = this.ds.client.smsSettings.categoryWiseSettings.find((item) => item.categoryId == this.reviewSkillParams.categoryId);
    this.updateQuestionsForSettings();
  }

  onFormValueChange(event, skill) {
    skill.skillTypeId = event.value;
    const {existingSkillsForUpdate, newSkillsForUpdate} = this.reviewSkillParams.updateSkillObject;

    if (!this.reviewSkillParams.newSkillIds.has(skill.skillItemId) && !existingSkillsForUpdate.some(skillItem => skillItem.skillItemId === skill.skillItemId)) {
      existingSkillsForUpdate.push(skill);
    } else if (this.reviewSkillParams.newSkillIds.has(skill.skillItemId) && !newSkillsForUpdate.some(skillItem => skillItem.skillItemId === skill.skillItemId)) {
      newSkillsForUpdate.push(skill);
    }
    
    this.updateSkillTypeErrorSummary();
  }

  closeDialog() {
    this.onEvent.emit();
  }

  isNewSkill(skill) {
    return this.reviewSkillParams.newSkillIds.has(skill.skillItemId);
  }

  hasError() {
    for (let item of (this.reviewSkillParams?.skillTypeValidationError as any)?.validation) {
      if(item.error) {
        return true
      }; 
    }

    return false;
  }

  updateValidationMessage(validationMessage) {
    this.reviewSkillParams.skillTypeValidationError = {...validationMessage};
  }

  applyChanges() {
    this.onEvent.emit({updateObjects: this.reviewSkillParams.updateSkillObject, type: DIALOG_ACTION_TYPE.UPDATE});
  }

  onDelete(skill) {
    const {existingSkillsForDelete, newSkillsForDelete} = this.reviewSkillParams.updateSkillObject;

    this.reviewSkillParams.skillData = this.reviewSkillParams.skillData.filter(skillItem => skill.skillItemId !== skillItem.skillItemId);
    if (!this.reviewSkillParams.newSkillIds.has(skill.skillItemId) && !existingSkillsForDelete.some(skillItem => skillItem.skillItemId === skill.skillItemId)) {
      existingSkillsForDelete.push(skill);
    } else if (this.reviewSkillParams.newSkillIds.has(skill.skillItemId) && !newSkillsForDelete.some(skillItem => skillItem.skillItemId === skill.skillItemId)) {
      newSkillsForDelete.push(skill);
    }

    this.updateSkillTypeErrorSummary();
  }

  private addControlForSkillItem(skillItem) {
    this.skillQuestions.map((q) => {
      if (q.form) {
        if (!this.skillForm.controls[q.key + '_' + skillItem.skillItemId]) this.skillForm.addControl(q.key + '_' + skillItem.skillItemId, new FormControl(skillItem[q.key] ? skillItem[q.key] : '', q.validators));
      }
    });
  }

  private updateQuestionsForSettings() {
    for (let i = 0; i < this.skillQuestions.length; i++) {
      let q = this.skillQuestions[i];
      if (q.key == 'skillTypeId') q.options = this.settings.skillTypes || [];
    }

    this.reviewSkillParams.skillData.forEach(skill => {
      this.addControlForSkillItem(skill);
    });
  }

  private updateSkillTypeErrorSummary () {
    const skillTypeSummary = this.skillTypeValidationService.getCategorySkillSummary(this.reviewSkillParams.skillData);
    this.reviewSkillParams.skillTypeValidationError = this.skillTypeValidationService.getSkillTypeValidationMessages(this.reviewSkillParams.categoryId, skillTypeSummary);
  }
}
