<ng-container *ngIf="!emptyForm">
    <div class="skill-taxonomy-con">
        <div class="h4 black ">Skill Tooltip Settings</div>
        <form [formGroup]="editForm" (ngSubmit)="saveData()" (keydown.enter)="evaluateEnterKey($event)"
            class="admin-form">
            <div class="admin-form-con">
                <div *ngFor="let smsOption of smsOptions"
                    class="admin-input-con-half">
                    <div *ngIf="smsOption.type=='text'&&smsOption.showControl">
                        <mat-form-field appearance="outline" class="admin-input-form-field">
                            <mat-label>{{smsOption.title}}</mat-label>
                            <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                            <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                                [required]="smsOption.required" autocomplete="off"
                                maxlength="{{ smsOption.maxLength ? smsOption.maxLength : '70' }}" />
                            <mat-error *ngIf="editForm.get(smsOption.key).invalid">{{smsOption.errorText||'This input is
                                mandatory'}}</mat-error>
                        </mat-form-field>
                    </div>

                    <mat-form-field *ngIf="smsOption.type=='textarea'&&smsOption.showControl" appearance="outline"
                        class="admin-input-form-field admin-text-area-field">
                        <mat-label>{{smsOption.title}}</mat-label>
                        <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                        <textarea matInput [placeholder]="smsOption.title" formControlName="{{smsOption.key}}"
                            cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="20" class="config-tooltip h5 black"></textarea>
                    </mat-form-field>

                    <div *ngIf="smsOption.type=='select'&&smsOption.showControl">
                        <mat-form-field appearance="outline" class="admin-input-form-field">
                            <mat-label>{{smsOption.title}}</mat-label>
                            <mat-icon matPrefix *ngIf="smsOption.icon">{{smsOption.icon}}</mat-icon>
                            <mat-select formControlName="{{smsOption.key}}" [required]="smsOption.required"
                                (selectionChange)="onSMSOptionSelected(smsOption, $event)"
                                [multiple]="smsOption.isMulti" [compareWith]="compareSelect">
                                <mat-option *ngFor="let option of smsOption.options"
                                    [value]="option.skillTagId || option.skillTypeId ? option.skillTagId || option.skillTypeId : option">
                                    {{smsOption.displayKey?option[smsOption.displayKey]:option}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="editForm.get(smsOption.key).invalid">{{smsOption.errorText||'This input is
                                mandatory'}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div *ngIf="smsOption.type=='date'&&smsOption.showControl">
                        <mat-form-field appearance="outline" class="admin-input-form-field">
                            <mat-label>{{smsOption.title}}</mat-label>
                            <mat-icon matPrefix>date_range</mat-icon>
                            <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                                [matDatepicker]="picker" [required]="smsOption.required" autocomplete="off"
                                (focus)="picker.open()" />
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="editForm.get(smsOption.key).invalid">{{smsOption.errorText||'This input is
                                mandatory'}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="admin-save-button-con" *ngIf="canEdit">
                <button type="submit" mat-stroked-button class="primary-button">Save</button>
            </div>
        </form>
    </div>
</ng-container>