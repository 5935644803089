export enum EXPERIENCE_TYPE {
  INTERNAL_CURRENT_EXPERIENCE = 'internalCurrentExperience',
  PAST_EXPERIENCE = 'Past Experience',
  UPDATED_HRMS_ADDED_PAST_EXPERIENCE = 'updateHrmsAddedPastExperiences',
  CURRENT_EXPERIENCE = 'Current Experience',
}

export enum PROJECT_TYPE {
  INTERNAL_CURRENT_EXPERIENCE_PROJECT = 'internalCurrentExperienceProject',
}

export enum DIALOG_ACTION_TYPE {
  SAVE = 'save',
  DELETE = 'delete',
  UPDATE = 'update'
}

export interface ISaveProfile {
  status: string;
  profileImg?: string;
  id?: string;
  error: IError;
}

interface IError {
  code: number;
}

export interface IUserStaffingRole {
  id: string;
  tagValue: string;
  searchKey: string;
  externalId: string;
  tagType: string;
  _id: string;
}