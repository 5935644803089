<div class="reports-center-settings-con">
    <div class="h3 black">Learning Settings</div>
    <form [formGroup]="editForm" (ngSubmit)="saveData()" (keydown.enter)="evaluateEnterKey($event)" class="admin-form">
        <div class="admin-form-con">
            <!-- <div class="h4 black ">Types of Learning Elements</div>
            <div *ngIf="client" class="skill-categories-con admin-form">
                <app-editable-table #skillCategories [canEdit]="canEdit" [enableDelete]="true"
                    class="sms-editable-table" [columns]="skillCategoriesColumns"
                    [dataSource]="client.smsSettings.skillCategories"
                    [footNotes]="'You can add maximum 7 Learning Elements Types. Drag and drop categories to change order'"
                    [saveButtonText]="'Save'" (onSave)="onLearningElementsTypesSave($event)" [addButtonText]="'Add'"
                    [enableDrag]="true" [maxRows]="7">
                </app-editable-table>
            </div> -->
            <div *ngFor="let smsOption of smsOptions"
                [ngClass]="smsOption.type=='textarea'||smsOption.type=='sub-title' ? 'admin-input-con-full' : 'admin-input-con-half'"
                [ngStyle]="{'display':smsOption.showControl?'block':'none'}">
                <div *ngIf="smsOption.type=='select'&&smsOption.showControl">
                    <mat-form-field appearance="outline" class="admin-input-form-field">
                        <mat-label>{{smsOption.title}}</mat-label>
                        <mat-icon matPrefix *ngIf="smsOption.icon">{{smsOption.icon}}</mat-icon>
                        <mat-select formControlName="{{smsOption.key}}" [required]="smsOption.required"
                            (selectionChange)="onSMSOptionSelected(smsOption, $event)" [multiple]="smsOption.isMulti"
                            [compareWith]="compareSelect">
                            <mat-option *ngFor="let option of smsOption.options"
                                [value]="option.value">
                                {{smsOption.displayKey?option[smsOption.displayKey]:option}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="editForm.get(smsOption.key).invalid">{{smsOption.errorText||'This input is
                            mandatory'}}</mat-error>
                    </mat-form-field>
                </div>
                <div *ngIf="smsOption.type=='text'">
                    <mat-form-field appearance="outline" class="admin-input-form-field">
                        <mat-label>{{smsOption.title}}</mat-label>
                        <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                        <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                            [required]="smsOption.required" autocomplete="off"
                            maxlength="{{ smsOption.maxLength ? smsOption.maxLength : '70' }}" />
                        <mat-error *ngIf="editForm.get(smsOption.key).invalid">{{smsOption.errorText||'This input is
                            mandatory'}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <ng-container *ngIf="client && myLearningTreatment === TREATMENT_MAP.V2">
          <app-learning-type-data-table [canEdit]="canEdit" [client]="client"></app-learning-type-data-table>
          <app-learning-element-certifications-settings [canEdit]="canEdit" [client]="client" (onSave)="saveData()"></app-learning-element-certifications-settings>
        </ng-container>
        <div class="admin-save-button-con" *ngIf="canEdit">
            <button type="submit" mat-stroked-button class="primary-button">Save</button>
        </div>
    </form>
</div>