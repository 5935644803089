import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { API_ENDPOINT } from 'src/app/constants/api-endpoint.constants';
import { SMS_OPTIONS } from 'src/app/constants';
import { DataService } from 'src/app/services/data.service';
import { Badge, BadgesResponse } from './badges.model';
import { ImmediateBadgeUpdatesService } from 'src/app/services/immediate-badge-updates.service';
import { BadgeDetailModalComponent } from './components/badge-detail-modal/badge-detail-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BadgesService {

  constructor(
    private ds: DataService,
    private dialog: MatDialog,
    private immediateBadgeUpdates: ImmediateBadgeUpdatesService
  ) {
    this.immediateBadgeUpdates.getBadgeUpdates().subscribe(badges => {
      this.handleImmediateBadgeUpdate(badges);
    });
  }

  public getAllBadges(page: number, limit: number, locked?: boolean): Observable<BadgesResponse> {
    return this.ds.saveData<BadgesResponse>(API_ENDPOINT.GET_BADGES, {
      clientId: this.ds.client.clientId,
      userId: this.ds.currentUserData? this.ds.currentUserData.userId : this.ds.user.userId,
      page,
      limit,
      ...(locked !== undefined && { locked })
    }).pipe(
      take(1)
    );
  }

  public getBadge(badgeId: string): Observable<Badge> {
    return this.ds.saveData<Badge>(API_ENDPOINT.GET_BADGES, {
      clientId: this.ds.client.clientId,
      userId: this.ds.currentUserData? this.ds.currentUserData.userId : this.ds.user.userId,
      badgeId,
    }).pipe(
      take(1)
    );
  }

  public handleImmediateBadgeUpdate(badgeResult: Badge[]): void {
    if (badgeResult?.length > 1) {
      this.showBadgeDetailsModal(badgeResult);
    } else if (badgeResult?.length === 1) {
      const apiSubscription = this.getBadge(badgeResult[0]?.badgeId).subscribe(badge => {
        apiSubscription.unsubscribe();
        this.showBadgeDetailsModal(badge);
      });
    }
  }

  public hasBadgesAccess(): boolean {
    return this.ds.client.smsSettings?.allowBadges === SMS_OPTIONS.YES;
  }

  public showBadgeDetailsModal(data: any): void {
    setTimeout(() => {
      const dialogRef = this.dialog.open(BadgeDetailModalComponent, {
        panelClass: 'dd-modal',
        disableClose: true,
        width: '80%',
        maxWidth: '400px',
        minHeight: '250px'
    });
    dialogRef.componentInstance.data = data;
    dialogRef.componentInstance.isImmediate = true;
    const onEvent = dialogRef.componentInstance.onClose.subscribe(() => {
      dialogRef.close();
    });
      dialogRef.afterClosed().subscribe(() => {
        onEvent.unsubscribe();
      });
    }, 1000);
  }
}
